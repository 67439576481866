<template>
  <div class="card p-fluid">
    <Loading v-show="loading" />
    <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
      display="chip" placeholder="Spalten Ansicht wählen" class="w-full" />
    <DataTable v-if="invoices.length > 0" v-model:filters="filters" :value="invoices" editMode="row" scrollable
      scrollHeight="85vh" filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit"
      stripedRows selectionMode="single" dataKey="supportDocumentId" sortField="supportDocumentId" :sortOrder="-1"
      class="p-datatable-sm" @row-dblclick="openSupportThread" :virtualScrollerOptions="{
      lazy: false,
      itemSize: 46,
      showLoader: true,
      loading: lazyLoading,
      numToleratedItems: 100
    }" ref="dataTableRef">
      <Column headerStyle="width: 2rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #header>
          <div>
            <div>
              <Button type="button" outlined id="isNew" :pt="{ icon: { class: 'text-xl' } }" icon="pi pi-plus"
                @click="handleNewRow" />
            </div>
          </div>
        </template>
      </Column>
      <Column v-for="col of selectedColumns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" sortable :sortField="col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
        <template #body="{ data }">
          <div v-if="col.field == 'createdTime' || col.field == 'updatedTime'">
            {{ formatDate(data[col.field]) }}
          </div>
          <div v-else-if="col.field == 'ticketStatus'">
            <Dropdown @change="statusChanged($event, data)" v-model="data.ticketStatus" class="w-full" id="ticketStatus"
              optionLabel="label" optionValue="value" :options="ticketStatuses" />
          </div>
          <div v-else>{{ data[col.field] }}</div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, defineAsyncComponent, markRaw, reactive, computed } from "vue";
import { FilterMatchMode } from "primevue/api";
import { TicketService } from "@/service/TicketService";
import Loading from "@/components/Loading";
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";

const CreateTicketComponent = defineAsyncComponent(() => import('@/components/CreateTicketComponent.vue'));
const SupportThreadComponent = defineAsyncComponent(() => import('@/components/SupportThreadComponent.vue'));

const toast = useToast();

const statusChanged = (event, ticket) => {
  let docId = ticket.supportDocumentId;

  const url = process.env.VUE_APP_BASE_API_URL + "/support/ticket-status/" + docId;

  let body = { ticketStatus: ticket.ticketStatus };
  console.log(body);
  iAxios.post(url, body).then((r) => {
    werteLaden();
  }).catch((e) => {
    werteLaden();
  });
}

const columns = ref([
  { field: "supportDocumentId", header: "ID" },
  { field: "ticketSubject", header: "Betreff" },
  { field: "createdUser", header: "Erstellt von" },
  { field: "createdTime", header: "Erstellt am" },
  { field: "updatedTime", header: "Letzte Antwort" },
  { field: "ticketStatus", header: "Status" },
]);
const startColumns = ref([]);
const selectedColumns = ref(startColumns.value);

const onToggle = (val) => {
  selectedColumns.value = columns.value.filter((col) => val.includes(col));
};

const ticketStatuses = ref([{ label: "Offen", value: "TICKET_OPEN" }, { label: "Geschlossen", value: "TICKET_CLOSED" }]);

const filters = ref({
  supportDocumentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ticketSubject: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ticketStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updatedTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const isMobile = ref(false);
const loading = ref(true);

const lazyLoading = ref(false);
const invoices = ref([]);
const dialog = useDialog();
const dataTableRef = ref();

const handleNewRow = async (event) => {
  dialog.open(CreateTicketComponent, {
    props: {
      header: 'Support Anfrage erstellen',
      style: {
        width: '35vw',
      },
      breakpoints: { // breakpoints so lassen! bei fragen an abdullah
        '2135px': '48vw',
        '1800px': '50vw',
        '1600px': '60vw',
        '1460px': '70vw',
        '1336px': '75vw',
        '1000px': '90vw',
        '831px': '100vw'
      },
      modal: true,
      closable: false,
    },
    onClose: () => {
      werteLaden();
    }
  });
}

const openSupportThread = async (event, proceedType) => {
  console.log(event.data);
  dialog.open(SupportThreadComponent, {
    props: {
      header: 'Support Anfrage/Diskussion',
      breakpoints: {
        '2135px': '48vw',
        '1800px': '50vw',
        '1600px': '60vw',
        '1460px': '70vw',
        '1336px': '75vw',
        '1000px': '90vw',
        '831px': '100vw'
      },
      modal: true,
    },
    data: {
      thread: event.data,
    }
  });
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
}

async function werteLaden() {
  loading.value = true;
  await
    TicketService.getData().then((data) => {
      invoices.value = data[1];
    });
  loading.value = false;
}
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
  startColumns.value.push(columns.value[0], columns.value[1], columns.value[2], columns.value[3]);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
});

const formatDate = (value) => {
  const date = new Date(value);

  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  }) + ' - ' + date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit"
  });
};
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
