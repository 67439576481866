<template>
  <template v-if="!store.rules.activatedModules.businessLetter">
  <UpgradeLicence />
    </template>
  <template v-else>
  <iframe id="pdfFrame" style="display:none"></iframe>
  <Menubar :model="items" :ariaLabel="items">
    <template #item="{ item, props, hasSubmenu, root }">
      <a v-ripple class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <Badge
          v-if="item.badge"
          :class="{ 'ml-auto': !root, 'ml-2': root }"
          :value="item.badge" />
        <span
          v-if="item.shortcut"
          class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
          >{{ item.shortcut }}</span
        >
        <i
          v-if="hasSubmenu"
          :class="[
            'pi pi-angle-down',
            { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
          ]"></i>
      </a>
    </template>
  </Menubar>

  <SpeedDial
    :model="items"
    :radius="80"
    type="semi-circle"
    direction="left"
    :style="{ top: 'calc(50% - 2rem)', right: 0 }"
    :tooltipOptions="{ position: 'left' }"
    showIcon="pi pi-bars"
    hideIcon="pi pi-times" />
  <div class="grid">
    <div class="col-5">
      <div v-if="showCustomer" class="CustomerOverlay"></div>
      <div class="CustomerField">
        <CustomerInputSearch
          v-if="showCustomer"
          :openPaymentsCheck="false"
          @inputSelected="handleCustomerSelected"
          @clearInput="clearInput"
          style="display: block !important"
          class="pt-5"
          auswahlText="Empfänger auswählen"
          neuErstellenText="neuen Kontakt erstellen" />
      </div>
    </div>
  </div>
  <img
    style="position: absolute; top: -10000px"
    ref="logoImageRef"
    v-if="companySettings.showCompanyLogo"
    :src="companyLogoUrl || require('@/assets/beispielLogo.png')"
    alt="Image"
    @load="onImageLogoLoad" />
  <div class="main">
    <vue-document-editor
      v-if="kopfBereich"
      class="editor"
      ref="editor"
      v-model:content="content"
      :overlay="overlay"
      :zoom="zoom"
      :page_format_mm="page_format_mm"
      :page_margins="page_margins"
      :display="display"
      :do_not_break="(elt) => elt.tagName.match(/section/i)" />
  </div>
</template>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  reactive,
  defineAsyncComponent,
  markRaw,
} from "vue";
import VueDocumentEditor from "vue-document-editor";
import iAxios from "@/store/axiosInterface";
import { onBeforeMount } from "vue";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import CustomerInputSearch from "@/components/CustomerInputSearch.vue";
import { useConfirm } from "primevue/useconfirm";

import { saveAndGetPDF,printPDF } from "@/utils/letterUtils";
import { onUnmounted } from "vue";
import { nextTick } from "vue";
import { formatDatum } from "@/utils/formatUtils";
import { useDialog } from "primevue/usedialog";
import { errorToast } from "@/components/useDebugToast";
const dialog = useDialog();
const LetterView = defineAsyncComponent(() => import("@/views/LetterView.vue"));
const LetterViewHeader = defineAsyncComponent(() =>
  import("@/views/LetterViewHeader.vue")
);
const LetterViewFooter = defineAsyncComponent(() =>
  import("@/views/LetterViewFooter.vue")
);
const store = userStore();
const toast = useToast();
const showCustomer = ref(false);
const companySettings = reactive({});
const companyData = reactive({});
const confirm = useConfirm();

const signatureImage = ref();
const stampImage = ref();

const companyLogoUrl = ref();
const imagePositionClass = ref("");
const imageSizeClass = ref("");
const logoImageRef = ref();
const maxPossibleBriefbogenHeight = ref(400);
const maxPossibleBriefbogenWidth = ref(595);
const maxImageLogoWidth = ref(0);
const maxImageLogoHeight = ref(0);
const imageLogoWidth = ref();
const imageLogoHeight = ref();
const imageBriefbogenHeight = ref(400);
const imageBriefbogenWidth = ref(595);
const adjustToHeight = ref(false);

const kopfBereich = ref(null);
let content = ref([]);
let zoom = ref(0.8);
let page_format_mm = ref([210, 297]);
let page_margins = ref("10mm 24mm 28mm 24mm");
let display = ref("grid");
let mounted = ref(false);
let undo_count = ref(-1);
let content_history = ref([]);

async function werteLaden() {
  const resCompanyData = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/details"
  );
  Object.assign(companyData, resCompanyData.data);
  const resCompanySettings = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/settings"
  );

  //console.log("🚀 ~ file: BusinessLetter.vue:122 ~ werteLaden ~ resCompanySettings:", resCompanySettings)
  Object.assign(companySettings, resCompanySettings.data);

  //companySettings= await JSON.parse(JSON.stringify(resCompanySettings.data)) ;

  //console.log("🚀 ~ file: BusinessLetter.vue:126 ~ werteLaden ~ companySettings:", companySettings)
}

console.log("🚀 ~ file: BusinessLetter.vue:74 ~ companyData:", companyData);

let initialDocumentSubject =
  "Bitte diesen Bereich mit dem Betreff überschrieben. Bsp. Kündigung xyz";
let initialTextContent =
  "Sehr geehrte Damen und Herren, <br/><br/> bitte diesen Bereich mit dem Text überschrieben. Bsp. hiermit kündige ich den Vertrag xyz fristgerecht zum 31.12.2021.";
let initialReceiver = {
  sendungsvermerk: "",
  name1: "Empfängername",
  name2: "",
  address1: "Strasse Hausnr.",
  address2: "",
  city: "Ort",
  postCode: "12345",
};

const initalBriefDaten = reactive({
  as_attachment: true,
  receiver: initialReceiver,
  //letter: { documentId: null },
  letterDocumentId: null,
  docName: null,
  customerId: null,
  customerUid: null,
  docContent: {
    salutationText: store.companySettings.salutationText,
    documentDate: new Date().toISOString().slice(0, 10),
    documentSubject: initialDocumentSubject,
    textContent: initialTextContent,
    greetings: "Mit freundlichen Grüßen",
  },
});

const briefDaten = JSON.parse(JSON.stringify(initalBriefDaten));

const combinedImage = ref();
onBeforeMount(async () => {
  await werteLaden();
  await loadImage();
  loadImage("signature");
  loadImage("stamp");

  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile

  //await onImageLogoLoad();
  setTimeout(async () => {
    kopfBereich.value =
      '<div contenteditable="false" style="height:35mm" class="letterLogoPlace"><img ref="logoImageRef" src="' +
      (await companyLogoUrl.value) +
      '" class="' +
      imagePositionClass.value +
      imageSizeClass.value +
      '" alt="Image" /></div>';
    let adressBereich =
      '<div contenteditable="false" class=" linie-0"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div contenteditable="false" class="address-overline baseline-8pt"><br/><br/><br/><br/>' +
      companyData.cname +
      ", " +
      companyData.streetName +
      " " +
      companyData.houseNumber +
      ", " +
      companyData.postCode +
      " " +
      companyData.city +
      '</div><div contenteditable="false"  style="height: 39.6mm !important;" class="address baseline-10pt" id="receiver">' +
      receiverFeld +
      "</div>";
    let sideText =
      '<div contenteditable="false" class="sideText"><div class="align-bottom"><div contenteditable="false">' +
      companyData.firstName +
      " " +
      companyData.surName +
      "<br/>" +
      (companyData?.replyEmail ? companyData.replyEmail : companyData.email) +
      "<br/>&#x1f4de; " +
      companyData.phone +
      "<br/><br/>" +
      '<br/><br/><br/><input type="date" class="inputDate p-component" value="' +
      briefDaten.docContent.documentDate +
      '"></div></div></div>';
    let betreff =
      '<strong id="documentSubject" contenteditable=true>' +
      briefDaten.docContent.documentSubject +
      "</strong><br/><br/><br/>";
    const textbereich = briefDaten.docContent.textContent;
    let signs =
      '<img contenteditable="false" class="h-5rem" src="' +
      (stampImage.value
        ? stampImage.value
        : require("@/assets/BeispielStempel.png")) +
      '" alt="stamp" /> <img contenteditable="false" class="bg w-10rem" src="' +
      (signatureImage.value
        ? signatureImage.value
        : require("@/assets/BeispielUnterschrift.png")) +
      '" alt="sign" />';
    let testLinie =
      '<div contenteditable="false" class=" linie-test"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div>';
    let combiSign = combinedImage.value
      ? '<div contenteditable="false"><img class="h-5rem" src="' +
        combinedImage.value +
        '" alt="Stempel Unterschrift" /></div>'
      : "";
    let addressOverlay =
      '<div style="cursor: pointer;" contenteditable="false" id="AdresseSetzen" class="address-overlay"></div>';
    let writerName =
      '<p contenteditable="false">' +
      companyData.firstName +
      " " +
      companyData.surName +
      "</p>";
    content = ref([
      "<span contenteditable=false>" +
      kopfBereich.value +
        adressBereich +
        sideText +
        betreff +
        '<span id="textContent" contenteditable=true>' +
        textbereich +
        "</span><section contenteditable=false><br/>" +
        briefDaten.docContent.greetings +
        "<br/><br/>" +
        combiSign +
        writerName +
        addressOverlay +
        "<br/><br/></section>"+"</span>",
    ]);
  }, 1000);
});

function removeWhiteBackground(imageSrc) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] > 200 && data[i + 1] > 200 && data[i + 2] > 200) {
          // wenn die Farbe weiß ist
          data[i + 3] = 0; // setze die Transparenz auf 0
        }
      }
      ctx.putImageData(imageData, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
  });
}

const setAddress = () => {
  showCustomer.value = true;
  console.log("setAddress");
};

const clearInput = () => {
  console.log("clearInput");
};

const handleCustomerSelected = (customer) => {
  if (customer) {
    console.log("🚀 ~ file: BusinessLetter.vue:185 ~ customer:", customer);

    briefDaten.receiver.name1 = customer.value.name1;
    briefDaten.receiver.name2 = customer.value.name2;
    briefDaten.receiver.address1 =
      customer.value.street + " " + customer.value.houseNumber;
    briefDaten.receiver.address2 = customer.value.address2;
    briefDaten.receiver.postCode = customer.value.postCode;
    briefDaten.receiver.city = customer.value.city;
    briefDaten.customerUid = customer.value.customerUid;
    briefDaten.customerId = customer.value.customerId;
  }

  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile
  let receiverDiv = document.getElementById("receiver");
  receiverDiv.innerHTML = receiverFeld;
  setTimeout(() => {
    showCustomer.value = false;
  }, 100); //geäbdert von 1000 auf 100 als test
};

async function combineImages(signSrc, stampSrc) {
  const img1 = await removeWhiteBackground(signSrc);
  const img2 = await removeWhiteBackground(stampSrc);

  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  let image1 = new Image();
  image1.src = img1;
  await new Promise((resolve) => (image1.onload = resolve));

  let image2 = new Image();
  image2.src = img2;
  await new Promise((resolve) => (image2.onload = resolve));

  // Skaliere den Stempel auf eine maximale Breite von 58mm, wenn er größer ist
  const maxWidth = 58 * 3.779528; // Umrechnung von mm zu px
  if (image2.width > maxWidth) {
    let scale = maxWidth / image2.width;
    image2.width *= scale;
    image2.height *= scale;
  }

  // Skaliere die Unterschrift auf die Höhe des Stempels, wenn sie größer ist
  if (image1.height > image2.height) {
    let scale = image2.height / image1.height;
    image1.width *= scale;
    image1.height *= scale;
  }

  // Setze die Canvas-Größe auf die Größe des größeren Bildes
  canvas.width = Math.max(image1.width, image2.width);
  canvas.height = Math.max(image1.height, image2.height);

  // Zeichne die Bilder auf das Canvas
  ctx.drawImage(image1, 0, 0, image1.width, image1.height);
  ctx.drawImage(image2, 0, 0, image2.width, image2.height);

  return canvas.toDataURL();
}

const formatDatumUS = (datum) => {
  const date = new Date(datum);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Monate von 0-11, also +1 und führende Nullen hinzufügen
  const day = String(date.getDate()).padStart(2, "0"); // Führende Nullen hinzufügen
  return `${year}-${month}-${day}`;
};

onMounted(() => {
  setTimeout(() => {
    console.log("eventlistener gesetzt");
    const clickableElementAddress = document.getElementById("AdresseSetzen");
    if (clickableElementAddress) {
      clickableElementAddress.addEventListener("click", setAddress);
    }
  }, 2000);
  mounted.value = true;
});

onUnmounted(() => {
  const clickableElementAddress = document.getElementById("AdresseSetzen");
  if (clickableElementAddress) {
    clickableElementAddress.addEventListener("click", setAddress);
  }
});

watch(content, (new_content) => {
  //  Undo/Redo-Historie
});

const current_margins_name = computed(() => {
  const margins = this.margins.find(
    ([, margins]) => page_margins.value == margins
  );
  return margins ? margins[0] : page_margins.value;
});

const current_text_style = computed(() => {
  return mounted.value ? this.$refs.editor.current_text_style : false;
});

const isBold = computed(() => {
  const fontWeight = current_text_style.value.fontWeight;
  return (
    fontWeight &&
    (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0)
  );
});

const isItalic = computed(() => {
  return current_text_style.value.fontStyle == "italic";
});

const isUnderline = computed(() => {
  const stack = current_text_style.value.textDecorationStack;
  return stack && stack.some((d) => d.indexOf("underline") == 0);
});

const isStrikeThrough = computed(() => {
  const stack = current_text_style.value.textDecorationStack;
  return stack && stack.some((d) => d.indexOf("line-through") == 0);
});

const can_undo = computed(() => {
  return undo_count.value > 0;
});

const can_redo = computed(() => {
  return content_history.value.length - undo_count.value - 1 > 0;
});

const overlay = (page, total) => {
  let html = "";
  /*   html = '<div style="position: absolute; bottom: 8mm; ' + ((page % 2) ? 'right' : 'left') + ': 10mm">Seite ' + page + ' von ' + total + '</div>';
   */ let footer =
    '<div contenteditable="false" class=" linie-4"><img src="' +
    require("@/assets/1pxGray.png") +
    '" class="linie-img-helper" alt="linie"></div><table><tr><th style="width: 4.9cm">Anschrift </th><th style="width: 4.9cm">Bankverbindung</th><th style="width: 6.6cm">Kontakt</th></tr><tr><td>' +
    companyData.cname +
    "</td><td>" +
    companyData.kontoInhaber +
    "</td><td>" +
    (companyData.website ? companyData.website : "") +
    " " +
    companyData.email +
    "</td></tr><tr><td>" +
    companyData.streetName +
    " " +
    companyData.houseNumber +
    "</td><td>" +
    companyData.bankName +
    "</td><td>" +
    companyData.mobileNummer +
    " " +
    companyData.phone +
    "<td><tr><td>" +
    companyData.postCode +
    " " +
    companyData.city +
    "</td><td>" +
    companyData.iban +
    "</td><td>" +
    (companyData.rechtsForm ? companyData.rechtsForm : "") +
    " " +
    companyData.firstName +
    " " +
    companyData.surName +
    "</td><tr><td>" +
    (companyData.steuerNrArt ? companyData.steuerNrArt : "") +
    " " +
    (companyData.steuerUmsatzId ? companyData.steuerUmsatzId : "") +
    "</td><td>" +
    companyData.bic +
    "</td><td>" +
    (companyData.registerGericht ? companyData.registerGericht : "") +
    "</td></tr><tr><td></td><td></td><td>" +
    (companyData.hrbNummer ? companyData.hrbNummer : "") +
    "</td></tr>";
  if (page == 1) {
    html +=
      '<div contenteditable="false" class="linie linie-1"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div class="linie linie-2"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div class="linie linie-3"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div>';
    html += '<div contenteditable="false" class="address-wrapper"></div>';
    html +=
      '<div editable="false" style="position: absolute; left: 24mm; right: 24mm; bottom: 5mm; text-align:left; font-size:8pt">' +
      footer +
      "</div>";
  }
  return html;
};

/* const loadImage = async (type) => {
type = type ?? "logo";
const stamp = type === "stamp";
const signature = type === "signature";
try {
let url = process.env.VUE_APP_BASE_API_URL + "/company/" + type;
let response = await iAxios.get(url, {
responseType: 'arraybuffer',
headers: {
'Content-Type': 'application/json',
}
})
.then(async (response) => {
const blob = new Blob([response.data]);
const fileURL = URL.createObjectURL(blob);
if (stamp) {
  stampImage.value = fileURL;
} else if (signature) {
  signatureImage.value = fileURL;
} else {
  companyLogoUrl.value = fileURL;
}
})
.catch((error) => {
console.error(error);
});
} catch (error) {
console.error(error);
}
}; */

const loadImage = async (type) => {
  type = type ?? "logo";
  const stamp = type === "stamp";
  const signature = type === "signature";
  try {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/" + type;
    let response = await iAxios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        const blob = new Blob([response.data]);
        const fileURL = URL.createObjectURL(blob);
        if (stamp) {
          stampImage.value = fileURL;
        } else if (signature) {
          signatureImage.value = fileURL;
        } else {
          companyLogoUrl.value = fileURL;
        }

        // Wenn sowohl das Stempelbild als auch das Unterschriftsbild geladen sind, kombinieren Sie sie
        if (stampImage.value && signatureImage.value) {
          combinedImage.value = await combineImages(
            signatureImage.value,
            stampImage.value
          );
        }
      })
      .catch((error) => {
        errorToast(error);
        //console.error(error);
      });
  } catch (error) {
    errorToast(error);
    //console.error(error);
  }
};
const getCompanyLogoSize = () => {
  if (logoImageRef.value?.offsetWidth) {
    const width = logoImageRef.value.offsetWidth;
    const height = logoImageRef.value.offsetHeight;
    imageLogoWidth.value = width;
    imageLogoHeight.value = height;
    console.log(`-companyLogo Größe: ${width}x${height}`);
  }
};
const changeLogoImageClass = async () => {
  const size = companySettings.companyLogoSize;
  const position = companySettings.companyLogoPosition;

  console.log("🚀 ~ file: BusinessLetter.vue:398 ~ position:", position);

  let newLogoPositionClass = "p-0 overlayLogo"; // Standardklasse
  let newLogoSizeClass = "";
  if (size == "LARGE") {
    newLogoSizeClass += " letterLogoSizeLarge";
  } else if (size == "MEDIUM") {
    newLogoSizeClass += " letterLogoSizeMedium";
  } else if (size == "SMALL") {
    newLogoSizeClass += " letterLogoSizeSmall";
  }
  // Positionsbehandlung
  switch (position) {
    case "LEFT":
      newLogoPositionClass += " letterLogoPosition-LEFT";
      break;
    case "RIGHT":
      newLogoPositionClass += " letterLogoPosition-RIGHT";
      break;
    case "CENTER":
      newLogoPositionClass += " letterLogoPosition-CENTER";
      break;
    default:
      break;
  }

  const style = document.createElement("style");
  let existingStyle = document.querySelector('style[data-id="custom-style"]');

  setTimeout(async () => {
    // getCompanyLogoSize();
    if (!existingStyle) {
      style.setAttribute("data-id", "custom-style");
      document.head.appendChild(style);
      existingStyle = style;
    }

    let logoHeightLarge = "";

    let logoWidthLarge = "";
    let logoWidthMedium = "";
    let logoHeightMedium = "";
    let logoWidthSmall = "";
    let logoHeightSmall = "";
    if (adjustToHeight.value == true) {
      logoWidthLarge = "auto";
      logoHeightLarge = `${maxImageLogoHeight.value}px`;
      logoWidthMedium = "auto";
      logoHeightMedium = `${maxImageLogoHeight.value * (2 / 3)}px`;
      logoWidthSmall = "auto";
      logoHeightSmall = `${maxImageLogoHeight.value * (1 / 3)}px`;
    } else {
      logoWidthLarge = `${maxImageLogoWidth.value}px`;
      logoHeightLarge = "auto";
      logoWidthMedium = `${maxImageLogoWidth.value * (2 / 3)}px`;
      logoHeightMedium = "auto";
      logoWidthSmall = `${maxImageLogoWidth.value * (1 / 3)}px`;
      logoHeightSmall = "auto";
    }

    existingStyle.innerHTML = `
.letterLogoSizeLarge {
    width: ${logoWidthLarge};
    height: ${logoHeightLarge};
}
.letterLogoSizeMedium {
    width: ${logoWidthMedium};
    height: ${logoHeightMedium};
}
.letterLogoSizeSmall {
    width: ${logoWidthSmall};
    height: ${logoHeightSmall};
}
.letterLogoPosition-LEFT {
    position: absolute;
    left: 40px;
}
.letterLogoPosition-RIGHT {
    position: absolute;
    right:  40px;
}
.letterLogoPosition-CENTER {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);}`;
  }, 10);

  imagePositionClass.value = newLogoPositionClass;
  imageSizeClass.value = newLogoSizeClass;
};

const checkAdjustToHeight = () => {
  console.log("loaf");
  if (logoImageRef.value) {
    const logoWidth = imageLogoWidth.value;
    const logoHeight = imageLogoHeight.value;
    adjustToHeight.value = logoWidth > logoHeight;
  }
};

const onImageLogoLoad = async () => {
  console.log("onImageLogoLoad");
  getCompanyLogoSize();
  maxPossibleBriefbogenHeight.value = Math.floor(
    imageBriefbogenHeight.value / 4
  );
  maxPossibleBriefbogenWidth.value = Math.floor(
    imageBriefbogenWidth.value - 80
  );
  maxImageLogoWidth.value =
    (await imageLogoWidth.value) > maxPossibleBriefbogenWidth.value
      ? maxPossibleBriefbogenWidth.value
      : imageLogoWidth.value;

  console.log(
    "🚀 ~ file: BusinessLetter.vue:281 ~ imageLogoWidth.value:",
    imageLogoWidth.value
  );

  console.log(
    "🚀 ~ file: BusinessLetter copy 2.vue:565 ~ maxImageLogoWidth.value :",
    maxImageLogoWidth.value
  );

  maxImageLogoHeight.value =
    (await imageLogoHeight.value) > maxPossibleBriefbogenHeight.value
      ? maxPossibleBriefbogenHeight.value
      : imageLogoHeight.value;
  checkAdjustToHeight();
  await changeLogoImageClass();
};

const newDocument = () => {
  confirm.require({
    header: "Beues Dokument erstellen?",
    message:
      "Nicht gespeicherte Änderungen gehen verloren, möchten Sie ein neues Dokument erstellen?",
    icon: "pi pi-question-circle",
    acceptLabel: "Ja",
    acceptIcon: "pi pi-check",
    rejectLabel: "Nein",
    rejectIcon: "pi pi-times",
    accept: () => {
      toast.add({
        severity: "info",
        summary: "neues Dokument",
        detail: "Neues DOkument erstellen gestartet",
        life: 3000,
      });
      const initData = JSON.parse(JSON.stringify(initalBriefDaten));
      Object.assign(briefDaten, initData);
      fillDocument(briefDaten);
    },
    reject: () => {
      toast.add({
        severity: "error",
        summary: "Abbruch",
        detail: "Neues Dokument erstellen abgebrochen",
        life: 3000,
      });
    },
  });
};

const items = ref([
  {
    label: "Neues Dokument",
    icon: "pi pi-file",
    command: () => {
      newDocument();
    },
  },
  {
    label: "Öffnen",
    icon: "pi pi-folder-open",
    command: () => {
      openLetterView();
    },
  },
  {
    label: "Speichern",
    icon: "pi pi-save",
    command: () => {
      if (briefDaten.receiver.name1 == "Empfängername") {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail:
            "Brief konnte nicht gespeichert werden. Bitte vorerst Empfänger auswählen.",
          life: 3000,
        });
        return;
      }
      if (
        saveDocument()
      ) {
        toast.add({
          severity: "success",
          summary: "Speichern",
          detail: "Brief gespeichert",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail: "Brief konnte nicht gespeichert werden",
          life: 3000,
        });
      }
    },
  },
  {
    label: "Speichern und herunterladen",
    icon: "pi pi-download",
    command: () => {
      if (briefDaten.receiver.name1 == "Empfängername") {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail:
            "Brief konnte nicht gespeichert werden. Bitte vorerst Empfänger auswählen.",
          life: 3000,
        });
        return;
      }
      if (
        downloadDocument()
      ) {
        toast.add({
          severity: "success",
          summary: "Speichern",
          detail: "Brief gespeichert",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail: "Brief konnte nicht gespeichert werden",
          life: 3000,
        });
      }
    },
  },
  {
    label: "Speichern und Drucken",
    icon: "pi pi-print",
    command: () => {
      if (briefDaten.receiver.name1 == "Empfängername") {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail:
            "Brief konnte nicht gespeichert werden. Bitte vorerst Empfänger auswählen.",
          life: 3000,
        });
        return;
      }
      saveDocument().then(() =>{
        console.log("then print")
      printDocument();
    });

      toast.add({
        severity: "info",
        summary: "Drucken",
        detail: "Druckvorgang gestartet",
        life: 2000,
      });
      setTimeout(() => {
        return;
      }, 10);
    },
  },
]);

const saveData = (briefDaten) => {

  console.log("🚀 ~ file: BusinessLetter.vue:908 ~ briefDaten:", briefDaten)

  console.log("🚀 ~ file: BusinessLetter.vue:927 ~ briefDaten.docContent.textContent:", briefDaten.docContent.textContent)
  setTimeout(() => {
    briefDaten.docContent.documentDate = document.querySelector('.inputDate').value;

console.log("🚀 ~ file: BusinessLetter.vue:909 ~ document.querySelector('.inputDate').value;:", document.querySelector('.inputDate').value)

briefDaten.docContent.textContent = document.querySelector('#textContent')?.innerHTML;

briefDaten.docContent.textContent = briefDaten.docContent.textContent?.replace(/<br>/g, "<br\/>");
console.log("🚀 ~ file: BusinessLetter.vue:913 ~ document.querySelector('textContent').innerHTML:", document.querySelector('#textContent').innerHTML)

briefDaten.docContent.documentSubject = document.querySelector('#documentSubject').innerHTML;

console.log("🚀 ~ file: BusinessLetter.vue:917 ~ document.querySelector('#documentSubject').innerHTML:", document.querySelector('#documentSubject').innerHTML)
}, 1000)


  console.log("🚀 ~ file: BusinessLetter.vue:910 ~ briefDaten:", briefDaten)
 return briefDaten;
};



const fillDocument = (letterData) => {
  console.log("🚀 ~ file: BusinessLetter.vue:611 ~ letterData:", letterData);

  Object.assign(briefDaten, letterData);
  const docDate = formatDatumUS(letterData.docContent.documentDate);
  document.querySelector(".inputDate").value = docDate;
  document.querySelector("#textContent").innerHTML =
    letterData.docContent.textContent;
  document.querySelector("#documentSubject").innerHTML =
    letterData.docContent.documentSubject;
  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile
  let receiverDiv = document.getElementById("receiver");
  receiverDiv.innerHTML = receiverFeld;
};

const openLetterView = async (event) => {
  dialog.open(LetterView, {
    props: {
      header: "Brief Öffnen",
      modal: true,
      closable: true,
      style: {
        width: "85vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(LetterViewHeader),
      footer: markRaw(LetterViewFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const customerUid = options.data.customerUid;
        if (buttonType == "openLetter") {
          const letterData = await options.data.letterData;

          console.log("🚀 ~ file: BusinessLetter.vue:1025 ~ letterData:", letterData)

          fillDocument(letterData);
        }
      }
    },
  });
};

const saveDocument = async () => {
  const download = false;
  const preview = !download;
  const data = { briefsDaten: saveData(briefDaten) };

  let response = await saveAndGetPDF(
    { data },
    "saveLetter",
    preview,
    download
  );

  console.log(
    "🚀 ~ file: InvoicesEditFooter.vue:68 ~ saveDraftPreview ~ response:",
    response
  );
  return response;
};
const downloadDocument = async () => {
  const download = true;
  const preview = !download;
  const data = { briefsDaten: saveData(briefDaten) };

  let response = await saveAndGetPDF(
    { data },
    "download",
    preview,
    download
  );

  console.log(
    "🚀 ~ file: InvoicesEditFooter.vue:68 ~ saveDraftPreview ~ response:",
    response
  );
  return response;
};


const printDocument = async () => {
  const download = false;
  const preview = !download;
  const data = { briefsDaten:  saveData(briefDaten) };


  console.log("🚀 ~ file: BusinessLetter.vue:1026 ~ data:", data)

  let responseFile = await saveAndGetPDF(
    { data },
    "print",
    preview,
    download
  );
};
</script>

<style scoped>
:deep(.CustomerOverlay) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1 !important;
}

.CustomerField {
  position: relative;
  z-index: 10 !important;
}

.main {
  width: fit-content;
  min-width: 100%;
}
</style>
