<template>
  <div id="app">
    <div class="content">
      <header>
        <h1 class="text-white">Famtura Betreiber Handbuch, hier findest Du wichtige Funktionen und Tips</h1>
      </header>
      <InputText v-model="searchTerm" class="text-3xl w-full" placeholder="Suche nach einer Funktion im Handbuch..." />
      <nav>
        <ol class="text-xl">
          <li v-for="section in filteredData" :key="section.id">
            <a class="text-blue-800 font-bold" :href="'#' + section.id">{{ section.title }}</a>
            <ol class="pl-3 text-lg">
              <li v-for="subSection in section.subSections" :key="subSection.id">
                <a class="text-blue-600 font-semibold" :href="'#' + subSection.id">{{ subSection.title }}</a>
                <ol class="pl-3 text-sm">
                  <li v-for="subSection in subSection.subSections" :key="subSection.id">
                    <a :href="'#' + subSection.id">{{ subSection.title }}</a>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </nav>
      <section v-for="section in filteredData" :id="section.id" :key="section.id">
        <h2>{{ section.title }}</h2>
        <p>{{ section.content }}</p>
        <p v-if="section.image"><img :src="section.image" alt="section.titel"></p>
        <div class="pl-2" v-for="subSection in section.subSections" :id="subSection.id" :key="subSection.id">
          <h4>{{ subSection.title }}</h4>
          <p>{{ subSection.content }}</p>
          <p v-if="subSection.image"><img :src="subSection.image" alt="subSection.titel"></p>
          <div class="pl-2" v-for="subSection in subSection.subSections" :id="subSection.id" :key="subSection.id">
            <h5>{{ subSection.title }}</h5>
            <p>{{ subSection.content }}</p>
            <p v-if="subSection.image"><img :src="subSection.image" alt="subSection.titel"></p>
            <div class="pl-2" v-for="subSection in subSection.subSections" :id="subSection.id" :key="subSection.id">
              <h5>{{ subSection.title }}</h5>
              <p>{{ subSection.content }}</p>
              <p v-if="subSection.image"><img :src="subSection.image" alt="subSection.titel"></p>
            </div>
          </div>
        </div>
      </section>
      <footer>
        © 2023 Famtura. Alle Rechte vorbehalten.
      </footer>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { data } from './HandbuchData.js';

const searchTerm = ref('');
const filteredData = computed(() => {
  if (!searchTerm.value) {
    return data;
  }
  return data.filter(section =>
    section.title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
    section.content.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
    section.subSections.some(subSection =>
      subSection.title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      subSection.content.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
  );
});
</script>
  
<style scoped>
@counter-style q {
  system: extends decimal;
  prefix: "Q";
}

ol.q {
  counter-reset: q;
}

ol.q>li {
  counter-increment: q;
}

ol.q>li::marker {
  content: "Q" counter(q) ". ";
}

header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.content {
  margin: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  overflow-y: auto;
}

nav ol {
  margin: 0;
  padding: 0;
}

section {
  scroll-margin-top: 80px;
}

section.div {
  scroll-margin-top: 80px;
}

nav ol li {
  list-style-type: none;
}

nav ol a {
  text-decoration: none;
  color: #0074D9;
  display: block;
  margin-top: 10px;
  position: relative;
  transition: 0.2s;
}


nav a:hover {
  text-decoration: underline;
}

.backToMain {
  display: block;
  text-align: right;
  margin-top: 10px;
  color: #0074D9;
}


h1,
h2,
h3,
h4 {
  color: #333;
  scroll-margin-top: 80px;
}

p {
  line-height: 1.6;
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #007BFF;
  color: white;
}
</style>
  

  
