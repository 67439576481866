import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'
import { markRaw } from "vue";
import { formatDate } from "./calculationUtils";
import { userStore } from "@/store/userStore";



//formatiert das Datum nach dem Schema: dd.mm.jjjj
const formatDatum = (datum) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(datum);
    return date.toLocaleDateString(undefined, options);
};

function createAddressString(deliverTo) {
    let addressParts = [
        deliverTo.name1,
        deliverTo.name2,
        deliverTo.address1,
        deliverTo.address2,
        deliverTo.postCode,
        deliverTo.city
    ];
    addressParts = addressParts.filter(part => part != null);
    return addressParts.join(" ");
}


/*
Neue Auftrag/Entwurf Erstellung:
//FE-Api
Kein Entwurf vorhanden: -> keine orderDocumentId vorhanden
Erstellen mit Vorschau: (UI: nach Validierung) Es wird ein neue Auftrag erstellt und die Vorschau angezeigt. // post //create-order
Erstellen mit Download: (UI: nach Validierung) Es wird ein neue Auftrag erstellt und direkt zum Download übergeben. // post //create-order
Entwurf vorhanden:
Erstellen mit Vorschau: Entwurf wird überschrieben und danach wird mit den Daten der Entwurf in eine Auftrag umgewandelt mit der Entwurf-ID und die Vorschau angezeigt. //Schritt1. patch //create-order-draft, Schritt 2. get //draft-to-order
Erstellen mit Download: Entwurf wird überschrieben und danach wird mit den Daten der Entwurf in eine Auftrag umgewandelt mit der Entwurf-ID und direkt zum Download übergeben. //Schritt1. patch //create-order-draft, Schritt 2. get //draft-to-order
Wenn nur Speichern: Es wird ein neuer Entwurf erstellt ohne Vorschau. // post //create-order-draft
Wenn Speichern mit Vorschau: Es wird ein neuer Entwurf erstellt und die Vorschau angezeigt. //post //create-order-draft
Löschen: (UI: Nach Bestätigung des Löschvorgangs) wird der vorhandene Entwurf gelöscht. //delete //delete-draft
Wenn Drucken: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt zum Ausdrucken übergeben. //post oder patch //create-order-draft
Wenn Download: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt zum Download übergeben. //post oder patch //create-order-draft
HORNY: Wenn per Email senden: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt per Email (orderDocumentId) versendet. Schritt1. //post oder patch //create-order-draft Schrit2. //post //send-email

//FE-Api
Bereits erstellte Auftrag: ->isOrder (orderDocumentId vorhanden & isDraft=false || readOnly=true)
Wenn Vorschau: Auftrags-PDF wird mit get und orderDocumentId geholt und die Vorschau angezeigt. //get //create-order
Wenn Drucken: Auftrag-PDF wird mit get und orderDocumentId geholt und direkt zum Ausdrucken übergeben. //get //create-order
Wenn Herunterladen: Auftrags-PDF wird mit get und orderDocumentId geholt und direkt zum Download übergeben. //get //create-order
Auftrag Klonen: Es wird ein Dialog gestartet, mit den Werten der Auftrag, die geklont werden soll.
HORNY: Wenn per Email senden: Auftrag wird orderDocumentId dem EP zum Versand per Email gesendet.

//FE-UI
Wenn Stornieren: Wird ein Dialog gestartet, mit folgenden Werten:
-auftragsDaten.docContent.orderTitel="Auftragskorrektur zu Auftrag: "+auftragsDaten.docContent.orderTitel
-auftragsDaten.relationFrom={"canceled-order":id der zu stornieren Auftrag} 
-alle items von itemsList werden mit negativen Werten in die itemsList von auftragsDaten übernommen.

//FE-Api
Auftragskorrektur:
-Gleicher Prozess wie Auftrag erstellen, nur mit den Werten von der zu stornierenden Auftrag.

*/


/**
 * Speichert die Auftragsdaten und erhält die PDF-Datei.
 * @param {Object} injectedValue - Die injizierten Werte, die die Auftragsdaten enthalten.
 * @param {string} proceedType - Der Vorgangstyp, der angibt, wie die Funktion ausgeführt werden soll.
 *                   Falls Entwurf vorhanden, wird der Entwurf überschrieben, falls nicht wird ein neuer Entwurf erstellt.
 * Mögliche Werte:  "save" => default bei null speichert und lädt direkt runter. Erstellt eine Auftrag.
 *                  "print"=> direkt Ausdruck, ohne speichern,
 *                  "saveDraftAndPreview"=>Vorschau mit speichern,  
 *                  "previewOnly"=>Vorschau ohne speichern als Entwurf, 
 *                  "download=>nur download ohne speichern".
 * @param {boolean} preview - Gibt an, ob eine Vorschau angezeigt werden soll.
 * @returns {Promise} - Ein Promise-Objekt, das die Antwort der Serveranfrage enthält.
 */
export const saveAndGetPDF = async (injectedValue, proceedType, preview, setDownload) => {
    const store = userStore();
    injectedValue.data.loading = true;
    console.log("🚀 ~ file: orderUtils.js:69 ~ saveAndGetPDF ~ setDownload:", setDownload)


    console.log("🚀 ~ file: orderUtils.js:69 ~ saveAndGetPDF ~ preview:", preview)


    console.log("🚀 ~ file: orderUtils.js:69 ~ saveAndGetPDF ~ proceedType:", proceedType)


    console.log("🚀 ~ file: orderUtils.js:69 ~ saveAndGetPDF ~ injectedValue:", injectedValue)

    //console.clear();
    const isDraftToOrder = (proceedType === "draftToOrder");
    const isOrder = (proceedType === "saveOrder");
    const saveOrder = isOrder || isDraftToOrder;
    const saveDraft = (proceedType === "saveDraft");
    const readOnly = injectedValue.data.order.readOnly;
    const print = (proceedType === "print");
    const printOrder = (print && readOnly);
    const previewOnly = (proceedType === "previewOnly");
    const downloadOnly = (proceedType === "download");
    const downloadDraft = (proceedType === "downloadDraft");
    const isDownload = (proceedType === "download") || setDownload;
    const deleteDraft = (proceedType === "deleteDraft");
    const auftragsDaten = injectedValue.data.auftragsDaten;
    const orderDocumentId = injectedValue.data.order.orderDocumentId;
    const isorderDocumentId = orderDocumentId != null;
    const overwriteDraft = isorderDocumentId && saveDraft;
    const documentDownlaod = orderDocumentId ? orderDocumentId + "/download" : "";
    const isCancelled = injectedValue.data.order.isCancelled;
    const isDraft = injectedValue.data.order.isDraft;
    const isCloned = injectedValue.data.order.isCloned;
    const isFromOffer = injectedValue.data.order?.isFromOffer;
    //let flatAuftrag = auftragsDaten;
    //flatAuftrag.storno = injectedValue.data.order.storno;
    //const docId = (preview ||saveOrder || isDraftToOrder || downloadDraft) && orderDocumentId ? "/" + orderDocumentId : "";

    //wenn store.companySettings.showEanInDocuments true ist, dann wird der itemDescription inder itemList die Ean mit EAN:+ EAN vorangesetzt,
    // bei sämtlichen artikel in der itemList in Auftragsdaten

    const deliverToAddresse = ((auftragsDaten?.deliverTo?.name1)
        ? createAddressString(auftragsDaten.deliverTo)
        : '=Rechnungsanschrift');
/*     if (true) {
        auftragsDaten.docContent.invoiceFooterNotes = "Lieferanschrift: " + deliverToAddresse;
    }; */

    if (store.companySettings.showEanInDocuments) {
        auftragsDaten.productItemList?.forEach((item) => {
            if (item.ean) {
                item.itemDescription = "EAN: " + item.EAN + " " + item.itemDescription;
            }
        });
    }

    const docId = orderDocumentId != null ? "/" + orderDocumentId : '';

    auftragsDaten.docContent.documentTitel = auftragsDaten.docContent.orderTitel;
    auftragsDaten.docContent.documentIntroduction = auftragsDaten.docContent.orderIntroduction;
    auftragsDaten.docContent.documentFinalText = auftragsDaten.docContent.orderFinalText;
    auftragsDaten.as_attachment = isDownload;
    //delete flatAuftrag.productItemList;
    let url = "/company";
    switch (proceedType) {
        case "previewOnly": url += "/download-order" + docId; break;
        case "saveOrder": url += "/create-order"; break;
        case "draftToOrder": url += "/draft-to-order" + docId; break;
        case "saveDraft": url += "/create-order-draft" + docId; break;
        case "downloadDraft": url += "/create-order-draft" + docId; break;
        case "saveDraftAndPreview": url += "/create-order-draft"; break;
        case "print": (readOnly ? url += "/download-order" + docId : url += "/create-order-draft" + docId); break;
        case "download": (readOnly ? url += "/download-order/" + documentDownlaod : url += "/create-order-draft/") + documentDownlaod; break;
        case "deleteDraft": break;
        default: console.log("Unbehandelter Proceed Type!"); break;
    }
    console.log("🚀 ~ file: orderUtils.js:112 ~ saveAndGetPDF ~ url:", url)
    console.log("🚀 ~ file: orderUtils.js:112 ~ saveAndGetPDF ~ docId:", docId)

    //patch
    if ((docId && !isOrder && !isDraftToOrder && !printOrder && !previewOnly && !downloadOnly) || downloadDraft || overwriteDraft) {
        await iAxios.patch(url, auftragsDaten, {
            responseType: 'blob',
        })
            .then((response) => {

                console.log("🚀 ~ file: orderUtils.js:140 ~ .then ~ response:", response)

                setDocNameFromHeader(response, injectedValue);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                    case "download": download(response.data, "Auftrag_" + auftragsDaten.receiver.name1 + "_" + formatDatum(auftragsDaten.docContent.auftragsDatum) + ".pdf", "application/pdf"); break;
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                    case "print": { printPDF(fileURL); break; }

                    default:
                        {
                            downloadPdf(blob, auftragsDaten, injectedValue); break;
                        }
                }
            })

    }
    //get
    else if (isDraftToOrder || printOrder || previewOnly || downloadOnly) {
        await iAxios.get(url, {


            responseType: 'blob',
        })
            .then((response) => {
                console.log("🚀 ~ file: orderUtils.js:153 ~ saveAndGetPDF ~ url:", url)

                console.log("🚀 ~ file: orderUtils.js:156 ~ .then ~ response:", response)

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "draftToOrder":
                        setDocNameFromHeader(response, injectedValue);
                        if (isDownload) {
                            download(response.data, "Auftrag_" + auftragsDaten.receiver.name1 + "_" + formatDatum(auftragsDaten.docContent.auftragsDatum) + ".pdf", "application/pdf"); break;
                        } else if (preview) {
                            injectedValue.data.pdfFile = fileURL; break;
                        } else {
                            break;
                        }
                    case "print": { console.log("print"); printPDF(fileURL); break; }
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "downloadOnly": download(response.data, "Auftrag_" + auftragsDaten.receiver.name1 + "_" + formatDatum(auftragsDaten.docContent.auftragsDatum) + ".pdf", "application/pdf"); break;

                    default:
                        {
                            downloadPdf(blob, auftragsDaten, injectedValue); break;
                        }
                }
            })
    }
    else {
        //post
        await iAxios.post(url, auftragsDaten, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then((response) => {

            console.log("🚀 ~ file: orderUtils.js:210 ~ saveAndGetPDF ~ response:", response)

            setDocNameFromHeader(response, injectedValue);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            switch (proceedType) {
                case "print": printPDF(fileURL); break;
                case "saveOrder":
                    console.log("🚀 ~ file: orderUtils.js:246 ~ injectedValue.data.toShipping:", injectedValue.data.toShipping)
                    if (auftragsDaten.toShipping) {
                        sendToShippingList(injectedValue);
                    }
                    if (preview) {
                        injectedValue.data.pdfFile = fileURL;
                        injectedValue.data.order.isDraft = false;
                        break;
                    } else {
                        injectedValue.data.order.isDraft = false;
                        break;
                    } case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                case "download": downloadPdf(blob, auftragsDaten, injectedValue); break;
                case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                default:
                    {
                        downloadPdf(blob, auftragsDaten, injectedValue); break;
                    }
            }


            if (isCancelled && isOrder) {
                console.log("🚀 ~ file: orderUtils.js:210 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)

                setDocumentStatus(injectedValue.data.auftragsDaten.relationFrom.orderCancelledFromId, "ORDER_CANCELED");
                setDocumentStatus(injectedValue.data.auftragsDaten.orderDocumentId, "ORDER_CORRECTION");
                setRelationToOrder(injectedValue.data.auftragsDaten);
            }

            console.log("🚀 ~ file: orderUtils.js:253 ~ saveAndGetPDF ~ isFromOffer:", isFromOffer)
            console.log("🚀 ~ file: orderUtils.js:254 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)


            if (isFromOffer) {
                setOrderRelationsInOffer(injectedValue.data.auftragsDaten);
            }


        })
            .catch((error) => {
                console.error(error);
            });
    }
    injectedValue.data.loading = false;

    return injectedValue;
};

const sendToShippingList = (injectedValue) => {
    try {
        const auftragsDaten = injectedValue.data.auftragsDaten;
        const orderDocumentId = auftragsDaten.orderDocumentId;
        const url = process.env.VUE_APP_BASE_API_URL + "/company/shipping-list";
        const bodyData = { orderList: [orderDocumentId] };
        iAxios.post(url, bodyData)
            .then((response) => {
                console.log("🚀 ~ file: orderUtils.js:282 ~ saveAndGetPDF ~ response:", response)
            })
            .catch((error) => {
                console.error(error);
            });
    }
    catch (error) {
        console.error(error);
    }
};

const setRelationToOrder = async (auftragsDaten) => {

    const orderDocumentId = auftragsDaten.relationFrom.orderCancelledFromId;
    const relationToId = auftragsDaten.orderDocumentId;
    const relationToDocName = auftragsDaten.relationFrom.docName;
    let url = process.env.VUE_APP_BASE_API_URL + "/company/order-edit";
    const bodyData =
    {
        "orderDocumentId": parseInt(orderDocumentId),
        "relationTo": { orderCorrectionId: relationToId, orderCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

const setOrderRelationsInOffer = async (auftragsDaten) => {
    const offerDocumentId = auftragsDaten.relationFrom.generatedFromOfferId;
    const relationToId = auftragsDaten.orderDocumentId;
    const relationToDocName = auftragsDaten.docName;

    let orders = [];
    let relationTo = {};
    await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/the-offer/" + offerDocumentId)
        .then((responseRelations) => {
            if (responseRelations.data?.relationTo) { relationTo = responseRelations.data.relationTo; };
            if (responseRelations.data.relationTo?.orders) { orders = responseRelations.data.relationTo.orders; };
            relationTo.orders = orders;
        })
        .catch((error) => {
            console.error(error);
        });
    orders.push({ orderDocumentId: relationToId, orderDocName: relationToDocName });

    let url = process.env.VUE_APP_BASE_API_URL + "/company/offer-edit";
    const bodyData =
    {
        "offerDocumentId": parseInt(offerDocumentId),
        "relationTo": relationTo,
        "createShipping": false,
    };
    console.log("🚀 ~ file: orderUtils.js:348 ~ bodyData:", bodyData)

    let response = await iAxios.patch(url, bodyData)
        .then((response) => {

            console.log("🚀 ~ file: orderUtils.js:355 ~ response:", response)

        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};


const setDocNameFromHeader = (response, injectedValue) => {
    console.log('Alle Header:');
    Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    });

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {
        injectedValue.data.auftragsDaten.orderDocumentId = response.headers.orderdocumentid;
        injectedValue.data.order.orderDocumentId = response.headers.orderdocumentid;
        injectedValue.data.auftragsDaten.docName = response.headers.orderdocname;
    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    }
}

const downloadPdf = (blob, auftragsDaten, injectedValue) => {
    console.log("🚀 ~ file: orderUtils.js:285 ~ downloadPdf ~ injectedValue:", injectedValue)

    download(blob, "Auftrag_" + auftragsDaten.receiver.name1 + "_" + formatDatum(auftragsDaten.docContent.auftragsDatum) + "_" + auftragsDaten.docName + ".pdf", "application/pdf");
};



/**Vorraussetzung eines iframe mit der id=pdfFrame setzen in der Komponente von der aus der Druck aufgerufen wird.
 * <iframe id="pdfFrame" style="display:none"></iframe>
 * @param {*} pdfFile -url der PDF-Datei.
 * @returns
 */
export const printPDF = async (pdfFile) => {
    const iframe = document.getElementById('pdfFrame');
    iframe.src = pdfFile;

    // Warten, bis die PDF-Datei geladen wurde
    await new Promise((resolve) => {
        iframe.onload = resolve;
    });
    iframe.contentWindow.print();
};

export const setDocumentStatus = async (orderDocumentId, status) => {

    console.log("🚀 ~ file: orderUtils.js:294 ~ setDocumentStatus ~ status:", status)


    console.log("🚀 ~ file: orderUtils.js:295 ~ setDocumentStatus ~ orderDocumentId:", orderDocumentId)

    if (isNaN(orderDocumentId)) {
        console.error("orderDocumentId ist keine Zahl!: " + orderDocumentId);
        return false;
    }
    let url = process.env.VUE_APP_BASE_API_URL + "/company/order-status";
    const formData = new FormData();
    formData.append("orderDocumentId", parseInt(orderDocumentId));
    formData.append("documentStatus", status);
    let response = await iAxios.post(url, formData)
        .then((response) => {
            //  console.log("🚀 ~ file: orderUtils.js:18 ~ .then ~ response:", response)
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

export const setOrderPayStatus = (auftragsDaten) => {
    const payProgress = auftragsDaten?.payProgress;
    const payInput = payProgress?.paymentInput;
    let totalAmount = auftragsDaten?.docContent?.documentValueBrutto;
    if (!payProgress || !payInput) return { totalPaid: 0, status: "OFFEN" };
    let totalPaid = 0.0;
    payInput.forEach((payment) => {
        if (payment.event != "PAYMENT_DELETED") {
            totalPaid += payment.paymentAmount;
        }
    });
    totalPaid = parseFloat(totalPaid.toFixed(2)); // ist nötig wegen Rundungsfehler in javascript
    totalAmount = parseFloat(totalAmount.toFixed(2));
    if (totalPaid >= totalAmount) {
        setDocumentStatus(auftragsDaten.orderDocumentId, "ORDER_PAID");
        console.log("ORDER_PAID")
        return { totalPaid: totalPaid, status: "BEZAHLT" };
    } if (totalPaid < totalAmount && totalPaid > 0) {
        console.log("ORDER_PARTIAL")
        setDocumentStatus(auftragsDaten.orderDocumentId, "ORDER_PARTIAL");
        return { totalPaid: totalPaid, status: "TEILZAHLUNG" };
    }
    console.log("ORDER_OPEN")
    setDocumentStatus(auftragsDaten.orderDocumentId, "ORDER_OPEN");
    return { totalPaid: totalPaid, status: "OFFEN" };
};


export const getOrderDeliverySlip = async (injectedValue, preview) => {

    console.log("🚀 ~ file: orderUtils.js:391 ~ injectedValue:", injectedValue)

    const deliveryDocumentId = injectedValue.data.auftragsDaten.deliveryDocumentId;
    const auftragsDaten = injectedValue.data.auftragsDaten;
    auftragsDaten.createShipping = false;
    auftragsDaten.docContent.documentTitel = auftragsDaten.docContent.deliveryTitel;
    auftragsDaten.docContent.documentIntroduction = auftragsDaten.docContent.deliveryIntroduction.replaceAll("[DOCNR]", auftragsDaten.orderDocName);
    auftragsDaten.docContent.documentFinalText = auftragsDaten.docContent.deliveryFinalText;
    if (isNaN(deliveryDocumentId)) {
        console.error("deliveryDocumentId ist keine Zahl!: ");
        return false;
    }
    const inline = preview ? "/inline" : "";
    const url = process.env.VUE_APP_BASE_API_URL + "/company/delivery-doc/" + deliveryDocumentId + inline;
    let response = await iAxios.put(url, auftragsDaten, {
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        if (preview) {
            injectedValue.data.pdfFile = fileURL;
        } else {
            download(blob, "Lieferschein_" + deliveryDocumentId + ".pdf", "application/pdf");
        }
    }).catch((error) => {
        console.error(error);
    });
    return true;
}

///TODO: order: Säubern des Codes und optimieren sowie entfernen der Consolenausgaben
///TODO: order: FACK: Teilzahlung

///TODO: DokumentenSettings: design anpassen
///TODO: Order/Auftragsverwaltung: erstellen von Sammelauftragen
///TODO: Order/Auftragsverwaltung: umwandeln in Angebote
///TODO: Order/Auftragsverwaltung: erstellen von Auftragen
///TODO: Authentifikation: Login Prozess korrieren
///TODO: Benutzer: Mitarbeiter anlegen, bearbeiten, löschen, Username
///TODO: Offer: Angebot erstellen, bearbeiten, löschen, versenden, drucken, downloaden, in Auftrag verwandeln, in Auftrag umwandeln
///TODO: Backend: Lieferschein QRCODE Navigation starten