// Deine footer-Variable
const footer = ``;

// Dein Tip-Array mit der aktualisierten "enable"-Eigenschaft für die Checkbox
const tips = [
  {
    tipId: 'erweiterteAnsichtAuswahl',
    title: 'Weitere Eigenschaften aktivieren/Deaktiveren',
    contentText: 'Hier können Sie weitere Eigenschaften aktivieren oder deaktivieren. Wie z.B. die Erweiterte Ansicht, Alias, Öffentlich..',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'contactType',
    title: 'Kontakt Art',
    contentText: 'Wählen Sie hier die Art des Kontakts aus. z.B. Kunde, Lieferant, Kontakt.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'vkPreis',
    title: 'Verkauspreis',
    contentText: 'Gehen Sie mit der Maus über die Bezeich VK-Preis(Verkaufspreis), um verschiedene Preise einzugeben oder zu sehen. Klicken Sie dazu jeweils auf VK-1 oder VK-2...',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'orderToInvoiceTip',
    title: 'Auftrag -> Rechnung',
    contentText: 'Hier können Sie aus einer oder mehrere Aufträgen, des selben Empfängers, zusammenfassend oder einzeln eine Rechnung erstelen lassen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'logOutTip',
    title: 'Abmelden',
    contentText: 'Hier können Sie sich abmelden.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'deleteUserTip',
    title: 'Kunde Löschen',
    contentText: 'Hier können Sie diesen Kunden löschen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'generateInvoiceTip',
    title: 'Auftrag in Rechnung umwandeln',
    contentText: 'Aufträge können in Rechnungen umgewandelt werden.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'settingsTip',
    title: 'Einstellungen',
    contentText: 'Hier können Sie die Einstellungen Ihres Unternehmens vornehmen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, 
  },
  {
    tipId: 'noCustomersTip',
    title: 'Keine Kunden verfügbar!',
    contentText: 'Bitte legen Sie zunächst einen Kunden an.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editCustomerTip',
    title: 'Kunden Bearbeiten!',
    contentText: 'Hier können Sie die Kundenakte<br> Ansehen & Bearbeiten.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editItemTip',
    title: 'Artikel Bearbeiten!',
    contentText: 'Hier können Sie den jeweiligen Artikel, aufrufen und bearbeiten.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'itemSettingsTip',
    title: 'Variante/Anzahl nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick auf "Anzahl" erreichen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'itemViewTip',
    title: 'Artikel Bearbeiten',
    contentText: 'Hier können Sie die Artkel Bearbeiten',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'hilfsMittelSettingsTip',
    title: 'Pflegehilfsmittel nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick hier erreichen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoItemsTip',
    title: 'Aktuell sind keine Artikel verfügbar.',
    contentText: 'Bevor ein Kunde angelegt werden kann, müssen Artikel vorhanden sein.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterOrAbortTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button [Weiter] betätigen oder [Abbrechen] für Abbruch.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button > Weiter < betätigen.',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoBoxesTip',
    title: 'Auswahl Boxen',
    contentText: 'Es sind keine Boxen zusammengestellt worden. Menü Katalog->Boxen zum hinzufügen von Boxen ',
    contentIcon: 'pi pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
];

export default tips;