<template>
    <div class="block-content">
      <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div class="text-center mb-5">
            <img src="@/assets/logo.png" alt="Image" height="50" class="mb-3" />
            <div class="text-900 text-3xl font-medium mb-3">
              Passwort zurücksetzen
            </div>
          </div>
          <div class="p-grid p-gap-2">
            <div class="p-col">
              <form @submit="resetPassword">
                <div class="p-field">
                  <Password
                    v-model="password"
                    id="password"
                    :class="{ 'p-invalid': errors.password }"
                  />
                  <div class="p-invalid">{{ errors.password }}</div>
                </div><p></p>
  
                <div class="p-field">
                  <Password
                    v-model="confirmPassword"
                    id="confirmPassword"
                    :class="{ 'p-invalid': errors.confirmPassword }"
                  />
                  <div class="p-invalid">{{ errors.confirmPassword }}</div>
                </div><p></p>
                <Button label="Passwort zurücksetzen" @click="resetPassword()"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useForm } from "vee-validate";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import * as yup from "yup";
import { inject } from "vue";

const dialogRef = inject("dialogRef");
const toast = useToast();
const router = useRouter();
const route = useRoute();

// Token aus dem Routenparameter auslesen
const token = ref(route.params.token);

// Formularfelder
const password = ref("");
const confirmPassword = ref("");

// Yup-Schema für die Validierung
const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password ist erforderlich")
    .min(6, "Password mindestens 6 Zeichen"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwörter müssen übereinstimmen")
    .required("Passwortbestätigung ist erforderlich"),
});

// VeeValidate-Formularhaken
const { handleSubmit, resetForm, errors } = useForm({
  validationSchema,
});

// Methode zum Zurücksetzen des Passworts
async function resetPassword() {
  try {
     handleSubmit();
    // Führe den API-Aufruf zum Zurücksetzen des Passworts mit iAxios durch
    const url=process.env.VUE_APP_BASE_API_URL+"/pass-reset";

    const response = await iAxios.post(url, {
      token: token.value,
      password: password.value,
    });
    // Zeige eine Erfolgsmeldung an
    toast.add({
      severity: "success",
      summary: "Passwort erfolgreich zurückgesetzt",
      detail: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
    });
    // Formular zurücksetzen
    resetForm();
    router.push({ name: "login" });
  } catch (error) {
    // Zeige eine Fehlermeldung an
    if (response.status==403){toast.add({
      severity: "error",
      summary: "Fehler beim Zurücksetzen des Passworts",
      detail: "Das Password wurde bereits zurückgesetzt, im Zweifelsfall starten Sie eine erneute Anfrage zum Zurücksetzeten des Passworts.",
    });}else
    {toast.add({
      severity: "error",
      summary: "Fehler beim Zurücksetzen des Passworts",
      detail: "Beim Zurücksetzen des Passworts ist ein Fehler aufgetreten.",
    });}
  }
}

const closeDialog = (e) => {
    dialogRef.value.close(e);
};
</script>
