<template>
  <Menubar :model="menuItems">
    <template #item="{ item, props, hasSubmenu, root }">
      <a v-ripple class="flex align-items-center" v-bind="props.action">
        <span class="ml-2 w-auto text-xs">
          {{ item.label }}
        </span>
        <Badge
          v-if="item.badge"
          :class="{ 'ml-auto': !root, 'ml-2': root }"
          :value="item.badge" />
        <span
          v-if="item.shortcut"
          class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
          >{{ item.shortcut }}</span
        >
        <i
          v-if="hasSubmenu"
          :class="[
            'pi pi-angle-down',
            { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
          ]"></i>
      </a>
    </template>
    <template #end>
    <span>von:</span><Calendar v-model="timeFrom" :showButtonBar="true" type="date" inputClass="text-xs w-6rem h-2rem"/>
  <span class="pl-2">bis:</span><Calendar v-model="timeTo" :showButtonBar="true" type="date" inputClass="text-xs w-6rem h-2rem"/>
    </template>
  </Menubar>
  <div>
    <div class="p-2 m-2 text-base bg-gray-50">
      <div class="flex justify-content-between">
        <span>Umsatz({{ zeitAuswahlLabel }}): </span
        ><span>{{ formatCurrency(totalAmount) }}</span>
      </div>
      <div class="flex justify-content-between text-green-500">
        <span>Bezahlt({{ zeitAuswahlLabel }}): </span
        ><span>{{ formatCurrency(totalPaid) }}</span>
      </div>
      <Divider />
      <div
        class="flex justify-content-between font-bold"
        :class="
          totalPaid < totalAmount
            ? 'text-red-500'
            : totalPaid > 0 && totalPaid < totalAmount
            ? 'text-orange-500'
            : 'text-green-500'
        ">
        <span>Kontostand({{ zeitAuswahlLabel }}): </span>

        <span>{{ formatCurrency(totalPaid - totalAmount) }}</span>
      </div>
    </div>
  </div>
  <div>
    <Button
      outlined
      size="small"
      label="Zahlung hinzufügen"
      icon="pi pi-plus"
      @click="openPayment" />
  </div>
  <span v-if="allPayInputs">{{
    console.log("allPayInputs", allPayInputs)
  }}</span>
  <DataTable
    v-if="allPayInputs"
    :value="allPayInputs"
    selectionMode="single"
    stripedRows
    :rows="15"
    :paginator="true"
    size="small"
    class="text-xs"
    >
    <Column field="" style="width: 0.1%" class="colClass">
      <template #body="slotProps" v-if="showAdvanced">
        <Button
          text
          icon="pi pi-search"
          @click="toggleDetails($event, slotProps.data)"
          size="small"
          class="p-0 m-0" />
      </template>
    </Column>
    <Column
      field="paymentDate"
      header="Datum"
      sortable
      style="width: 8%"
      class="colClass">
      <template #body="slotProps">
        <span
          :class="
            slotProps.data.event == 'PAYMENT_DELETED'
              ? 'line-through text-red-300'
              : ''
          "
          >{{ formatDatumZeit(slotProps.data.paymentDate) }}</span
        >
      </template>
    </Column>
    <Column
      field="paymentMethod"
      header="Bezahlart"
      sortable
      style="width: 5%"
      class="colClass">
      <template #body="slotProps">
        <span
          :class="
            slotProps.data.event == 'PAYMENT_DELETED'
              ? 'line-through text-red-300'
              : ''
          "
          >{{ slotProps.data.paymentMethod }}</span
        >
      </template>
    </Column>
    <Column field="paymentAmount" sortable style="width: 5%" class="colClass">
      <template #header="slotProps">
        <div class="w-full text-right">Betrag</div>
      </template>
      <template #body="slotProps">
        <div
          class="text-right w-full"
          :class="
            slotProps.data.event == 'PAYMENT_DELETED'
              ? 'line-through text-red-300'
              : ''
          ">
          {{ formatCurrency(slotProps.data.paymentAmount) }}
        </div>
      </template>
    </Column>
    <Column
      field="comment"
      header="Kommentar"
      sortable
      style="width: 10%"
      class="colClass">
      <template #body="slotProps">
        {{ slotProps.data.comment }}
      </template>
    </Column>
    <Column style="width: 0.1%" class="colClass">
      <template #body="slotProps" v-if="showAdvanced">
        <Button
          :icon="
            store.isMainUser && slotProps.data.event == 'PAYMENT_DELETED'
              ? 'pi pi-replay p-0 m-0'
              : 'pi pi-trash p-0 m-0'
          "
          size="small"
          text
          @click="deletePayment(slotProps.data)"
          :severity="
            slotProps.data.event != 'PAYMENT_DELETED' ? 'danger' : 'secondary'
          "
          class="p-0 m-0" />
      </template>
    </Column>
  </DataTable>

  <OverlayPanel ref="detailsOpRef" appendTo="body" class="w-5">
    <div v-for="log in payments.log" class="grid">
      <div class="col-3">
        <span>{{ formatDatumZeit(log.eventTime) }}</span>
      </div>
      <div class="col-3">
        <span>{{ log.userName }}</span>
      </div>
      <div class="col-3">
        <span>{{ eventLabel[log.eventAction] }}</span>
      </div>
      <div class="col-3">
        <span>{{ payments.docName }}</span>
      </div>
    </div>
  </OverlayPanel>
</template>
<script setup>
import { computed, onMounted, ref, defineAsyncComponent } from "vue";
import { formatCurrency } from "@/utils/formatUtils";
import { formatDatumZeit } from "@/utils/formatUtils";
import { userStore } from "@/store/userStore";
import iAxios from "@/store/axiosInterface";
import { setInvoicePayStatus } from "@/utils/invoiceUtils";
import { setOrderPayStatus } from "@/utils/orderUtils";
import { timeSelect } from "@/utils/timeSelectUtils";
import { useDialog } from "primevue/usedialog";
const dialog = useDialog();
const CustomerPaymentInput = defineAsyncComponent(() =>
  import("@/components/CustomerPaymentInput.vue")
);
const eventLabel = ref({
  ADDED: "Zahlung hinzugefügt",
  DELETED: "Zahlung Entfernt",
});
const store = userStore();
const { customer, customerHistory, showAdvanced } =
  defineProps({
    customerHistory: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    showAdvanced: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

const zeitAuswahlLabel = ref("Gesamt");
const timeFrom = ref();
const timeTo = ref();
const zeitAuswahl = (zeitAuswahl) => {
  timeSelect(zeitAuswahl,timeFrom,timeTo,zeitAuswahlLabel);
};
const menuItems = ref([
    {
      label: "Gesamt",
      command: () => {
        zeitAuswahl("Gesamt");
      },
    },
    {
      label: "Tage",
      items: [
        {
          label: "7 Tage",
          command: () => {
            zeitAuswahl("7 Tage");
          },
        },
        {
          label: "14 Tage",
          command: () => {
            zeitAuswahl("14 Tage");
          },
        },
        {
          label: "30 Tage",
          command: () => {
            zeitAuswahl("30 Tage");
          },
        },
      ],
    },
    {
      label: "Monate",
      items: [
        {
          label: "1 Monat",
          command: () => {
            zeitAuswahl("1 Monat");
          },
        },
        {
          label: "3 Monate",
          command: () => {
            zeitAuswahl("3 Monate");
          },
        },
        {
          label: "6 Monate",
          command: () => {
            zeitAuswahl("6 Monate");
          },
        },
        {
          label: "9 Monate",
          command: () => {
            zeitAuswahl("9 Monate");
          },
        },
      ],
    },
    {
      label: "Jahre",
      items: [
        {
          label: "1 Jahr",
          command: () => {
            zeitAuswahl("1 Jahr");
          },
        },
        {
          label: "3 Jahre",
          command: () => {
            zeitAuswahl("3 Jahre");
          },
        },
        {
          label: "5 Jahre",
          command: () => {
            zeitAuswahl("5 Jahre");},
        },
      ],
    },
  ]);




onMounted(() => {
  store.getWhoAmIData;
  console.log(
    "🚀 ~ file: DocumentPaymentList.vue:34 ~ allPayInputs.value",
    allPayInputs.value
  );
});



// alle order Payprogresses aus dem customerHistory ausser den mit relationTo.invoice
const concatPayInputs = () => {
  const customerPayprogress=customer?.extended?.payProgress?.paymentInput || [];


  const allInvoicePaymentInputs =
    customerHistory?.invoices?.flatMap((invoice) =>
      invoice.payProgress?.paymentInput ? invoice.payProgress.paymentInput : []
    ) || [];
  const allOrderPaymentInputs =
    customerHistory?.orders
      ?.filter((order) => !order.relationTo?.invoice)
      .flatMap((order) =>
        order.payProgress?.paymentInput ? order.payProgress.paymentInput : []
      ) || [];

  const allCustomerPaymentInputs = [...customerPayprogress];

  let allPaymentInputs = [...allInvoicePaymentInputs, ...allOrderPaymentInputs, ...allCustomerPaymentInputs];
  return allPaymentInputs;
};

const allPayInputs =computed(()=>{
  const all = concatPayInputs();
  if (timeFrom?.value && timeTo?.value) {
    let filteredPayInputs = all.filter((payInput) => {
      return (
        new Date(payInput.paymentDate) >= timeFrom.value &&
        new Date(payInput.paymentDate) <= timeTo.value
      );
    });
    if (filteredPayInputs.length == 0)
      return null;

    return filteredPayInputs;
  }
  return all;
});

//zusammenführen von order(ausser den mit relationTo.invoice) und invoice documents
const concatAllDocuments = () => {
  const allInvoices = customerHistory?.invoices || [];
  const allOrders = customerHistory?.orders?.filter(
    (order) => !order.relationTo?.invoice
  )|| []; 
  let allDocuments = [...allInvoices, ...allOrders];

  console.log("🚀 ~ file: CustomerPaymentList.vue:363 ~ allDocuments:", allDocuments)

  return allDocuments;
};

//zusammenrechnen der totalAmounts von allen documents
const totalAmount = computed(() => {
  let totalAmount = 0;
  const allDocs = concatAllDocuments();
  if (!allDocs) return 0;
  //filtern nach Zeit
  if (timeFrom?.value && timeTo?.value) {
    let filteredDocs = allDocs.filter((doc) => {
      return (
        new Date(doc.docContent.leistungsdatum) >= timeFrom.value &&
        new Date(doc.docContent.leistungsdatum) <= timeTo.value
      );
    });
    if (filteredDocs.length == 0) return 0;
    let totalAmount = 0;
    filteredDocs.forEach((doc) => {
      totalAmount += doc.docContent.documentValueBrutto;
    });
    return totalAmount;
  }
  allDocs.forEach((doc) => {
    totalAmount += doc.docContent.documentValueBrutto;
  });
  return totalAmount;
});

//berechnen des payStatus von allPayInputs
const totalPaid = computed(() => {
  let totalPaid = 0.0;
  if (!allPayInputs.value) return totalPaid;
  allPayInputs.value.forEach((payment) => {
    if (payment.event != "PAYMENT_DELETED") totalPaid += payment.paymentAmount;
  });
  return totalPaid;
});

const payments = ref([]);
const detailsOpRef = ref();
const toggleDetails = (event, payment) => {
  console.log("🚀 ~ file: DocumentPaymentList.vue:176 ~ payment:", payment);

  payments.value = payment;

  detailsOpRef.value.toggle(event);
};

const deletePayment = (payment) => {
  console.log("🚀 ~ file: DocumentPaymentList.vue:158 ~ payment:", payment);

  if (payment.event == "PAYMENT_DELETED" && store.isMainUser) {
    undoDeletePayment(payment);
    return;
  }

  payment.event = "PAYMENT_DELETED";
  payment.log.push({
    eventAction: "DELETED",
    eventTime: new Date(),
    userUid: store.whoAmIData.cid,
    userName:
      store.whoAmIData.firstName ??
      store.whoAmIData.firmenName + " " + store.whoAmIData.surName ??
      store.whoAmIData.userName,
    userRole: store.whoAmIData.role,
  });
  savePayProgressDocument(payment);
};

const undoDeletePayment = (payment) => {
  payment.event = "PAYMENT_ADDED";
  payment.log.push({
    eventAction: "ADDED",
    eventTime: new Date(),
    inputMode: payment.inputMode,
    userUid: store.whoAmIData.cid,
    userName:
      store.whoAmIData.firstName ??
      store.whoAmIData.firmenName + " " + store.whoAmIData.surName ??
      store.whoAmIData.userName,
    userRole: store.whoAmIData.role,
  });
  savePayProgressDocument(payment);
};

const savePayProgressDocument = async (payment) => {
  if (payment.inputMode == "Manuell") {
    const url = "/company/customer-details";
    iAxios.patch(url, customer).then((response) => {
     if(store.debug) console.log(
        "🚀 ~ file: CustomerPaymentList.vue:161 ~ deletePayment ~ response:",
        response
      );
    });
    return;
  }

  console.log("darf garnicht hier runterkommen",payment)
  let docData = null;
  const isOrder = payment.inputMode == "Auftrag";
  const docId = payment.docId;
  const urlPatch = isOrder ? "/company/order-edit" : "/company/invoice-edit";
  const urlGet = isOrder ? "/company/the-order/"+ docId : "/company/the-invoice/"+ docId;

  await iAxios.get(urlGet).then((response) => {
    docData = response.data;
    console.log("🚀 ~ file: CustomerPaymentList.vue:313 ~ docData:", docData);
  });

  //finde als nöchstes die paymentInput und un ersetze sie mit der neuen
  const payProgress = await docData.payProgress;
  const payInput = payProgress.paymentInput;
  const index = payInput.findIndex((p) => p.uId == payment.uId);
  payInput[index] = payment;
  payProgress.paymentInput = payInput;
  docData.payProgress = payProgress;
  if (docId) {
    iAxios.patch(urlPatch, docData).then((response) => {
      console.log(
        "🚀 ~ file: CustomerPaymentList.vue:161 ~ deletePayment ~ response:",
        response
      );
    });

    if (isOrder) {
      setOrderPayStatus(docData);
    } else {
      setInvoicePayStatus(docData);
    }
  }
};


const openPayment = () => {
  dialog.open(CustomerPaymentInput, {
    props: {
      header: "Zahlung",
      style: {
        width: "40vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "831px": "100vw",
      },
      modal: true,
      closable: true,
    },
    data: {
      customer: customer,
      totalAmount: totalAmount.value,
      totalPaid: totalPaid.value,
      allDocuments: concatAllDocuments(),
      payInputs: concatPayInputs(),
    },
    templates: {},
    onClose: async (options) => {
      const data = options?.data;
      if (data) {

      }
    },
  });
};
</script>
