<template>
  <div class="dialog-content">
    <Form @submit.prevent="saveForm">
      <div>
        <div class=" ">
          <div class="pl-5 pr-4">
            <div v-if="dialogData.isPrivateCustomer" class="formgrid grid">
              <div class="field col-12 md:col-2">
                <label for="salutation" class="feldLabel">Anrede</label>
                <InputText
                  v-model="dialogData.salutation"
                  id="salutation"
                  class="w-full" />
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstName" class="feldLabel">Vorname</label>
                <InputText
                  v-model="dialogData.firstName"
                  id="firstName"
                  class="w-full" />
              </div>
              <div class="field col-12 md:col-6">
                <label for="surName" class="feldLabel">Nachname</label>
                <a
                  href="#"
                  v-show="!zusatzFeldName && dialogData.name2 === ''"
                  @click="zusatzFeldName = true"
                  class="text-xs pl-5">
                  Zusatz hinzufügen</a
                >
                <InputText
                  v-model="dialogData.surName"
                  id="surName"
                  type="text"
                  class="w-full" />
              </div>
              <div class="col-12">
                <InputText
                  v-if="zusatzFeldName || dialogData.name2 !== ''"
                  v-model="dialogData.name2"
                  id="customerCompanyName"
                  placeholder="Zusatz"
                  class="w-10 md:w-11"
                  type="text" /><Button
                  text
                  severity="danger"
                  v-show="zusatzFeldName || dialogData.name2 !== ''"
                  icon="pi pi-minus"
                  v-tooltip="'Zusatzfeld entfernen'"
                  @click="entferneZusatzfeldName2" />
              </div>
            </div>
            <div v-else class="w-full">
              <div class="field">
                <label for="customerCompanyName" class="feldLabel"
                  >Firmenname</label
                >
                <a
                  href="#"
                  v-show="!zusatzFeldName || dialogData?.name2 === ''"
                  @click="zusatzFeldName = true"
                  class="text-xs pl-5">
                  Zusatz hinzufügen</a
                >

                <div class="w-full">
                  <InputText
                    v-model="dialogData.firmenName"
                    id="customerCompanyName"
                    class="w-10 md:w-11"
                    type="text" />
                  <InputText
                    v-if="zusatzFeldName || dialogData?.name2?.length > 0"
                    v-model="dialogData.name2"
                    id="customerCompanyName"
                    placeholder="Zusatz"
                    class="w-10 md:w-11"
                    type="text" /><Button
                    text
                    severity="danger"
                    v-show="zusatzFeldName || dialogData?.name2?.length > 0"
                    icon="pi pi-minus"
                    v-tooltip="'Zusatzfeld entfernen'"
                    @click="entferneZusatzfeldName2" />
                </div>
              </div>
            </div>
          </div>
          <Fieldset
            legend="Rechnungsanschrift"
            class="field col-12"
            :pt="{
              legend: { class: 'bg-white border-none' },
              legendTitle: { class: 'text-primary' },
              togglerIcon: { class: 'text-primary' },
            }">
            <div class="formgrid grid">
              <div class="field col-9 md:col-9">
                <label for="street" class="feldLabel">Straße</label>
                <a
                  href="#"
                  v-show="!zusatzFeldAddress && dialogData.address2 === ''"
                  @click="zusatzFeldAddress = true"
                  class="text-xs pl-5">
                  Zusatz hinzufügen</a
                >
                <InputText
                  class="w-full"
                  id="street"
                  v-model="dialogData.street" />
              </div>
              <div class="field col-3 md:col-3">
                <label for="houseNumber" class="feldLabel">Hausnr</label>
                <InputText
                  class="w-full"
                  id="houseNumber"
                  v-model="dialogData.houseNumber" />
              </div>
              <div
                v-if="zusatzFeldAddress || dialogData.address2 !== ''"
                class="field col-12">
                <InputText
                  v-model="dialogData.address2"
                  id="customerCompanyName"
                  placeholder="Zusatz"
                  class="w-10 md:w-11"
                  type="text" /><Button
                  text
                  severity="danger"
                  v-show="zusatzFeldAddress || dialogData.address2 !== ''"
                  icon="pi pi-minus"
                  v-tooltip="'Zusatzfeld entfernen'"
                  @click="entferneZusatzfeldAddress2" />
              </div>
              <div class="field col-3 md:col-3">
                <label for="postCode" class="feldLabel">PLZ</label>
                <InputText
                  class="w-full"
                  id="postCode"
                  v-model="dialogData.postCode" />
              </div>
              <div class="field col-9 md:col-9">
                <label for="city" class="feldLabel">Ort</label>
                <InputText class="w-full" id="city" v-model="dialogData.city" />
              </div>
            </div>
          </Fieldset>

          <Fieldset
            legend="Kontaktdaten/Details"
            :toggleable="true"
            class="field col-12"
            :collapsed="true"
            :pt="{
              legend: { class: 'bg-white border-none' },
              legendTitle: { class: 'text-primary' },
              togglerIcon: { class: 'text-primary' },
            }">
            <div v-if="!dialogData.isPrivateCustomer" class="formgrid grid">
              <div class="field col-12 md:col-2">
                <label for="salutation" class="feldLabel">Anrede</label>
                <InputText
                  v-model="dialogData.salutation"
                  id="salutation"
                  class="w-full" />
              </div>
              <div class="field col-12 md:col-4">
                <label for="firstName" class="feldLabel">Vorname</label>
                <InputText
                  v-model="dialogData.firstName"
                  id="firstName"
                  class="w-full" />
              </div>
              <div class="field col-12 md:col-6">
                <label for="surName" class="feldLabel">Nachname</label>
                <InputText
                  v-model="dialogData.surName"
                  id="surName"
                  type="text"
                  class="w-full" />
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="phone" class="feldLabel">Festnetznummer</label>
                <InputText
                  class="w-full"
                  id="phone"
                  v-model="dialogData.phone" />
              </div>
              <div class="field col-12 md:col-6">
                <label for="phone" class="feldLabel">Mobilnummer</label>
                <InputText
                  v-if="isNew || !dialogData.mobilePhone"
                  class="w-full"
                  id="phone"
                  v-model="dialogData.mobilePhone" />
                <InputGroup v-if="!isNew && dialogData.mobilePhone">
                  <a
                    :href="'https://wa.me/' + dialogData.mobilePhone"
                    target="_blank"
                    rel="noopener"
                    class="pl-2">
                    <i class="pi pi-whatsapp text-green-400"></i>
                  </a>
                  <a
                    :href="'https://t.me/' + dialogData.mobilePhone"
                    class="pl-2"
                    target="_blank"
                    rel="noopener">
                    <i class="pi pi-telegram text-blue-400"></i>
                  </a>
                  <InputText
                    class="w-full"
                    id="phone"
                    v-model="dialogData.mobilePhone" />
                </InputGroup>
              </div>
              <div class="field col-12 md:col-6">
                <label for="emailAddress" class="feldLabel"
                  >E-Mail Adresse</label
                >
                <InputText
                  class="w-full"
                  id="emailAddress"
                  v-model="dialogData.emailAddress" />
              </div>
              <div class="field col-12 md:col-6">
                <label for="vk" class="feldLabel">VK - Stufe</label>
                <Dropdown
                  class="w-full"
                  id="vk"
                  v-model="dialogData.defaultVk"
                  :options="calcUtils.vkStufen.value"
                  optionLabel="optionLabel"
                  optionValue="optionValue" />
              </div>
              <div
                class="field col-12 md:col-6"
                v-if="dialogData.contactType == 'CUSTOMER'">
                <label for="status" class="feldLabel">Status</label>
                <Dropdown
                  class="w-full"
                  id="status"
                  v-model="dialogData.accountStatus"
                  :options="calcUtils.statuses"
                  optionLabel="label"
                  optionValue="value">
                  <template #value>
                    <Tag
                      :value="dialogData.accountStatus"
                      class="text-base"
                      :class="dialogData.accountStatus" />
                  </template>
                  <template #option="slotProps">
                    <Tag
                      :value="slotProps.option.label"
                      class="text-base"
                      :class="slotProps.option.value" />
                  </template>
                </Dropdown>
              </div>
              <div class="field col-12 md:col-6">
                <div></div>
                <Button
                  v-if="!isNew"
                  label="Historie, Notizen, Zahlungen->"
                  text
                  @click="sideAdvancedToggle" />
              </div>
            </div>
          </Fieldset>
          <Fieldset
            legend="weitere Anschriften"
            :collapsed="false"
            :toggleable="true"
            class="field col-12"
            :pt="{
              legend: { class: 'bg-white border-none' },
              legendTitle: { class: 'text-primary' },
              togglerIcon: { class: 'text-primary' },
            }">
            <div
              v-if="!delievryAddressAvailable"
              class="flex align-items-center gap-2">
              <span>Lieferadresse = Rechnungsadresse</span>
              <Button
                outlined
                size="small"
                icon="pi pi-ellipsis-h"
                @click="toggle"
                aria-haspopup="true"
                aria-controls="overlay_menu" />
              <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
              <div class="col">
          <label for="customerEditorField" class="w-3">{{ store.companySettings.companyEditorField }}: </label>
          <InputText v-model="dialogData.customerEditorField" class="w-3" />
        </div>
            </div>
            <div v-else>
              <Button
                outlined
                class="mb-2"
                size="small"
                icon="pi pi-plus"
                label="weitere Adresse hinzufügen"
                @click="openExtraAddressDialog()" />
            </div>
            <ScrollPanel
              style="width: 100%"
              :class="dialogData?.extraAddress?.list ? 'h-12rem' : ''">
              <div class="flex flex-wrap gap-1">
                <template
                  v-if="dialogData?.extraAddress?.list"
                  v-for="(extraAddress, index) in dialogData.extraAddress.list">
                  <!-- Wenn keine Lieferadresse hinzugefügt wurde, also extraAddress.list.bezeichnung===Lieferadresse vorhanden ist,
                kommt eine Checkbox Lieferadresse=Rechnungadresse. Wenn die checkbox abgewählt wurde kommt eine Eingabe für angabe der 
                LIeferadresse. Wenn die checkbox abgewählt wurde und keine Adresse eingegeben wurde, ist es nicht Möglich diese zu speichern.-->
                  <Card
                    :pt="{ body: { class: 'bg-primary-50 border-round-lg' } }">
                    <template #header>
                      <div class="bg-primary-100">
                        <Button
                          text
                          icon="pi pi-pencil"
                          icon-pos="right"
                          class="p-button-rounded p-button-text p-button-plain"
                          @click="openExtraAddressDialog(index, 'edit')"
                          :label="extraAddress.bezeichnung" />
                      </div>
                    </template>
                    <template #content>
                      <div>{{ extraAddress.name1 }}</div>
                      <div>{{ extraAddress.name2 }}</div>
                      <div>
                        {{ extraAddress.street }} {{ extraAddress.houseNumber }}
                      </div>
                      <div>{{ extraAddress.address2 }}</div>
                      <div>
                        {{ extraAddress.postCode }} {{ extraAddress.city }}
                      </div>
                      <div class="pt-2" v-if="extraAddress.customerEditorField">
                        <span class="text-xs">
                        {{ store.companySettings.companyEditorField }}: {{ extraAddress.customerEditorField }}
                      </span>
                      </div>
                    </template>
                  </Card>
                </template>
              </div>
            </ScrollPanel>
          </Fieldset>
        </div>
        <div class="flex justify-content-end">
          <Button
            :loading="loadingConfirm"
            label="Abbrechen"
            @click="closeDialog"
            icon="pi pi-times"
            severity="danger"
            outlined
            class="font-medium text-lg mr-2"
            :disabled="saveButton">
          </Button>
          <Button
            :loading="loadingConfirm"
            label="Speichern"
            @click="saveForm"
            icon="pi pi-check"
            class="font-medium text-lg"
            :disabled="saveButton">
          </Button>
        </div>
      </div>
    </Form>

    <Sidebar
      v-model:visible="sideAdvancedShow"
      header="Sidebar"
      position="right"
      class="w-full h-10 lg:h-10 md:w-8 lg:w-7 p-3">
      <template #header>
        <div class="flex align-items-center gap-2">
          <div class="text-xl font-semibold">Erweiterte Details</div>
          <ToggleButton v-model="showAdvanced" onLabel="Erweitert" offLabel="Einfach" onIcon="pi pi-lock-open" 
    offIcon="pi pi-lock" class="w-9rem" aria-label="Do you confirm" />
        </div>
        </template>
      <div class="grid">
        <div class="col-2">
          <label for="searchTag" class="feldLabel">Suchtags</label>
        </div>
        <div class="col-5">
          <Chips v-if="dialogData?.searchTag?.tags"
            v-model="dialogData.searchTag.tags"
            separator=","
            class="w-full"
            addOnBlur
            inputClass="w-full"
            :max="2"
            v-tooltip="
              'Such-Begriffe anhand dessen der Kunde in der Liste gefunden werden kann'
            " />
        </div>
        <div class="col-3">
          <Button outlined class="text-xs mt-1"  v-tooltip="'Warnung bei keiner Aktivität nach .. tagen'" :severity="(dialogData.activityReminderAfterDays==0)?'secondary':'success'" :label="(dialogData.activityReminderAfterDays>0)?'Warnung Stummschalten':'Warnung Aktivieren'" @click="toggleMuteWarningActive()"/>
          </div>
          <div class="col-1">

          <InputNumber v-if="dialogData.activityReminderAfterDays!=0" v-model="dialogData.activityReminderAfterDays" inputClass="w-full" />

          </div>

        <div class="col-12">
          <label for="notes" class="feldLabel">Notizen</label>
          <Textarea
            v-model="dialogData.notes"
            rows="5"
            autoResize
            class="w-full" />
        </div>
      </div>

      <div>
        <TabView
          class="p-0"
          :pt="{
            panelContainer: { class: 'p-0 m-0' },
          }">
          <TabPanel header="Zahlungen">
            <CustomerPaymentList :customer="dialogData"
              :customerHistory="customerHistory" :showAdvanced="showAdvanced"/>
          </TabPanel>
          <TabPanel header="Rechnungen">
            <InvoicesView v-if="dialogData?.kundenNummer" :showAdvanced="showAdvanced" dtClass="text-xs" :kundenNummer="dialogData?.kundenNummer" />
          </TabPanel>
          <TabPanel header="Aufträge">
            <OrdersView v-if="dialogData?.kundenNummer" :showAdvanced="showAdvanced" dtClass="text-xs" :kundenNummer="dialogData?.kundenNummer" /> 
          </TabPanel>
          <TabPanel header="Angebote">
            <OffersView v-if="dialogData?.kundenNummer" :showAdvanced="showAdvanced" dtClass="text-xs" :kundenNummer="dialogData?.kundenNummer" /> 
          </TabPanel>
          <TabPanel header="Gutschriften">
            <CreditNoteView v-if="dialogData?.kundenNummer" :showAdvanced="showAdvanced" dtClass="text-xs" :kundenNummer="dialogData?.kundenNummer" /> 
          </TabPanel>
          <TabPanel header="Artikelverlauf">
            <ItemsView v-if="dialogData?.kundenNummer" :showAdvanced="showAdvanced" :showAdvancedMenu="false" dtClass="text-xs" :kundenNummer="dialogData?.kundenNummer" /> 
          </TabPanel>
          <TabPanel header="Zahlungsarten">
            <div class="p-3 bg-blue-100 pt-3">
              <i class="pi pi-info-circle"></i>
              Die Zahlungsarten können hier aktiviert oder deaktiviert werden.
              Wählen Sie die Zahlungsarten aus, die für diesen Kunden verfügbar
              sein sollen.
            </div>
            <div class="pb-3"></div>
            <div
              v-for="(payment, index) in dialogData.availablePaymentMethods
                ?.list"
              :key="index"
              class="flex align-items-center grid">
              <div class="col-6 text-sm">{{ payment.value }}</div>
              <div class="col-6 text-left">
                <InputSwitch v-model="payment.available" :binary="true" />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </Sidebar>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  onMounted,
  defineAsyncComponent,
  inject,
  markRaw,
  computed,
} from "vue";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import { CustomerValidator } from "@/validation/CustomerValidator";
import { useConfirm } from "primevue/useconfirm";
import iAxios from "@/store/axiosInterface";
import { useDialog } from "primevue/usedialog";
import * as calcUtils from "@/utils/calculationUtils.js";
import { formatDatum, formatCurrency } from "@/utils/formatUtils.js";
import CustomerPaymentList from "@/components/CustomerPaymentList.vue";
import InvoicesView from "./InvoicesView.vue";
import OrdersView from "./OrdersView.vue";
import OffersView from "./OffersView.vue";
import ItemsView from "./ItemsView.vue";
import CreditNoteView from "./CreditNoteView.vue";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

const store = userStore();
const dialog = useDialog();
const extraAddressDialog = defineAsyncComponent(() =>
  import("@/views/ExtraAddressInputDialog.vue")
);
const ExtraAddressInputDialogHeader = defineAsyncComponent(() =>
  import("@/views/ExtraAddressInputDialogHeader.vue")
);
const paymentData = ref([]);
const customerHistory = ref([]);
const toast = useToast();
const confirm = useConfirm();
const showAdvanced = ref(false);
const saveButton = ref(false);
const zusatzFeldName = ref(false);
const zusatzFeldAddress = ref(false);
//const { value, errorMessage } = useField('value', validateField);
const sideAdvancedShow = ref(false);
const dialogVisible = ref(false);
const delievryAddressAvailable = computed(() => {
  if (!dialogData?.extraAddress?.list) return false;
  return dialogData.extraAddress.list.find(
    (extraAddress) => extraAddress.bezeichnung === "Lieferadresse"
  );
});

const sideAdvancedToggle = () => {
  sideAdvancedShow.value = !sideAdvancedShow.value;
};

const entferneZusatzfeldName2 = () => {
  zusatzFeldName.value = false;
  dialogData.name2 = "";
};

const entferneZusatzfeldAddress2 = () => {
  zusatzFeldAddress.value = false;
  dialogData.address2 = "";
};

const resetDialogData = reactive({
  isPrivateCustomer: false,
  customerId: "",
  registered: "",
  activityReminderAfterDays : store.companySettings.lastCustomerActivityWarningDays,
  registered_us: "",
  firmenName: "",
  salutation: "",
  firstName: "",
  surName: "",
  name2: null,
  street: "",
  address2: null,
  searchTag: {
    tags: [],
  },
  houseNumber: "",
  postCode: "",
  city: "",
  emailAddress: "",
  phone: "",
  mobilePhone: "",
  notes: ref({}),
  extraAddress: {
    list: [
      {
        bezeichnung: "",
        name1: "",
        name2: "",
        street: "",
        houseNumber: "",
        address2: "",
        postCode: "",
        city: "",
      },
    ],
  },
});

let responseCustomer = reactive({});
const loadingConfirm = ref(false);

const dialogRef = inject("dialogRef");
const dialogData = dialogRef.value.data.customerData;

const closeDialog = (event) => {
  dialogRef.value.close(event);
};

const validationErrors = ref({ insurancePolicyNumber: false });

const menu = ref();
const items = ref([
  {
    label: "Options",
    items: [
      {
        label: "Lieferadresse hinzufügen",
        icon: "pi pi-truck",
        disabled: () => delievryAddressAvailable.value,
        command: () => {
          openExtraAddressDialog("Lieferadresse");
        },
      },
      {
        label: "weitere Adressen hinzufügen",
        icon: "pi pi-plus",
        command: () => {
          openExtraAddressDialog();
        },
      },
    ],
  },
]);

const toggle = (event) => {
  menu.value.toggle(event);
};

const openExtraAddressDialog = async (event, proceedType) => {
  let adressIndex = 0;
  const isLieferAdresse = ref(false);

  let isNew = true;
  console.log("🚀 ~ openExtraAddressDialog ~ event:", event);
  const newextraAddressData = reactive({
    bezeichnung: "",
    name1: "",
    name2: "",
    street: "",
    houseNumber: "",
    address1: "",
    address2: "",
    postCode: "",
    city: "",
    customerEditorField: "",
  });
  let extraAddressData = newextraAddressData;
  switch (event) {
    case "Lieferadresse":
      adressIndex = 0;
      isLieferAdresse.value = true;
      newextraAddressData.bezeichnung = "Lieferadresse";
      break;
    default:
      console.log("🚀 ~ openExtraAddressDialog ~ event:", event);

      adressIndex = parseInt(event);
      break;
  }
  if (proceedType == "edit") {
    extraAddressData = dialogData.extraAddress.list[adressIndex];
    isLieferAdresse.value = extraAddressData.bezeichnung === "Lieferadresse";
    isNew = false;
  }

  dialog.open(extraAddressDialog, {
    props: {
      modal: true,
      closable: true,
      style: {
        width: "45vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(ExtraAddressInputDialogHeader),
    },
    data: {
      extraAddressData,
      isNewExtraAddress: isNew,
      isLieferAdresse: isLieferAdresse.value,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const extraAddressUid = options.data.extraAddressUid;
        if (buttonType == "Save") {
          options.data.extraAddressData.address1 =
            options.data.extraAddressData.street +
            " " +
            options.data.extraAddressData.houseNumber;
          if (isNew) {
            if (!dialogData.extraAddress?.list)
              dialogData.extraAddress = { list: [] };
            dialogData.extraAddress.list.push(options.data.extraAddressData);
          } else {
            dialogData.extraAddress.list[adressIndex] =
              options.data.extraAddressData;
          }
        } else if (buttonType == "Delete") {
          dialogData.extraAddress.list.splice(adressIndex, 1);
        }
      }
    },
  });
};

const invalidFields = reactive({});

// Manuelle Überprüfung der Feldgültigkeit
function isInvalid(fieldName) {
  return invalidFields[fieldName] && invalidFields[fieldName].length > 0;
}

async function checkFormValidity() {
  //reset:alle elemente des Object löschen
  Object.keys(invalidFields).forEach((key) => {
    delete invalidFields[key];
  });

  try {
    CustomerValidator.validateSync(dialogData, {
      abortEarly: false,
    });
  } catch (error) {
    error.inner.forEach((fieldError) => {
      invalidFields[fieldError.path] = fieldError.message;
    });
  }
}

/* watch(
  dialogData,
  async () => {
    await checkFormValidity();
  },
  { deep: true }
); */

const saveData = async () => {
  if (store.debug)
    console.log(
      "🚀 ~ saveData ~ .......dialogData.customerUid:",
      dialogData.customerUid
    );
  let response = null;
  try {
    let url = "/company/customer-details"; ///" + dialogData.customerUid;
    if (store.debug) console.log("🚀 ~ saveData ~ dialogData:", dialogData);

    if (dialogRef.value.data.isNewCustomer) {
      console.log("new");
      url = "/company/create-customer";
      response = await iAxios.post(url, dialogData);
      dialogData.customerId = response.data.customerId;
      dialogData.customerUid = response.data.customerUid;
    } else {
      response = await iAxios.patch(url, dialogData);
    }
    console.log("🚀 ~ saveData ~ response:", response);

    toast.add({
      severity: "success",
      summary: "Speichern",
      detail: "Daten erfolgreich gespeichert.",
      life: 2000,
    });
    dialogVisible.value = false;
    closeDialog({
      buttonType: "Save",
      customerUid: dialogData.customerUid,
      customerData: CustomerDetails(dialogData.customerUid),
      preparedCustomerData: prepareCustomerData(),
    });
  } catch (error) {
    console.log(error);
  }
};

const prepareCustomerData = () => {
  const customer = ref({});
  customer.value = dialogData;
  customer.value.address1 = dialogData.street + " " + dialogData.houseNumber;
  customer.value.address2 = dialogData.address2;
  customer.value.name1 = dialogData.isPrivateCustomer
    ? dialogData.salutation +
      " " +
      dialogData.firstName +
      " " +
      dialogData.surName
    : dialogData.firmenName;
  customer.value.name2 = dialogData.name2;
  return customer;
};

const CustomerDetails = async (customerUid) => {
  console.log("🚀 ~ CustomerDetails ~ customerUid:", customerUid);
  let responseCustomer = null;
  try {
    let url =
      process.env.VUE_APP_BASE_API_URL +
      "/company/customer-details/" +
      customerUid;
    const response = await iAxios.get(url);
    const responseData = await response.data;
    responseCustomer = responseData.customer;
  } catch (error) {
    console.log(error);
  }
  return responseCustomer;
};

const saveForm = async () => {
  const errorCount = Object.keys(invalidFields).length;
  try {
    if (dialogData.hasOngoingShipping != true) {
      console.log("dialogData.hasOngoingShipping != true");
      saveData();
    } else {
      console.log("dialogData.hasOngoingShipping == true");

      if (dialogData.hasOngoingShipping == true) {
        confirm.require({
          header: "Versand überschreiben",
          message:
            "Sollen die bestehenden Sendungen für diesen Kunden, die noch nicht versendet wurden, mit den aktuellen Änderungen aktualisiert werden?",
          icon: "pi pi-question-circle",
          acceptLabel: "Ja",
          acceptIcon: "pi pi-check",
          rejectLabel: "Nein",
          rejectIcon: "pi pi-times",
          accept: () => {
            dialogData.shippingOverwrite = true;
            toast.add({
              severity: "info",
              summary: "Akzeptiert",
              detail: "Überschreiben der aktuellen Sendungen akzeptiert.",
              life: 3000,
            });
            saveData();
          },
          reject: () => {
            dialogData.shippingOverwrite = false;
            toast.add({
              severity: "error",
              summary: "Abbruch",
              detail: "Überschreiben der aktuellen Sendungen übersprungen",
              life: 3000,
            });
            saveData();
          },
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const loadCustomerHistory = async (customerUid) => {
  try {
    let url ="/company/customer-history/" +
      customerUid;
    const response = await iAxios.get(url);
    customerHistory.value = response.data.customerHistory;

    console.log(
      "🚀 ~ file: KundenEditDialog.vue:803 ~ customerHistory.value:",
      customerHistory.value
    );
  } catch (error) {
    console.log(error);
  }
};

const toggleMuteWarningActive = () => {
  if (dialogData.activityReminderAfterDays == 0) {
    dialogData.activityReminderAfterDays = store.companySettings.lastCustomerActivityWarningDays;
  } else {
    dialogData.activityReminderAfterDays = 0;
  }
};

onMounted(async () => {
  if (!dialogData?.availablePaymentMethods?.list) {
    paymentData.value = store.companySettings.paymentKinds.paymentData.map(
      (payment) => ({
        value: payment.value,
        available: payment.available,
      })
    );
    dialogData.availablePaymentMethods = { list: paymentData.value };
    console.log(
      "🚀 ~ file: KundenEditDialog.vue:940 ~ store.companySettings.paymentKinds.paymentData:",
      store.companySettings.paymentKinds.paymentData
    );

    console.log(
      "🚀 ~ file: KundenEditDialog.vue:919 ~ dialogData.availablePaymentMethods:",
      dialogData.availablePaymentMethods
    );
  }
  loadCustomerHistory(dialogData.customerUid);
  await checkFormValidity();
  window.scrollTo({
    top: 0,
    behavior: "auto", // Scrollt sofort nach oben, ohne Animation
  });
  try {
  } catch (error) {}
});
</script>
<style scoped>
.p-tabview .p-tabview-panels {
  padding: 0.25rem;
}
</style>
