export function compareJSONObjects(json1, json2,onlyTypdiffs,c1,c2) {
    const differences = [];
    const compare1=c1 || "JSON 1";
    const compare2=c2 || "JSON 2";
  
    function compareObjects(obj1, obj2, path = '') {
      for (let key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          const newPath = path ? `${path}.${key}` : key;
  
          if (!obj2.hasOwnProperty(key)) {
            differences.push(`Element ${newPath} existiert nur in ${compare1}`);
          } else if (typeof obj1[key] !== typeof obj2[key]) {
            differences.push(`Element ${newPath} hat unterschiedliche Typen: ${typeof obj1[key]} in ${compare1}, ${typeof obj2[key]} in ${compare2}`);
          } else if (typeof obj1[key] === 'object') {
            compareObjects(obj1[key], obj2[key], newPath);
          } else if (obj1[key] !== obj2[key]) {
            differences.push(`Element ${newPath} hat unterschiedliche Werte: ${obj1[key]} in ${compare1}, ${obj2[key]} in ${compare2}`);
          }
        }
      }
  
      for (let key in obj2) {
        if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
          const newPath = path ? `${path}.${key}` : key;
          differences.push(`Element ${newPath} existiert nur in ${compare2}`);
        }
      }
    }
  
    compareObjects(json1, json2);
  
    return differences;
  }