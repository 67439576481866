<template>
  <template v-if="!store.rules.activatedModules.order">
  <UpgradeLicence />
    </template>
  <template v-else>
  <div class="card p-fluid">
    <Loading v-show="loading" />
    <Tip
      v-if="hasVisibleElements"
      :tipId="orderToInvoiceTip"
      :target="generateInvoice"
      :overlay="true"
      :placement="'right'" />
    <div class="w-full flex">
      <div class="flex flex-0">
        <Button
          v-if="showGenerateOrder"
          id="generateInvoice"
          label="Rechnung generieren"
          severity="info"
          class="w-15rem"
          type="button"
          icon="pi pi-file-export"
          @click="convertToInvoice" />
      </div>
      <MultiSelect
        v-show="showAdvanced"
        :modelValue="selectedColumns"
        :options="kundenNummer ? columnsCustomer : columns"
        optionLabel="header"
        @update:modelValue="onToggle"
        display="chip"
        placeholder="Spalten Ansicht wählen"
        :class="dtClass"
        class="w-full" />
    </div>
    <DataTable
      :metaKeySelection="true"
      v-model:selection="selectedOrders"
      v-model:filters="filters"
      :value="orders"
      editMode="row"
      :class="dtClass"
      scrollable
      scrollHeight="85vh"
      :filterDisplay="showAdvanced ? 'row' : ''"
      tableStyle="min-width: 10%"
      resizableColumns
      selectionMode="multiple"
      columnResizeMode="fit"
      stripedRows
      dataKey="orderDocumentId"
      sortField="orderDocumentId"
      :sortOrder="-1"
      class="p-datatable-sm"
      @row-dblclick="openOrderDialog"
      ref="dataTableRef">
      <Column
        :headerStyle="
          showAdvanced ? 'width: 2rem; text-align: center' : 'display: none'
        "
        :bodyStyle="
          showAdvanced
            ? 'text-align: center; overflow: visible'
            : 'display: none'
        ">
        <template #header>
          <div>
            <div>
              <Button
                v-show="showAdvanced"
                type="button"
                outlined
                id="isNew"
                class="p-1"
                :class="dtClass"
                icon="pi pi-plus"
                :icon="dtClass"
                @click="handleNewRow" />
            </div>
          </div>
        </template>
        <template #body="{ data }">
          <Button
            v-show="showAdvanced"
            type="button"
            :pt="{ icon: { class: 'text-xl' } }"
            id="editCustomer"
            text
            :class="dtClass"
            icon="pi pi-file-edit"
            rounded
            @click="openOrderDialog({ data })" />
        </template>
      </Column>
      <Column
        :selectionMode="showAdvanced ? 'multiple' : ''"
        :headerStyle="showAdvanced ? 'width: 3rem' : 'display:none'"
        :bodyStyle="showAdvanced ? '' : 'display:none'"
        field="event">
        <template #body="{ data }">
          <div v-show="showAdvanced">
            <i
              v-show="data?.relationTo?.invoice"
              v-tooltip="'Rechnung erstellt'"
              class="pi pi-file-check text-2xl text-blue-300"></i>
            <Checkbox
              v-show="!data?.relationTo?.invoice"
              v-model="selectedOrders"
              :value="data" />
          </div> </template
      ></Column>
      <Column
        field="docName"
        header="Auftragsnummer"
        :footer="showAdvanced ? 'Auftragsnummer' : ''"
        key="docName"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <template v-if="data.eventAction != 'ORDER_DRAFT'">
            {{ data.docName }}
          </template>
          <template v-else>
            <span class="font-light">Entwurf</span>
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
            type="text"
            @keydown.enter="filterCallback()"
            @input="filterModel.value || filterCallback()"
            :class="dtClass"
            class="p-column-filter" />
        </template>
      </Column>
      <Column
        v-for="col of selectedColumns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="showAdvanced ? col.header : ''"
        :showFilterMenu="false"
        sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <div v-if="col.field === 'created_de' || col.field === 'updated_de'">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              :inputClass="dtClass"
              :class="dtClass"
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              :class="dtClass"
              class="p-column-filter" />
          </div>
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
      <Column
        field="documentStatus"
        header="Status"
        :footer="showAdvanced ? 'Status' : ''"
        key="documentStatus"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <Tag
            :severity="getAuftragSeverity(data.documentStatus)"
            :icon="getAuftragIcon(data.documentStatus)"
            :value="getActionLabel(data.documentStatus)"
            :class="dtClass"></Tag>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Auswählen"
            class="p-column-filter"
            :class="dtClass"
            :inputClass="dtClass"
            style="min-width: 10%"
            :showClear="true">
            <template #option="slotProps">
              <Tag
                :value="slotProps.option.label"
                :class="slotProps.option.label"
                :severity="getAuftragSeverity(slotProps.option.value)" />
            </template>
          </Dropdown>
        </template>
      </Column>

      <!--       <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Auftrag anlegen'" severity="danger" rounded @click="openAuftrag(data)" />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  reactive,
  computed,
} from "vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { OrdersService } from "@/service/OrdersService";
import { formatCurrency } from "@/utils/formatUtils";
import Loading from "@/components/Loading";
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import {
  saveAndGetPDF,
  setDocumentStatus,
  setOrderPayStatus,
} from "@/utils/orderUtils";

import { nextTick } from "vue";
import iAxios from "@/store/axiosInterface";
import { watch } from "vue";
import { userStore } from "@/store/userStore.js";
import { useToast } from "primevue/usetoast";

const store = userStore();
const OrdersEditComponent = defineAsyncComponent(() =>
  import("@/views/OrdersEditComponent.vue")
);
const OrderEditFooter = defineAsyncComponent(() =>
  import("@/views/OrdersEditFooter.vue")
);
const OrderEditHeader = defineAsyncComponent(() =>
  import("@/views/OrdersEditHeader.vue")
);
const router = useRouter();
const showGenerateOrder = ref(false);
const InvoicesEditComponent = defineAsyncComponent(() =>
  import("@/views/InvoicesEditComponent.vue")
);
const InvoiceEditFooter = defineAsyncComponent(() =>
  import("@/views/InvoicesEditFooter.vue")
);
const InvoiceEditHeader = defineAsyncComponent(() =>
  import("@/views/InvoicesEditHeader.vue")
);

const OffersEditComponent = defineAsyncComponent(() =>
  import("@/views/OffersEditComponent.vue")
);
const OffersEditFooter = defineAsyncComponent(() =>
  import("@/views/OffersEditFooter.vue")
);
const OffersEditHeader = defineAsyncComponent(() =>
  import("@/views/OffersEditHeader.vue")
);
FilterService.register("dateFilter", dateFilter);

const { dtClass, kundenNummer, showAdvanced } = defineProps({
  dtClass: {
    type: String,
    required: false,
    default: "",
  },
  kundenNummer: {
    type: String,
    required: false,
    default: "",
  },
  showAdvanced: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const columns = ref([
  { field: "kundenNummer", header: "Kundennr" },
  { field: "name1", header: "Name" },
  { field: "postCode", header: "PLZ" },
  { field: "documentValueBrutto", header: "Auftragsbetrag" },
  { field: "documentValueNetto", header: "Auftragsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumns = ref([
  { field: "name1", header: "Name" },
  { field: "documentValueBrutto", header: "Auftragsbetrag" },
]);

const columnsCustomer = ref([
  { field: "documentValueBrutto", header: "Auftragsbetrag" },
  { field: "documentValueNetto", header: "Auftragsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumnsCustomer = ref([
  { field: "created_de", header: "Erstelldatum" },
  { field: "documentValueBrutto", header: "Auftragsbetrag" },
]);

const selectedColumns = kundenNummer
  ? ref(startColumnsCustomer.value)
  : ref(startColumns.value);
const onToggle = (val) => {
  const cols = kundenNummer ? columnsCustomer : columns;
  const valFields = val.map((v) => v.field);
  cols.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};
const selectedOrders = ref();

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}

const filters = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  kundenNummer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bezahlArten: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

function isSortable(field) {
  return field === "updated" || field === "created";
}
const isMobile = ref(false);
const loading = ref(true);

const orders = ref([]);
const dialog = useDialog();
let rowData = null;
const dataTableRef = ref();
const popUpLabel = reactive({ label: "" });

const hasVisibleElements = computed(() => {
  if (!dataTableRef.value || !dataTableRef.value.children) {
    return false;
  }

  // Überprüfen Sie, ob es sichtbare Kinder gibt
  for (let i = 0; i < dataTableRef.value.children.length; i++) {
    if (
      window.getComputedStyle(dataTableRef.value.children[i]).display !== "none"
    ) {
      return true;
    }
  }

  return false;
});

const statuses = reactive([
  { label: "Entwurf", value: "ORDER_DRAFT_OPEN" },
  { label: "Storniert", value: "ORDER_CANCELED" },
  { label: "Gutschrift", value: "ORDER_CORRECTION" },
  { label: "Offen", value: "ORDER_OPEN" },
  { label: "Bezahlt", value: "ORDER_PAID" },
  { label: "Teilzahlung", value: "ORDER_PARTIAL" },
]);

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = "Auftrag " + rowData.docName;
  menu.value.toggle(event);
};

const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Offen",
        icon: "pi pi-money-bill",
        command: () => {
          if (rowData && rowData.documentStatus != "ORDER_OPEN") {
            setDocumentStatus(rowData.orderDocumentId, "ORDER_OPEN");
            rowData.documentStatus = "ORDER_OPEN";
          }
        },
      },
      {
        label: "Bezahlt",
        icon: "pi pi-dollar",
        command: () => {
          if (rowData && rowData.documentStatus != "ORDER_PAID") {
            setDocumentStatus(rowData.orderDocumentId, "ORDER_PAID");
            rowData.documentStatus = "ORDER_PAID";
          }
        },
      },
    ],
  },
]);

const pdfFile = ref();
const handleNewRow = async (event) => {
  const dialogRef = dialog.open(OrdersEditComponent, {
    props: {
      header: "Auftrag erstellen",
      style: {
        width: "35vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: {
      order: {
        orderDocumentId: null,
        readOnly: false,
        storno: false,
        isNew: true,
      },
      auftragsDaten: event.data,
    },
    templates: {
      footer: markRaw(OrderEditFooter),
      header: markRaw(OrderEditHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let responseFile = await saveAndGetPDF(dialogRef);
          pdfFile.value = dialogRef.data.pdfFile;
        } else if (buttonType == "Generate") {
          let response = speichern(dialogRef, true);
        } else if (buttonType == "Delete") {
          //von virtalOrders löschen mit orderDocumentId
          refreshReRenderTable();
          /*           const index = virtualOrders.value.findIndex((order) => order.orderDocumentId == options.data.orderDocumentId);
                    if (index > -1) {
                      virtualOrders.value.splice(index, 1);
                    }
                    //von orders löschen mit orderDocumentId
                    const index2 = orders.value.findIndex((order) => order.orderDocumentId == options.data.orderDocumentId);
                    if (index2 > -1) {
                      orders.value.splice(index2, 1);
                    } */
        } else if (buttonType == "GoToOrder") {
          console.log(
            "🚀 ~ file: OrdersView.vue:326 ~ options.data:",
            options.data
          );

          openOrder(options.data.orderDocumentId);
        } else refreshReRenderTable();
      }
    },
  });
};

const refreshReRenderTable = async () => {
  await werteLaden();
  //    dataTableRef.value.filter();
};

const openOrderDialog = async (event, proceedType) => {
  console.log("dataTableRef", dataTableRef.value);
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "ORDER_DRAFT" || isCloned;
  const isCancelledDone = event.data?.relationFrom?.orderCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;

  //event.data.responsed.orderDocumentId = event.data.orderDocumentId || event.data.orderDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OrdersEditComponent, {
    props: {
      header: isCancelled ? "Auftragskorrektur " : "Auftrag",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    templates: {
      footer: markRaw(OrderEditFooter),
      header: markRaw(OrderEditHeader),
    },
    data: {
      order: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        orderDocumentId: event.data.orderDocumentId
          ? event.data.orderDocumentId
          : event.data.orderDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.orderDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      auftragsDaten: event.data,
    },
    onClose: async (options) => {
      showGenerateOrder.value = false;
      console.log("🚀 ~ file: OrdersView.vue:414 ~ options:", options);

      const buttonType = options?.data?.buttonType;
      if (options.data) {
        if (buttonType == "Save" && isDraft) {
          refreshReRenderTable();
        } else if (buttonType == "Delete") {
          refreshReRenderTable();
        } else if (buttonType == "Cancellation") {
          cloneOrder(event, "Cancellation", dialogRef);
        } else if (buttonType == "orderToOffer") {
          convertToOffer(event);
        } else if (buttonType == "Clone") {
          cloneOrder(event, "Clone", dialogRef);
        } else if (buttonType == "GoToOrder") {
          openOrder(options.data.orderDocumentId);
        } else if (buttonType == "GoToOffer") {
          openOffer(options.data.offerDocumentId);
        } else if (buttonType == "GoToInvoice") {
          openInvoice(options.data.invoiceDocumentId, options.data.isDraft);
        } else refreshReRenderTable();
      }
    },
  });
};

const openOrder = async (orderDocumentId) => {
  console.log(
    "🚀 ~ file: OrdersView.vue:461 ~ orderDocumentId:",
    orderDocumentId
  );

  await werteLaden();
  orders.value.forEach((order) => {
    if (order.orderDocumentId == orderDocumentId) {
      openOrderDialog({ data: order });
    }
  });
};

const openOffer = (offerDocumentId) => {
  //mit iAxios von /company/the-offer/<offerDocumentId> die Daten holen und dann mit openOfferDialog öffnen
  iAxios.get("/company/the-offer/" + offerDocumentId).then((response) => {
    if (response.data) {
      openOfferDialog({ data: response.data });
    }
  });
};

const cloneOrder = async (event, proceedType, dialogRef) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const auftragsDatenCopy = copyEvent.data;
  let docNameFrom = auftragsDatenCopy.docName;
  let orderDocumentIdFrom = auftragsDatenCopy.orderDocumentId;
  event.data.docContent.auftragsDatum = new Date().toISOString().slice(0, 10);
  event.data.docName = null;
  event.data.orderDocumentId = null;
  event.data.orderDocumentId = null;
  event.data.storno = true;
  ///TODO open paid status setzen
  switch (proceedType) {
    case "Cancellation":
      {
        event.data.docContent.documentTitel = "Auftragskorrektur/ Gutschrift";
        event.data.documentStatus = "ORDER_CANCELED";
        event.data.docContent.documentIntroduction =
          " Sie erhalten anbei die Auftragskorrektur/ Gutschrift zur Auftrag " +
          docNameFrom;
        event.data.relationFrom = {
          orderCancelledFromId: orderDocumentIdFrom,
          orderCancelledDocName: docNameFrom,
        };
        const itemList = event.data.itemList;
        itemList.forEach((item) => {
          item.quantity = item.quantity * -1;
        });
      }
      break;
    case "Clone":
      {
        event.data.docContent.documentTitel = "Auftrag";
        event.data.documentStatus = "ORDER_OPEN";
        event.data.docContent.documentIntroduction =
          " Sie erhalten anbei unsere Auftrag.";
        event.data.relationFrom = {
          duplicatedFromId: orderDocumentIdFrom,
          duplicatedFromDocName: docNameFrom,
        };
        event.data.isNew = true;
      }
      break;
  }
  openOrderDialog(event, proceedType);
};

const openOfferDialog = async (event, proceedType) => {
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "ORDER_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.offerCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;
  const isOrderToOffer = proceedType == "fromOrder";
  //event.data.responsed.offerDocumentId = event.data.offerDocumentId || event.data.offerDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OffersEditComponent, {
    props: {
      header: isCancelled ? "Angebotskorrektur " : "Angebot",
      subHeader: isOrderToOffer
        ? "(Entwurf erstellt von Auftrag " + event.data.docName + ")"
        : isDraft
        ? "(Entwurf)"
        : event.data.docName,
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OffersEditFooter),
      header: markRaw(OffersEditHeader),
    },
    data: {
      offer: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        offerDocumentId: event.data.offerDocumentId
          ? event.data.offerDocumentId
          : event.data.offerDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: false,
        storno: isCancelled,
      },
      angebotsDaten: event.data,
    },
    onClose: async (options) => {
      console.log(
        "🚀 ~ file: OffersView.vue:387 ~ onClose: ~ options:",
        options
      );

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          //von virtalOffers löschen mit offerDocumentId
          refreshReRenderTable();
          /*           const index = virtualOffers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index > -1) {
                      virtualOffers.value.splice(index, 1);
                    }
                    //von offers löschen mit offerDocumentId
                    const index2 = offers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index2 > -1) {
                      offers.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "offerToOrder") {
        console.log("offerToOrder");
        convertToOrder(event);
      } else if (buttonType == "Clone") {
        cloneOffer(event, "Clone", dialogRef);
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const getAuftragIcon = (statusAuftrag) => {
  switch (statusAuftrag) {
    case "ORDER_OPEN":
      return "pi pi-money-bill";
    case "ORDER_PAID":
      return "pi pi-star-fill";
    case "ORDER_PARTIAL":
      return "pi pi-star-half-fill";
    case "ORDER_CANCELED":
      return "pi pi-times";
    case "ORDER_CORRECTION":
      return "pi pi-file-edit";
  }
  return "pi pi-file-edit";
};

const getAuftragSeverity = (statusAuftrag) => {
  switch (statusAuftrag) {
    case "ORDER_OPEN":
      return "danger";

    case "ORDER_PAID":
      return "success";

    case "ORDER_PARTIAL":
      return "warning";

    case "ORDER_CANCELED":
      return "contrast";

    case "ORDER_CORRECTION":
      return "info";
  }
  return "secondary";
};

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const getBezahltArten = (invoice) => {
  const paymentMethods = invoice?.payProgress?.paymentInput;
  if (!paymentMethods) return "";
  //paymentMethod, wird immer jeweils 1x von jeder Zahlungsart in einem String gespeichert, bspw. Rückgabe "Überweisung, Bar" für 4 Einträge paymentMethod="Überweisung, Bar, Überweisung, Bar", es wird nur jeweils das vorkommen der Zahlungsart 1x übernommen
  const uniquePaymentMethods = paymentMethods
    .map((bezahltArt) => bezahltArt.paymentMethod)
    .filter((value, index, self) => self.indexOf(value) === index);
  return uniquePaymentMethods.join(", ");
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
}

async function werteLaden() {
  loading.value = true;
  let allOrders = null;
  await OrdersService.getOrders().then((data) => {
    if (data.orders) {
      allOrders = data.orders.map((order) => ({
        ...order,
        name1: order.receiver.name1,
        postCode: order.receiver.plz,
        documentValueBrutto: formatCurrency(
          order.docContent.documentValueBrutto
        ),
        documentValueNetto: formatCurrency(order.docContent.documentValueNetto),
        bezahlArten: getBezahltArten(order),
      }));
    }
  });
  if (kundenNummer && allOrders) {
    orders.value = await allOrders.filter(
      (d) => d.kundenNummer === kundenNummer
    );
  } else orders.value = allOrders;
  loading.value = false;
}
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});

const convertToOffer = async (event) => {
  const copyEvent = JSON.parse(JSON.stringify(event));
  const auftragsDatenCopy = copyEvent.data;

  let docName = auftragsDatenCopy.docName;
  let orderDocumentId = auftragsDatenCopy.orderDocumentId;
  let offerModel = auftragsDatenCopy;

  offerModel.docContent.angebotsDatum = new Date().toISOString().slice(0, 10);
  offerModel.relationFrom = {
    generatedFromOrderId: orderDocumentId,
    generatedFromOrderDocName: docName,
  };
  offerModel.docContent.offerTitel = "Angebot";
  offerModel.salutationText = store.companySettings.salutationText;
  offerModel.docContent.offerIntroduction =
    store.companySettings.offerIntroduction;
  offerModel.orderFinalText = store.companySettings.orderFinalText;
  offerModel.documentStatus = "OFFER_DRAFT_OPEN";
  offerModel.eventAction = "OFFER_DRAFT";
  offerModel.offerDocumentId = null;
  offerModel.docContent.subHeader = null;
  offerModel.isNew = true;

  console.log("🚀 ~ file: OffersView.vue:514 ~ offerModel:", offerModel);

  openOfferDialog({ data: offerModel }, "fromOrder");
};

const convertToInvoice = async () => {
  /* von den ausgewählten Aufträgen selectedOrders, wird der docName,documentId, docContent.auftragsDatum in ein Array gespeichert, der paidStatus wird inder Variable paidStatus als ORDER_PAID,
   wenn alle Aufträge bezahlt sind, sonst ORDER_OPEN gespeichert, die itemList wird von allen ausgewählen Aufträgen in ein Array zusammengeführt gespeichert und in der Variable itemList gespeichert.
    */
  let orderDocs = [];
  let docName = [];
  let auftragsDatum = [];
  let paidStatus = "INVOICE_PAID";
  let itemList = [];
  let payProgress = [];
  let invoiceModel = JSON.parse(JSON.stringify(selectedOrders.value[0]));
  let openPaidValue = 0.0;
  let openPaidValueBrutto = 0.0;
  const ordersForConvert = selectedOrders.value;

  ordersForConvert.forEach((order) => {
    if (store.debug) console.log("order:", order);
    orderDocs.push({
      orderDocName: order.docName,
      orderDocId: order.orderDocumentId,
    });
    docName.push(order.docName);
    auftragsDatum.push(order.docContent.auftragsDatum);
    if (order.paidStatus == "INVOICE_OPEN") {
      openPaidValue += order.docContent.documentValueNetto;
      paidStatus = "INVOICE_OPEN";
    }
    itemList = itemList.concat(order.itemList);
    payProgress = payProgress.concat(order.payProgress);
  });
  ordersForConvert.forEach((orderToInvoice) => {
    orderToInvoice.isFromOrder = true;
  });

  //invoiceModel.docNameList = docName;
  //invoiceModel.documentIdList = documentId;
  invoiceModel.itemList = itemList;
  invoiceModel.invoiceFooterNotes = null;
  invoiceModel.createShipping = false; //Kein Lieferschein erstellen, da bei Auftrag schon erstellt wurde
  invoiceModel.docContent.rechnungsDatum = new Date()
    .toISOString()
    .slice(0, 10);
  invoiceModel.paidStatus = paidStatus; //falls eine Rechnung noch etwas offen ist, wird paidStatus auf offen gesetzt
  invoiceModel.docContent.invoiceTitel = "Rechnung";
  invoiceModel.docContent.openPaidValue = openPaidValue; //falls eine Rechnung noch etwas offen ist, wird openPaidValue auf den offenen Betrag gesetzt
  invoiceModel.relationFrom = { generatedFromOrder: orderDocs };
  invoiceModel.docContent.invoiceIntroduction =
    "Sie erhalten anbei unsere Rechnung " +
    (ordersForConvert.length === 1
      ? "für den Auftrag " + docName
      : "zu den Aufträgen " + docName.join(", "));
  console.log(
    "🚀 ~ file: OrdersView.vue:621 ~ generateOrder ~ invoiceModel.docContent.documentIntroduction:",
    invoiceModel.docContent.documentIntroduction
  );

  const addEndText =
    "Vielen Dank für Ihren Auftrag. Bei Fragen und Wünschen stehen wir Ihnen gerne zur Verfügung.";
  if (paidStatus == "INVOICE_OPEN")
    invoiceModel.docContent.invoiceFinalText =
      "offener Gesamtbetrag: " +
      formatCurrency(openPaidValueBrutto) +
      " EUR. " +
      addEndText;
  else invoiceModel.docContent.documentFinalText = addEndText;
  invoiceModel.invoiceDocumentId = null;
  invoiceModel.docName = null;

  console.log("🚀 ~ file: OrdersView.vue:734 ~ invoiceModel:", invoiceModel);

  openInvoiceDialog({ data: invoiceModel });

  if (store.debug) console.log("ordersForConvert:", ordersForConvert);
};

const openInvoiceDialog = async (event, isReadOnly) => {
  console.log(
    "🚀 ~ file: OrdersView.vue:741 ~ openReadOnlyInvoice ~ event:",
    event
  );

  const isDraft = true;

  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: "Rechnung",
      subHeader: event.data?.docNameList
        ? "(Entwurf erstellt von Auftrag " +
          event.data.docNameList.join(", ") +
          ")"
        : event.data?.docName
        ? event.data.docName
        : "",
      style: {
        width: "",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(InvoiceEditFooter),
      header: markRaw(InvoiceEditHeader),
    },
    data: {
      invoice: {
        isLoading: loading.value,
        isDraft: isDraft,
        invoiceDocumentId: null,
        docName: null,
        readOnly: isReadOnly ?? false,
      },
      rechnungsDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data) {
        if (buttonType == "Save" && isDraft) {
          refreshReRenderTable();
        } else if (buttonType == "Delete") {
          //von virtalInvoices löschen mit invoiceDocumentId
          refreshReRenderTable();

          /*           const index = virtualInvoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index > -1) {
                      virtualInvoices.value.splice(index, 1);
                    }
                    //von invoices löschen mit invoiceDocumentId
                    const index2 = invoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index2 > -1) {
                      invoices.value.splice(index2, 1);
                    } */
        }
        //refreshReRenderTable();
        else if (buttonType == "Cancellation") {
          cloneInvoice(event, "Cancellation", dialogRef);
        } else if (buttonType == "Clone") {
          cloneInvoice(event, "Clone", dialogRef);
        } else if (buttonType == "GoToInvoice") {
          openInvoice(options.data.invoiceDocumentId, options.data.isDraft);
        } else if (buttonType == "GoToOrder") {
          openOrder(options.data.orderDocumentId);
        }
      }
    },
  });
};

const openInvoice = (invoiceDocumentId, isDraft) => {
  console.log("openinvoice");
  let url = "/company/the-invoice/" + invoiceDocumentId;
  if (isDraft) url = "/company/the-invoice-draft/" + invoiceDocumentId;
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: OrdersView.vue:1012 ~ response:", response);

    if (response.data) {
      openInvoiceDialog(
        { data: response.data },
        response.data.eventAction == "INVOICE"
      );
    }
  });
};

watch(selectedOrders, () => {
  //wurde nur 1 Auftrag ausgewählt(selectedOrder.value.length==0) dann wird der Button über showGenerateOrder auf true gesetzt, wenn aber mehr als einer ausgewöhlt wurde
  // wird geprüft ob alle customerUid der Einträge identisch sind und ob die ausgewählten Einträge dessen eventAction==ORDER ist, und wenn die es sind wird showGenerateOrder.value = true sonsts auf false gesetzt.
  //Todo: nach Änderung von Faruk von ORDER auf ORDER muss das hier auch geändert werden
  try {
    if (
      selectedOrders.value.length == 1 &&
      selectedOrders.value[0].eventAction == "ORDER"
    ) {
      showGenerateOrder.value = true;
      return;
    } else if (selectedOrders.value.length > 1) {
      let customerUid = selectedOrders.value[0].customerId;
      let isSameCustomer = true;
      selectedOrders.value.forEach((order) => {
        if (order.customerId != customerUid || order.eventAction != "ORDER") {
          isSameCustomer = false;
        }
      });
      showGenerateOrder.value = isSameCustomer;
      return;
    }
    showGenerateOrder.value = false;
  } catch (error) {
    console.log(error);
  }
});

function closePreview() {
  pdfFile.value = null;
}
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
