import iAxios from "@/store/axiosInterface";
export const ShippingService = {
  async getData() {
    const pat = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/shipping-list");
    console.log(pat);
    const data = await pat.data.shippingList;
    return data;
  },

  getShippingListSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  async getShippingList() {
    return Promise.resolve(this.getData());
  },

  setShippingStatus(shipping_id, shippingStatus) {
    iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/deli-confirm/" + shipping_id + "/" + shippingStatus, null, 'post');
  },

  async getFilterAndGroupShippingsByTimePeriod(startDate, endDate) {
    try{
    const pat = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/shipping-list");
    const data = await pat.data.shippingList;

    // Wenn kein Zeitraum angegeben ist, dann gesammte Daten
    if (!startDate || !endDate) {
      return data;
    }

    const filteredData = data.filter((shipping) => {
      const eventTime = new Date(shipping.eventTime);
      return eventTime >= startDate && eventTime <= endDate;
    });

    return filteredData.reduce((result, shipping) => {
      const deliverStatus = shipping.deliverStatus;
      const existingGroup = result.find(group => group.deliverStatus === deliverStatus);

      if (existingGroup) {
        existingGroup.shippings.push(shipping);
        existingGroup.shippingCount++;
      } else {
        result.push({
          deliverStatus: deliverStatus,
          shippings: [shipping],
          shippingCount: 1,
        });
      }

      return Promise.resolve(result);
    }, []);
  }catch(err){};
},
}
