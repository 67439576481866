import iAxios from "@/store/axiosInterface";
export const getItems = (data) => {
  return data;
};

export const ItemsService = {
  async getData() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/item");
    const data = await response.data;
    return Promise.resolve(data);
  },

  async getItem(id) {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/the-item/" + id);
    const data = await response.data;
    return Promise.resolve(data);
  },

  async getItemHistoryCustomer(kundenNummer) {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/item-history-customer/" + kundenNummer);
    const data = await response.data;
    return Promise.resolve(data);
  },

  getItemsSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getItemsMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async getItemsXLarge() {
    return Promise.resolve(this.getData());
  },

};
