<template>
  <div class="w-full">
    <span class="p-input-icon-left p-float-label w-full text-color-secondary">
      <i
        v-if="selectedCustomer?.name1 == null"
        class="pi pi-users z-1 text-color-secondary"></i>
      <i
        v-if="selectedCustomer?.name1 && selectedCustomer.isPrivateCustomer"
        class="material-symbols-outlined text-lg text-teal-400 z-1">
        face
      </i>
      <i
        v-if="selectedCustomer?.name1 && !selectedCustomer.isPrivateCustomer"
        class="pi pi-building text-blue-400 z-1"></i>
      <AutoComplete
        inputId="Kundenauswahl"
        v-model="selectedCustomer"
        optionLabel="name1"
        :suggestions="filteredCustomers"
        dataKey="customerId"
        inputClass="w-full pl-6 pr-5"
        completeOnFocus
        class="w-4"
        @complete="search"
        @item-select="selectCustomer"
        ref="autocompleteRef"
        @blur="autoSelectRollBack">
        <template v-if="customers" #option="slotProps">
          <div class="align-options-center">
            <span class="flex">
              <span v-if="slotProps.option.isPrivateCustomer">
                <span class="material-symbols-outlined text-lg text-teal-400">
                  face </span
                >&nbsp;</span
              >
              <span v-else>
                <i class="pi pi-building text-blue-400"></i>&nbsp;
              </span>
              <div
                v-html="
                  highlightMatch(slotProps.option.name1, selectedCustomer)
                "></div>
            </span>
            <small
              v-html="
                highlightMatch(slotProps.option.address1, selectedCustomer)
              "></small>
          </div>
        </template>
        <template #header>
          <Button
            class="underline text-color-secondary w-full h-8 font-light text-left border-noround line-height-3"
            text
            :label="neuErstellenText"
            @click="openKundenEditDialog" />
        </template>
      </AutoComplete>
      <small
        v-if="openPaymentsCheck && loadingPaymentsCheck && selectedCustomer"
        class="text-orange-300">
        Prüfe Offene Positionen .. <i class="pi pi-spin pi-spinner"></i>
      </small>
      <label for="Kundenauswahl" class="pl-3">{{ auswahlText }}</label>
      <Button
        v-if="selectedCustomer"
        @click="clearInput"
        icon="pi pi-times text-2xl"
        class="p-button-rounded p-button-text p-button-sm p-button-icon-only p-button-secondary" />
    </span>
    <Message v-if="openToPay > 0" severity="warn" :closable="false">
      <div class="flex justify-between">
        <div>Offene Zahlungen: {{ formatCurrency(openToPay) }}</div>
        <Button
          label=" Details anzeigen"
          class="pl-3 text-xs p-0 m-0"
          text
          @click="openPayments()" />
      </div>
    </Message>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  computed,
  defineAsyncComponent,
  reactive,
  markRaw,
} from "vue";
import { CustomerService } from "@/service/CustomerService.js";
import { userStore } from "@/store/userStore";
import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { formatCurrency } from "@/utils/formatUtils";

const dialog = useDialog();
const loadingPaymentsCheck = ref(false);
const { auswahlText, neuErstellenText, contactType, openPaymentsCheck } =
  defineProps({
    auswahlText: {
      type: String,
      default: "Kunde auswählen",
    },
    neuErstellenText: {
      type: String,
      default: "Neuen Kunden erstellen",
    },
    contactType: {
      type: String,
      default: null, //CONTACT CUSTOMER SUPPLIER
    },
    openPaymentsCheck: {
      type: Boolean,
      default: true,
    },
  });

const emit = defineEmits(["clearInput", "inputSelected"]);

const KundenEditDialog = defineAsyncComponent(() =>
  import("@/views/KundenEditDialog.vue")
);
const KundenEditDialogHeader = defineAsyncComponent(() =>
  import("@/views/KundenEditDialogHeader.vue")
);
const OpenPaymentsDialog = defineAsyncComponent(() =>
  import("@/views/OpenPaymentsDialog.vue")
);
const autocompleteRef = ref(null);

const filteredCustomers = ref();
const store = userStore();
const selectedCustomer = ref(null);

const customers = ref();

const autoSelectRollBack = () => {
  if (selectedCustomer?.value?.customerId == null) {
    openToPay.value = 0;
    emit("clearInput", null);
  }
};

const search = (event) => {
  /*   if (!customers?.value) 
    {
      console.log("🚀 ~ search ~ customers?.value", customers?.value)
      loading.value = false;
    return; }  */
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredCustomers.value = [...customers.value];
    } else {
      filteredCustomers.value = customers.value.filter((customer) => {
        return (
          customer?.name1?.toLowerCase().includes(event.query?.toLowerCase()) ||
          customer?.address1?.toLowerCase().includes(event.query?.toLowerCase())
        );
      });
    }
  }, 10);
};
const clearInput = () => {
  openToPay.value = 0;
  selectedCustomer.value = null;
  emit("clearInput", null);
};

const loading = ref(true);

const highlightMatch = (text, keyword) => {
  if (typeof keyword !== "string") {
    return text;
  }
  try {
    const regex = new RegExp(`(${keyword})`, "gi");
    // Verwenden von replace, um das Keyword durch markierten Text zu ersetzen
    return text.replace(
      regex,
      (_, match) => `<span class="highlightText">${match}</span>`
    );
  } catch (error) {
    return text;
  }
};

const customerData = ref();
const openKundenEditDialog = async (event) => {
  const newCustomerData = reactive({
    isPrivateCustomer: false,
    customerId: "",
    created_de: "",
    created_us: "",
    updated_de: "",
    updated_us: "",
    address1: "",
    address2: "",
    name1: "",
    name2: "",
    firmenName: "",
    salutation: "",
    firstName: "",
    surName: "",
    street: "",
    houseNumber: "",
    postCode: "",
    city: "",
    emailAddress: "",
    phone: "",
    mobilePhone: "",
    linkedToCustomerId: null,
    defaultVk: "VK-1",
  });
  if (contactType) {
    newCustomerData.contactType = contactType;
  }
  let customerData = newCustomerData;

  dialog.open(KundenEditDialog, {
    props: {
      header: "Hinzufügen",
      modal: true,
      closable: false,
      style: {
        width: "45vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(KundenEditDialogHeader),
    },
    data: {
      customerData,
      isNewCustomer: true,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const customerUid = options.data.customerUid;
        if (buttonType == "Save") {
          refreshAndSelect(options.data.preparedCustomerData);
        } else if (buttonType == "Cancel") {
        }
      }
    },
  });
};

const refreshAndSelect = async (newCustomer) => {
  await werteLaden();
  selectedCustomer.value = await customers.value[customers.value.length - 1];
  emit("inputSelected", newCustomer);
};

const werteLaden = async () => {
  await CustomerService.getCustomerXLarge().then((data) => {
    if (contactType) {
      console.log(
        "🚀 ~ file: CustomerInputSearch.vue:220 ~ contactType:",
        contactType
      );

      // Filtern der Kundenliste basierend auf dem contactType
      customers.value = data.filter(
        (customer) => customer.contactType === contactType
      );
    } else {
      customers.value = data;
    }
    loading.value = false;
  });
  if (!customers.value) {
    loading.value = false;
    customers.value = [];
    return;
  }
  customers.value.sort((a, b) => a.customerId - b.customerId);
};

onMounted(() => {
  werteLaden();
});

// alle order Payprogresses aus dem customerHistory ausser den mit relationTo.invoice
const concatPayInputs = () => {
  const customerPayprogress =
    selectedCustomer?.extended?.payProgress?.paymentInput || [];

  const allInvoicePaymentInputs =
    customerHistory?.value?.invoices?.flatMap((invoice) =>
      invoice.payProgress?.paymentInput ? invoice.payProgress.paymentInput : []
    ) || [];
  const allOrderPaymentInputs =
    customerHistory?.value?.orders
      ?.filter((order) => !order.relationTo?.invoice)
      .flatMap((order) =>
        order.payProgress?.paymentInput ? order.payProgress.paymentInput : []
      ) || [];

  const allCustomerPaymentInputs = [...customerPayprogress];

  let allPaymentInputs = [
    ...allInvoicePaymentInputs,
    ...allOrderPaymentInputs,
    ...allCustomerPaymentInputs,
  ];
  return allPaymentInputs;
};

const allPayInputs = computed(() => {
  const all = concatPayInputs();
  return all;
});

const totalPaid = computed(() => {
  let totalPaid = 0.0;
  if (!allPayInputs.value) return totalPaid;
  allPayInputs.value.forEach((payment) => {
    if (payment.event != "PAYMENT_DELETED") totalPaid += payment.paymentAmount;
  });
  return totalPaid;
});

//zusammenführen von order(ausser den mit relationTo.invoice) und invoice documents
const concatAllDocuments = () => {
  const allInvoices = customerHistory?.value?.invoices || [];
  const allCreditNotes = customerHistory?.value?.creditnotes || [];

  console.log("🚀 ~ file: CustomerInputSearch.vue:333 ~ customerHistory:", customerHistory)

  const allOrders =
    customerHistory?.value?.orders?.filter(
      (order) => !order.relationTo?.invoice
    ) || [];
  let allDocuments = [...allInvoices, ...allOrders, ...allCreditNotes];
  console.log(
    "🚀 ~ file: CustomerPaymentList.vue:363 ~ allDocuments:",
    allDocuments
  );
  return allDocuments;
};

//zusammenrechnen der totalAmounts von allen documents
const totalAmount = computed(() => {
  let totalAmount = 0;
  const allDocs = concatAllDocuments();
  if (!allDocs) return 0;

  allDocs.forEach((doc) => {
    totalAmount += doc.docContent.documentValueBrutto;
  });
  return totalAmount;
});

const customerHistory = ref(null);
const openToPay = ref(0);

const selectCustomer = async (kunde) => {
  loadingPaymentsCheck.value = true;
  let url = "/company/customer-details/" + kunde.value.customerUid;
  const response = await iAxios.get(url);
  kunde.value.emailAddress = response.data.customer.emailAddress;
  kunde.value.city = response.data.customer.city;
  kunde.value.street = response.data.customer.street;
  kunde.value.houseNumber = response.data.customer.houseNumber;
  kunde.value.address1 =
    response.data.customer.street + " " + response.data.customer.houseNumber;
  kunde.value.vkStufen = response.data.customer.defaultVk;
  if (!openPaymentsCheck) {
    emit("inputSelected", kunde);
    return;
  } else {
    const payments = checkOpenPayments();
    if (!payments) {
      console.log("offene Rechnungen vorhanden", payments);
    }
    emit("inputSelected", kunde);
  }
};

const checkOpenPayments = async () => {
  await loadCustomerHistory(selectedCustomer.value.customerUid);
  openToPay.value = totalAmount.value - totalPaid.value;

  console.log(
    "🚀 ~ file: CustomerInputSearch.vue:301 ~ totalPaid.value:",
    totalPaid.value
  );
  console.log(
    "🚀 ~ file: CustomerInputSearch.vue:302 ~ totalAmount.value:",
    totalAmount.value
  );
  console.log(
    "🚀 ~ file: CustomerInputSearch.vue:303 ~ diff:",
    openToPay.value
  );

  loadingPaymentsCheck.value = false;

  if (openToPay.value > 0) return openToPay.value;
  else return null;
};

//laden der customerHistory
const loadCustomerHistory = async (customerUid) => {
  try {
    let url = "/company/customer-history/" + customerUid;
    const response = await iAxios.get(url);
    customerHistory.value = response.data.customerHistory;

    console.log(
      "🚀 ~ file: KundenEditDialog.vue:803 ~ customerHistory.value:",
      customerHistory.value
    );
  } catch (error) {
    console.log(error);
  }
};

const groupByDocId = () => {
  let payInputs = concatPayInputs();
  const docs = concatAllDocuments();

  console.log("🚀 ~ file: CustomerInputSearch.vue:426 ~ docs:", docs);

  let groupedDocsPaymentsArray = [];
  let groupedDocsPayments = payInputs.reduce((acc, payInput) => {
    if (payInput.event != "PAYMENT_DELETED") {
      if (!acc[payInput.docId]) {
        acc[payInput.docId] = {
          docId: payInput.docId,
          docName: payInput.docName,
          paymentAmount: 0,
        };
      }
      // Runden der Zahlungsbeträge auf zwei Dezimalstellen
      acc[payInput.docId].paymentAmount =
        Math.round(
          (acc[payInput.docId].paymentAmount + (payInput.paymentAmount || 0)) * 100
        ) / 100;
    }
    return acc;
  }, {});

  console.log("🚀 ~ file: CustomerInputSearch.vue:446 ~ groupedDocsPayments:", groupedDocsPayments)

  for (let docId in groupedDocsPayments) {
    const doc = docs.find((doc) => {
      const ddocId = doc.creditNoteDocumentId??doc.invoiceDocumentId
        ? doc.invoiceDocumentId
        : doc.orderDocumentId;
      return docId == ddocId;
    });

    if (doc) {
      // Runden der offenen Beträge auf zwei Dezimalstellen
      groupedDocsPayments[docId].openAmount =
        Math.round(
          (doc.documentValueBrutto - groupedDocsPayments[docId].paymentAmount) * 100
        ) / 100;
      groupedDocsPaymentsArray.push(groupedDocsPayments[docId]);
    } else {
      console.warn(`Dokument mit docId ${docId} nicht gefunden.`);
    }
  }
  return groupedDocsPaymentsArray;
};

const openPayments = async (event) => {
  const groupedDocsPaymentsArray = groupByDocId();

  console.log("🚀 ~ file: CustomerInputSearch.vue:467 ~ groupedDocsPaymentsArray:", groupedDocsPaymentsArray)

  const openDocs = concatAllDocuments()
    .map((doc) => {
      const ddocId = doc.invoiceDocumentId
        ? doc.invoiceDocumentId
        : doc.orderDocumentId;
      console.log("🚀 ~ file: CustomerInputSearch.vue:470 ~ ddocId:", ddocId);

      const paymentInfo = groupedDocsPaymentsArray.find(
        (pay) => pay.docId == ddocId
      );

      if (!paymentInfo) {
        return { ...doc, openAmount: doc.documentValueBrutto};
      }

      console.log("🚀 ~ file: CustomerInputSearch.vue:477 ~ paymentInfo:", paymentInfo)

      if (paymentInfo && paymentInfo.openAmount > 0) {
        console.log("🚀 ~ file: CustomerInputSearch.vue:480 ~ doc:", doc)

        return { ...doc, openAmount: paymentInfo.openAmount };
      }
      return null;
    })
    .filter((doc) => doc !== null);
    
  console.log("🚀 ~ file: CustomerInputSearch.vue:496 ~ openDocs:", openDocs);

  dialog.open(OpenPaymentsDialog, {
    props: {
      header: "Offen Posten",
      modal: true,
      closable: true,
      style: {
        width: "45vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      // header: markRaw(KundenEditDialogHeader),
    },
    data: {
      openDocs: openDocs,
    },
  });
};
</script>
<style scoped></style>
