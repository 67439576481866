import iAxios from "@/store/axiosInterface";
import { reactive } from "vue";

export const ParcelServicesService = {
  async getData() {
    const pat = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/settings");
    
    return pat.data.parcelConfig;
  },

  getParcelServicesSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  async getParcelServicesXLarge() {
    return Promise.resolve(this.getData());
  },

};
