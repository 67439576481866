<template>
  <SupportWidget/>
  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
  <div id="app" class="app">
    <div v-if="store.isAuthenticated && store.firstLogin===false">
      <header class="header p-component border-round-right-2xl bg-gray-900 shadow-6">
        <div class="grid">
          <div class="flex col">
            <!-- Kopfzeileninhalt -->
            <!--       <img src="@/assets/famtura-with-name-sh.png" alt="Logo" class="logo" />-->
            <!-- Weitere Kopfzeileninhalte -->
            <Button v-if="isMobile" icon="pi pi-bars" class="mobile-sidebar-toggle" @click="mobileMenu" text></Button>
            <img v-if="!isMobile && !isSidebarExpanded" src="@/assets/famtura_icon_fb.svg" alt="Sidebar Icon"
              class="sidebar-icon scheinShadow" @click="
                holdSidebar = true;
              isSidebarExpanded = true;
              " />
            <img v-if="isSidebarExpanded" src="@/assets/famtura_logo_t.svg" alt="Sidebar Icon"
              class="sidebar-icon-long scheinShadow" @click="
                holdSidebar = false;
              isSidebarExpanded = false;
              " />
            <img v-if="isMobile" src="@/assets/famtura_icon_fb.svg" alt="Icon" class="logo" />
            <div v-if="store.debug">{{ store.companySettings.cid }}</div>
            <div v-if="store.debug" class="text-white">{{ store.whoAmIData.userName }}</div>
            <span v-if="store.debug">{{ console.log("store", store) }}</span>
          </div>
          <div class="col flex card align-items-center">
            <div class="flex-grow-1 align-items-center justify-content-center">
              <span class="font-light text-xl" style="color: #ddd; font-weight: 400; font-size: auto" v-if="!isMobile">
<span v-html="store.headerTitel"></span>
<span class="text-orange-500" v-if="store.debug" @dblclick="setDebugOff"><strong> !! DEBUG MODUS AKTIVIERT !!</strong></span>
              </span>
              <span style="color: #ddd; font-weight: 400" v-else>
                {{ store.headerTitel }}
              </span>
            </div>
            <div class="flex align-items-center justify-content-end">
              <span class="pl-1">{{ store.loggedUserName }}</span>
              <Button v-if="store.isMainUser" text rounded icon="pi pi-cog text-white text-2xl" v-tooltip.left="'Einstellungen'" id="settingsButton" aria-haspopup="true" @click="popupMenuToggle" />
              <Menu ref="popupMenu" id="overlay_popupMenu" :model="popupMenuItemsOwner" :popup="true"
                aria-controls="overlay_menu">
                <template #headericon="{ item }">
                  <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                    class="material-symbols-outlined text-xl">{{ item.customIcon }}</span>
                </template>
                <template #itemicon="{ item }">
                  <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                    class="material-symbols-outlined text-xl">{{ item.customIcon }}</span>
                </template>
              </Menu>


              <Button icon="pi pi-sign-out text-white text-2xl" id="buttonLogout" text v-tooltip.left="'Abmelden'" rounded @click="confirmLogOut" />
              <Button icon="pi pi-ellipsis-v" style="color: #ddd;" text rounded aria-label="help" @click="toggle"
                aria-haspopup="true" aria-controls="overlay_menu" />
              <Menu ref="menu" id="overlay_menu" :model="popUpItems" :popup="true" />
            </div>
          </div>
        </div>
      </header>
      <div class="main-container">
        <div v-show="!isMobile" class="sidebar-container">
          <div class="sidebar bg-gray-900 shadow-7 border-round-bottom-2xl"
            :class="{ 'sidebar-expanded': isSidebarExpanded }" @mouseenter="expandSidebar" @mouseleave="collapseSidebar">
            <!-- Seitenleisteninhalt -->

            <PanelMenu v-model:expandedKeys="expandedKeys" :separator="false" :model="store.isManagementUser?menuItems:menuItemsStuff"
              :style="{ width: isSidebarExpanded ? '200px' : '50px' }" :multiple="false" @command="handleMenuCommand"
              class="menu-panel" :pt="{
                headerContent: { class: 'bg-gray-900' },
                menuContent: { class: 'bg-gray-900' },
                headerLabel: { class: 'text-white' },
                label: {
                  class: 'text-white',
                },

              }">
              <template #headericon="{ item }">
                <span class="bg-gray-900 text-white" v-if="item.iconKind == 'primevue'" :class="item.icon"
                  style="padding-right: 10px"></span>
                <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                  class="material-symbols-outlined bg-gray-900 text-white">{{ item.customIcon }}</span>
              </template>
              <template #itemicon="{ item }">
                <span class="bg-gray-900 text-white" v-if="item.iconKind == 'primevue'" :class="item.icon"
                  style="padding-right: 10px"></span>
                <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                  class="material-symbols-outlined bg-gray-900 text-white">{{ item.customIcon }}</span>
              </template>
            </PanelMenu>
          </div>
        </div>

        <div v-if="isMobile">
          <!--           <div @click="toggleSidebar">
 -->
          <Sidebar v-model:visible="MenuVisible" :showCloseIcon="false" autoZIndex blockScroll :modal="false" :pt="{
            root: {
              style: { top: '62px' },
              class: 'w-20rem bg-gray-900 text-white',
            },
          }">
            <div>
              <PanelMenu v-model:expandedKeys="expandedKeys" :separator="false" :model="store.isManagementUser?menuItems:menuItemsStuff" s :multiple="false"
                class="w-full top:21rem md:w-25rem text-white"
                :pt="{
                headerContent: { class: 'bg-gray-900' },
                menuContent: { class: 'bg-gray-900' },
                headerLabel: { class: 'text-white' },
                label: {
                  class: 'text-white',
                },}"
                >
                <template #headericon="{ item }">
                  <span v-if="item.iconKind == 'primevue'" class="bg-gray-900 text-white" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                    class="material-symbols-outlined bg-gray-900 text-white">{{ item.customIcon }}</span>
                </template>
                <template #itemicon="{ item }">
                  <span v-if="item.iconKind == 'primevue'" class="bg-gray-900 text-white" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 9px; margin-left: -5px;"
                    class="material-symbols-outlined bg-gray-900 text-white">{{ item.customIcon }}</span>
                </template>
              </PanelMenu>
            </div>
          </Sidebar>
          <!--           </div>
 -->
        </div>
      </div>
    </div>
    <div v-if="store.isAuthenticated && store.firstLogin==false" class="content"
      :style="{ 'margin-left': isMobile ? '0' : '70px' }">
      <router-view></router-view>
    </div>
    <div v-else class="content">
      <router-view></router-view>
    </div>
    <!-- Hauptinhalt der Anwendung -->
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeMount } from "vue";
import { userStore } from "@/store/userStore";
import { useRouter, useRoute } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import 'material-symbols/outlined.css';
import { useToast } from "primevue/usetoast";
import SupportWidget from "@/components/SupportWidget.vue";


const toast = useToast();
const { locale } = useI18n();
const confirm = useConfirm();

const currentRoute = useRoute();
const router = useRouter();
const store = userStore();
const isSidebarExpanded = ref(false);
const isMobile = ref(false);
const expandedKeys = ref([]);
const isSidebarCollapsed = ref(false);
const MenuVisible = ref(false);

const fastStartVisible = computed(() => {
  return (
    !store.tipEnabledList?.tipsList?.hasOwnProperty("fastStartVisible") ||
    store.tipEnabledList?.tipsList?.["fastStartVisible"]
  );
});

const holdSidebar = ref(false);
let dbgZlr=0;
const menuItems = ref([
  {
    key: "0",
    label: "Schnellstart",
    icon: "pi pi-star",
    iconKind: "primevue",
    url: "/faststart",
    visible: fastStartVisible,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "SCHNELLSTART";
    },
  },
  {
    key: "1",
    label: "Dashboard",
    icon: "pi pi-home",
    iconKind: "primevue",
    visible: store.rules.activatedModules.dashboard,
    url: "/dashboard",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "DASHBOARD";
    },
  },
  {
    key: "2",
    label: "Katalog",
    icon: "pi pi-warehouse",
    iconKind: "primevue",
    visible: store.rules.activatedModules.catalog,
    url: "/itemsView",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "Katalog";
    },
  },
  {
    key: "3",
    label: "Kunden/Kontakte/Liefer..",
    icon: "pi pi-address-book",
    iconKind: "primevue",
    visible: store.rules.activatedModules.customer,
    url: "/kundenView",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "ADRESSBUCH";
    },
  },
  {
    key: "4",
    label: "Angebote",
    iconKind: "primevue",
    icon: "pi pi-file",
    visible: store.rules.activatedModules.offer,
    url: "/OffersView",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "ANGEBOTE";
    },
  },
  {
    key: "5",
    label: "Aufträge",
    iconKind: "primevue",
    icon: "pi pi-book",
    visible: store.rules.activatedModules.order,
    url: "/ordersView",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "AUFTRÄGE";
    },
  },
  {
    key: "6",
    label: "Rechnungen",
    iconKind: "primevue",
    //customIcon: "receipt_long",
    icon:"pi pi-wallet",
    url: "/invoicesView",
    visible: store.rules.activatedModules.invoice,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "RECHNUNGEN";
    },
  },
   {
    key: "7",
    label: "Gutschriften",
    iconKind: "primevue",
    //customIcon: "receipt_long",
    icon:"pi pi-cart-minus",
    url: "/creditnoteView",
    visible: store.rules.activatedModules.creditNote,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "GUTSCHRIFTEN";
    },
  },
  {
    key: "8",
    label: "Geschäftsschreiben",
    iconKind: "primevue",
    icon: "pi pi-file-edit",
    url: "/businessLetter",
    visible: store.rules.activatedModules.businessLetter,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "GESCHÄFSBRIEFE";
    },
  },
  {
    key: "9",
    label: "Versandabwicklung",
    icon: "pi pi-truck",
    iconKind: "primevue",
    url: "/shippingPrepareView",
    visible: store.rules.activatedModules.picking,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "VERSAND - ABWICKLUNG";
    },
  },
  {
    key: "10",
    label: "Versand",
    icon: "pi pi-qrcode",
    iconKind: "primevue",
    url: "/qrscanner",
    visible: store.rules.activatedModules.shipping,
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "VERSAND & QR SCANNER";
    },
  }
]);

const menuItemsStuff = menuItems; /* ref([
  {
    key: "9",
    label: "Inventur",
    icon: "pi pi-barcode",
    iconKind: "primevue",
    url: "/inventurScanner",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "Inventur";
    },
  },
]);*/

const menu = ref();
const popUpItems = ref([
  {
    items: [
      {
        label: "Handbuch",
        icon: "pi pi-file",
        url: "/handbook",
        command: () => {
          store.headerTitel = "Handbuch";
        },
      },
      {
        label: "Support / Hilfe erhalten",
        iconKind: "primevue",
        //customIcon: "receipt_long",
        icon: "pi pi-ticket",
        url: "/ticketsView",
        command: () => {
          MenuVisible.value = false;
          store.headerTitel = "SUPPORT";
        },
      },
      {
        label: "",
        command: () => {
          handleDebug();
        },
      },
    ],
  },
]);

const handleDebug = () => {
  dbgZlr++;
  if (dbgZlr>2){
    dbgZlr=0;
  store.setDebug(true);
  toast.add({
    severity: "info",
    summary: "DEBUG MODUS",
    detail: "DEBUG MODUS AKTIVIERT",
    life: 3000,
  });
}
};

const toggle = (event) => {
  menu.value.toggle(event);
};

const popupMenu = ref();

const popupMenuItemsLight = ref([
  {
    key: "1",
    label: "Rechtstexte",
    url: "legalTextsSettingsForm",
    iconKind: "primevue",
    icon: "pi pi-graduation-cap",
    //customIcon: "gavel",
    //icon: "ti ti-section-sign",
    //iconClass: "ti ti-section-sign",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "RECHTSTEXTE";
    },
  },
  {
    key: "2",
    label: "Erweitert",
    url: "Settings",
    iconKind: "primevue",
    icon: "pi pi-sliders-h",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "Einstellungen";
    },
  },
]);

const popupMenuItemsOwner = ref([
  {
    key: "1",
    label: "Benutzerdaten",
    icon: "pi pi-user-edit",
    iconKind: "primevue",
    url: "UserSettingsView",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "BENUTZERDATEN";
    },
  },
  {
    key: "2",
    label: "Firmen-Profildaten",
    icon: "pi pi-wrench",
    iconKind: "primevue",
    url: "CompanySettingsForm",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "PROFILDATEN";
    },
  },
  {
    key: "3",
    label: "Rechtstexte",
    url: "legalTextsSettingsForm",
    iconKind: "primevue",
    icon: "pi pi-graduation-cap",
    //customIcon: "gavel",
    //icon: "ti ti-section-sign",
    //iconClass: "ti ti-section-sign",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "RECHTSTEXTE";
    },
  },
  {
    key: "4",
    label: "Erweitert",
    url: "Settings",
    iconKind: "primevue",
    icon: "pi pi-sliders-h",
    command: () => {
      MenuVisible.value = false;
      store.headerTitel = "Einstellungen";
    },
  },
]);
const popupMenuToggle = (event) => {
  popupMenu.value.toggle(event);
};

const confirmLogOut = (data, draftToInvoice) => {
  let msg = "Bestätigen Sie die Abmeldung?";
  confirm.require({
    message: msg,
    header: "Abmelden bestätigen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      logout();
    },
    reject: () => {
    },
  });
};

const setDebugOff=()=>{
  store.setDebug(false);
  toast.add({
    severity: "warn",
    summary: "DEBUG MODUS",
    detail: "DEBUG MODUS DEAKTIVIERT",
    life: 3000,
  });
}

const logout = () => {
  try {
    store.logout();
    MenuVisible.value = false;
    router.push("/");
  } catch (e) {
    console.log("\n\n logout erro", e);
  }
};

const mobileMenu = () => {
  MenuVisible.value = !MenuVisible.value;
  if (MenuVisible.value === true) {
    const mask = document.querySelector(".p-sidebar-mask");
    const overlayClass = "p-component-overlay-enter";
    if (mask && mask.classList.contains(overlayClass)) {
      mask.classList.remove(overlayClass);
    }
  }
};

/* const toggleSidebar = () => {
  if (isMobile.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
  }
}; */

//watchEffect(() => {
/*   if (currentRoute.name=="MailConfirm"){
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute=MailConfirm");
}else{
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute!=MailConfirm");
}

if (store.isAuthenticated==false && currentRoute.name!="login" && currentRoute.name!="MailConfirm") {
  //router.push('/');
} */
//});

const expandSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = true;
};

const collapseSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = false;
};

const handleMenuCommand = (event) => {
  // Aktion nach dem Betätigen eines Menüpunkts
  // Hier die Sidebar verkleinern
  collapseSidebar();
};

const checkMobile = () => {
  isMobile.value = window.innerWidth < 769; // Anpassen der Bildschirmbreite nach Bedarf

  if (isMobile.value) {
    isSidebarExpanded.value = false; // Sidebar ausblenden auf mobilen Geräten
  }
};

onMounted(async () => {
  store.refreshUserData;
  console.log("store", store);
  locale.value = "de";
  checkMobile();
  window.addEventListener("resize", checkMobile);
});
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  background-color: #ffffff;
  padding-left: 7px;
  z-index: 2;
  height: 60px;
  width: 100%;
  transition: width 0.2s;
}

.scheinShadow {
  filter: drop-shadow(-0.9rem -0.05rem 0.07rem #282828);
  /*   filter: drop-shadow(0px 3px 1px rgba(255, 255, 255,0.6));
 */
}

.logo {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  top: 60px;
}

.sidebar {
  background-color: #fff;
  width: 70px;
  height: 100%;
  padding: 10px;
  transition: width 0.3s ease;
}

.sidebar-icon {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sidebar-icon-long {
  height: 50px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar-expanded {
  width: 220px;
}

.content {
  flex-grow: 1;
  padding: 10px;
  z-index: 1;
  margin-top: 60px;
}

:deep(.p-panelmenu .p-panelmenu-header) {
  width: 55 px;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
  background-color: #ffffff;
  background: #ffffff;
  border: 0;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action) {
  padding: 1.25rem;
  font-weight: 700;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .pi) {
  font-size: 24px;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .pi .material-symbols-outlined) {
  font-size: 32px;
}

:deep(.p-panelmenu .p-submenu-icon) {
  display: none !important;
}

@media (min-width: 769px) {
  :deep(.p-menuitem-text) {
    display: none;
  }
}

:deep(.sidebar-expanded .p-menuitem-text) {
  display: inline;
  font-size: 12px;
  font-weight: 500;
}

:deep(.p-sidebar) {
  top: 62px;
}

.sidebar-expanded {
  width: 100%;
  /* Vollständige Breite der Sidebar */
}
</style>
