<template>
  <template v-if="!store.rules.activatedModules.customer">
  <UpgradeLicence />
    </template>
  <template v-else>
  <div class="card p-fluid">
    <Loading v-show="loading" />

    <Tip
      v-if="lazyLoading == false && customers.length > 0"
      :target="'#editCustomer'"
      :tipId="'editCustomerTip'"
      :placement="'right'"
      :overlay="false"
      :timeout="1000"
    />

    <div>
      <InputText
        v-model="tagSuchFeld"
        v-tooltip.top.focus="'Suche nach Suchtags die unter History,Notizen.. definiert wurden. '"
        type="text"
        class="p-column-filter"
        placeholder="TAG-Suche"/>
    </div>
    <div style="text-align: left">
      <MultiSelect
        :modelValue="selectedColumns"
        :options="columns"
        optionLabel="header"
        @update:modelValue="onToggle"
        display="chip"
        placeholder="Spalten Ansicht wählen"
      />
    </div>
    <DataTable
      ref="dataTableRef"
      v-model:filters="filters"
      :value="customers"
      editMode="row"
      scrollable
      scrollHeight="85vh"
      filterDisplay="row"
      tableStyle="min-width: 10%"
      resizableColumns
      columnResizeMode="fit"
      stripedRows
      selectionMode="single"
      dataKey="kundenNummer"
      sortField="kundenNummer"
      :sortOrder="-1"
      class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick"
      :virtualScrollerOptions="{
        lazy: false,
        onLazyLoad: loadCustomersLazy,
        itemSize: 46,
        delay: 300,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100,
      }"
    >
      <template #empty> Keine Einträge gefunden </template>

      <Column
        headerStyle="width: 2rem; text-align: center"
        footer=""
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #header>
          <div>
            <div>
              <Button
                type="button"
                outlined
                id="addCustomers"
                :pt="{ icon: { class: 'text-xl' } }"
                icon="pi pi-user-plus"
                @click="openKundenEditDialog('new')"
              />
            </div>
          </div>
        </template>
<template #body="{ data }">
  <Button
    type="button"
    :pt="{ icon: { class: 'text-xl' } }"
    id="editCustomer"
    text
    icon="pi pi-user-edit"
    aria-haspopup="true"
    :aria-controls="(data.contactType == 'CUSTOMER')?'overlay_menu_customer':'overlay_menu'"
    rounded
    @click="popUptoggle($event, data)"
    v-tooltip="'Ansehen/Bearbeiten'"
  />
  <Menu v-if="data.contactType == 'CUSTOMER'"
    ref="menuCustomer"
    id="overlay_menu_customer"
    :model="popUpitemsCustomer"
    :popup="true"
  />
  <Menu v-else
    ref="menuNonCustomer"
    id="overlay_menu"
    :model="popUpitems"
    :popup="true"
  />
</template>
      </Column>

      <Column
        field="name1"
        key="name1"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable
      >
        <template #header>
          <Dropdown
            class="border-none"
            inputClass="text-base font-bold"
            v-model="filters['contactType'].value"
            :options="calcUtils.contactTypes.value"
            optionValue="optionValue"
            optionLabel="optionLabel"
            placeholder="Kunden/Kontakte/Lieferanten"
            :showClear="true"
            @click.stop
          />
        </template>
        <template #body="{ data }">
          <span v-if="data.isCustomer" v-tooltip="'Kunde'">
            <span class="material-symbols-outlined text-lg text-green-800">
              support_agent </span
            >&nbsp;
          </span>
          <span v-if="data.isSupplier" v-tooltip="'Lieferant'">
            <span class="material-symbols-outlined text-lg text-orange-800">
              forklift </span
            >&nbsp;
          </span>
          <span v-if="data.isContact" v-tooltip="'Kontakt'">
            <span class="material-symbols-outlined text-lg text-pink-400">
              contact_phone</span
            >&nbsp;
          </span>
          <span v-if="data.isPrivateCustomer" v-tooltip="'Privat'">
            <span class="material-symbols-outlined text-lg text-teal-400">
              face </span
            >&nbsp; <span>{{ data.name1 }}</span></span
          >
          <span v-else v-tooltip="'Firma'">
            <i class="pi pi-building text-blue-400"></i>&nbsp;
            {{ data.name1 }}
          </span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
            type="text"
            @keydown.enter="filterCallback()"
            @input="filterModel.value || filterCallback()"
            class="p-column-filter"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <Column
        v-for="col of selectedColumns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="col.header"
        :showFilterMenu="false"
        sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field"
      >
        <template #filter="{ filterModel, filterCallback }">
          <span v-if="(col.field == 'accountStatus')">
            <Dropdown
              v-model="filterModel.value"
              :options="calcUtils.statuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Status"
              @change="filterCallback"
              class="p-column-filter"
              :showClear="true"
            >
            <template #option="slotProps">
              <Tag :value="slotProps.option.label" class="text-base" :class="slotProps.option.value" />
            </template>
          </Dropdown>
            </span>
            <span v-else>
              <div v-if="col.field === 'created_de' || col.field === 'updated_de'">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              class="p-column-filter" />
          </div>
        </span>
        </template>
        <template #body="{ data, field }">
          <span v-if="(col.field == 'accountStatus')">
            <span v-if="data.isCustomer">
              <Tag class="text-base" :value="calcUtils.getAccountStatus(data.accountStatus)" :class="data.statusAntrag" />
            </span>
            <span v-else></span>
          </span>
          <span v-else>
            {{ data[field] }}
          </span>
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeMount,
  computed,
  defineAsyncComponent,
  markRaw,
} from "vue";
import { useRouter } from "vue-router";
import Tip from "@/components/Tips/Tip.vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { CustomerService } from "@/service/CustomerService";
import Loading from "@/components/Loading";
import { useDialog } from "primevue/usedialog";
import { saveAndGetPDF } from "@/utils/invoiceUtils";
import { useI18n } from "vue-i18n";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import * as calcUtils from "@/utils/calculationUtils.js";
import InputText from "primevue/inputtext";

const store = userStore();
const toast = useToast();
const KundenEditDialog = defineAsyncComponent(() =>
  import("@/views/KundenEditDialog.vue")
);
const KundenEditDialogHeader = defineAsyncComponent(() =>
  import("@/views/KundenEditDialogHeader.vue")
);
const InvoicesEditComponent = defineAsyncComponent(() =>
  import("@/views/InvoicesEditComponent.vue")
);
const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/InvoicesEditFooter.vue")
);
FilterService.register("dateFilter", dateFilter);

const dialog = useDialog();
const { t } = useI18n();
const tagSuchFeld = ref("");
const router = useRouter();
const isMobile = ref(false);
const dialogVisible = ref(false);
const loading = ref(true);
const lazyLoading = ref(false);
const loadLazyTimeout = ref();
const dataTableRef = ref();
const allCustomers = ref([]);
const virtualCustomers = ref([]);

let rowData = null;

const popUpLabel = reactive({ label: "" });

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.name1;
  if (data.contactType == "CUSTOMER") {
    menuCustomer.value.toggle(event);
  } else {
    menuNonCustomer.value.toggle(event);
  }
};
const selectedUser = ref();
const menuCustomer = ref();
const menuNonCustomer = ref();

const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Ansehen/Bearbeiten",
        icon: "pi pi-file-edit",
        command: () => {
          if (rowData) {
            openKundenEditDialog(rowData);
          }
        },
      }
    ],
  },
]);

const popUpitemsCustomer = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Ansehen/Bearbeiten",
        icon: "pi pi-file-edit",
        command: () => {
          if (rowData) {
            openKundenEditDialog(rowData);
          }
        },
      },
      {
        label: "Zahlung buchen",
        icon: "pi pi-money-bill",
        command: () => {
          if (rowData) {
            console.log("Zahlung buchen");
          }
        },
      },
    ],
  },
]);


const columns = ref([
  { field: "address1", header: "Adresse" },
  { field: "postCode", header: "PLZ" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "created_de", header: "Erstellt" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "updated_de", header: "Geändert" },
  { field: "kundenNummer", header: "Kundennr" },
  { field: "lastSeen", header: "Letzte Aktivität" },
  { field: "accountStatus", header: "Account-Status" },
  { field: "searchTag.tags", header: "Suchtags" },
]);
const startColumns = ref([
  { field: "address1", header: "Adresse" },
  { field: "accountStatus", header: "Account-Status" },
]);
const selectedColumns = ref(startColumns.value);
const onToggle = (val) => {
  const valFields = val.map(v => v.field);
  columns.value.forEach((col) => {
    if (valFields.includes(col.field) && !selectedColumns.value.some(selectedCol => selectedCol.field === col.field)) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter((selectedCol) => selectedCol.field !== col.field);
    }
  });
};

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}


const filters = ref({
  contactType: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  address1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: { value: null, matchMode: "dateFilter", filterFunction: dateFilter, dateRange: [null, null],},
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: { value: null, matchMode: "dateFilter", filterFunction: dateFilter, dateRange: [null, null],},
  kundenNummer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  lastSeen: { value: null, matchMode: FilterMatchMode.CONTAINS },
  accountStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  "searchTag.tags": { value: null, matchMode: FilterMatchMode.CONTAINS },

});

function isSortable(field) {
  return field === "updated" || field === "created";
}

//Filter von customer nach dem Tag unter customer.searchTag.tags[] mit tags
const customers = computed(() => {
  if (!tagSuchFeld.value) {
    return allCustomers.value;
  }
  return allCustomers.value.filter(customer => 
    customer.searchTag.tags.some(tag => 
      tag.toLowerCase().includes(tagSuchFeld.value.toLowerCase())
    )
  );
});

const dataForDialog = ref();

const closeDialog = () => {
  dialogVisible.value = false;
};

const loadCustomersLazy = (event) => {
  try {
    !lazyLoading.value && (lazyLoading.value = true);

    if (loadLazyTimeout.value) {
      clearTimeout(loadLazyTimeout.value);
    }
    //simulate remote connection with a timeout
    loadLazyTimeout.value = setTimeout(() => {
      let virtuelleKunden = [...customers.value];
      let { first, last } = event;

      //load data of required page
      const loadedCustomers = customers.value.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(virtuelleKunden, [
        ...[first, last - first],
        ...loadedCustomers,
      ]);

      virtualCustomers.value = virtuelleKunden;
      lazyLoading.value = false;
    }, Math.random() * 1000 + 800);
  } catch (e) {
    console.log(e);
  }
};

const handleRowDoubleClick = (event) => {
  const data = event.data;
  openKundenEditDialog(data);
};

const initialCustomerValues = reactive({
  isPrivateCustomer: false,
  kundenNummer: "",
  activityReminderAfterDays : store.companySettings.lastCustomerActivityWarningDays,
  created_de: "",
  created_us: "",
  updated_de: "",
  updated_us: "",
  address1: "",
  address2: "",
  firmenName: "",
  salutation: "",
  firstName: "",
  surName: "",
  name2: "",
  street: "",
  address2: "",
  houseNumber: "",
  postCode: "",
  city: "",
  emailAddress: "",
  phone: "",
  mobilePhone: "",
  extraAddress: ref({}),
  linkedToCustomerId: null,
  defaultVk: "VK-1",
  searchTag: {
    tags: [],
  },
});

const extraAdressShema = reactive({
  list: [
    {
      bezeichnung: "",
      name1: "",
      name2: "",
      street: "",
      houseNumber: "",
      address2: "",
      postCode: "",
      city: "",
    },
  ],
});

const newCustomer = reactive({ ...initialCustomerValues });

const resetCustomer = () => {
  Object.assign(newCustomer, initialCustomerValues);
};

const CustomerDetails = async (customerUid) => {
  let responseCustomer = null;
  try {
    let url =
      process.env.VUE_APP_BASE_API_URL +
      "/company/customer-details/" +
      customerUid;
    console.log("🚀 ~ file: KundenView.vue:364 ~ CustomerDetails ~ url:", url);
    const response = await iAxios.get(url);
    const responseData = await response.data;
    responseCustomer = responseData.customer;
  } catch (error) {
    console.log(error);
  }
  return responseCustomer;
};

const openKundenEditDialog = async (event) => {
  console.log(
    "🚀 ~ file: KundenView.vue:374 ~ openKundenEditDialog ~ event:",
    event
  );
  let customerData = null;
  const isNew = event == "new";
  console.log(
    "🚀 ~ file: KundenView.vue:378 ~ openKundenEditDialog ~ isNew:",
    isNew
  );

  const rowData = ref(event.data ? event.data : event);
  if (!isNew) {
    customerData = await CustomerDetails(rowData.value.customerUid);
  } else customerData = newCustomer;

  console.log(
    "🚀 ~ file: KundenView.vue:382 ~ openKundenEditDialog ~ customerData:",
    customerData
  );

  const originalData = rowData.value;
  dialog.open(KundenEditDialog, {
    props: {
      header: isNew ? " hinzufügen" : " Bearbeiten",
      modal: true,
      closable: false,
      style: {
        width: "50vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(KundenEditDialogHeader),
    },
    data: {
      customerData,
      isNewCustomer: isNew,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const customerUid = options.data.customerUid;
        if (buttonType == "Save") {
          refreshDataTable();
        } else if (buttonType == "Cancel") {
        } else if (buttonType == "Delete") {
          deleteCustomer(customerUid);
        }
      }
      resetCustomer();
    },
  });
};

const refreshDataTable = async () => {
  toast.add({
    severity: "info",
    summary: "Aktuallisiere",
    detail: "Daten werden erneut geladen",
    life: 3000,
  });
  await werteLaden();
  virtualCustomers.value = [...customers.value];
};

const deleteCustomer = async (customerUid) => {
  try {
    const res = await iAxios.delete(
      process.env.VUE_APP_BASE_API_URL + "/company/customer/" + customerUid
    );
    refreshDataTable();
  } catch (e) {
    console.log(e.message);
  }
};

/* async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
} */

async function werteLaden() {
  try {
    loading.value = true;
    await CustomerService.getCustomerXLarge().then((data) => {
      if (!data) {
        loading.value = false;
        customers.value = [];
        return;
      }
      allCustomers.value = data.map((customer) => ({
        ...customer,
        isCustomer: customer.contactType == "CUSTOMER",
        isSupplier: customer.contactType == "SUPPLIER",
        isContact: customer.contactType == "CONTACT",
      })); //optimierbar würde um 1-1,5 sekunden schneller laden wenn vom backend kommt
      //  if (customers.value.length == 0) {
      //    router.push("/nocustomers");
      //   } //numberOfCustomers=customers.length;
    });
    if (store.debug) console.log("KundenView: Kunden geladen", customers.value);
    loading.value = false;
  } catch (Exception) {
    console.log(Exception);
    loading.value = false;
    //  if (!customers.value.length > 0) {
    //   router.push("/nocustomers");
    //  }
  }
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  //  await zeitMessen(werteLaden);
  await werteLaden();
});
</script>

<style scoped>

:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
