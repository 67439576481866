import axios from "axios";
import router from "@/router";
import { userStore } from "@/store/userStore";

// definition of request <pre> settings and headers
// these will be applied for all requests made with defAxios
const iAxios = axios.create({
  withCredentials: true,
  xsrfHeaderName: "X-Csrftoken",
  xsrfCookieName: "csrf_access_token",
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
  responseType: "json"
});

/*  iAxios.interceptors.response.use((config) => {
    return config;
  });
 */

  iAxios.interceptors.response.use(
    config => {
      return config;
    },
    async error => {
      const originalRequest = error.config;
      const store = userStore();
      if (error.response && error.response.status === 401) {
        try {
          const res_1 = await new Promise((resolve, reject) => {         
            iAxios.get(process.env.VUE_APP_BASE_API_URL + "/refresh")
              .then(res => {
                resolve(res);
              })
              .catch(err => {
                reject(err);
              });
          });
          //return await axios(originalRequest);
          return await Promise.reject(error);
        } catch (err_1) {
          return await Promise.reject(err_1);
        }
      } else if (error.response && error.response.status === 403) {
        if (router.currentRoute.value.name === 'MailConfirm') return;
        if (router.currentRoute.value.name === 'reset-password') return;
        if (router.currentRoute.value.name !== 'login') {
          store.logout();
          console.clear();
          router.push({ name: 'login' });    
            }
        return Promise.reject(error);
      } 
      else if (error.response && (error.response.status === 419 || error.response.status === 412)) {
        console.log("error 412 oder 419");
        store.loggedIN = false;
        await store.logout();
        throw new Error('412 oder 419: Zugang abgelaufen, erneuter Login notwendig');
      }
       else if (error.response && error.response.status === 500) {
      console.log("500: Serverfehler");
      return Promise.reject(error);
    }
      else {
        return Promise.reject(error);
      }
    }
  );





/* iAxios.interceptors.response.use(response => response,error => {
  console.log("response");
      if (error.response.status === 401) {
        // Fehler ignorieren und eigene Fehlermeldung anzeigen
        console.log("Unauthorisiert: Bitte melden Sie sich an.");
      }
      return Promise.reject(error);
    }
  ); */




export default iAxios;