<template>
  <div>
    <div :class="tipOverlay?.visible == true ? 'overlay' : ''"></div>
    <OverlayPanel ref="tipOverlay" @hide="tipHide" :pt="{
      root: { class: 'p-overlaypanelTip' }
    }">
      <div class="p-3 border-round-top tipTitel flex justify-content-center ">{{ tip.title }}</div>
      <div class="grid p-3">
        <div class="col-2">
          <i :class="tip.contentIcon+' text-blue-500 text-3xl'"></i>
        </div>
        <div class="col-10" v-html="tip.contentText"></div>
        <div v-html="tip.image"></div>
        <div v-html="tip.contentFooter"></div>
      </div>
      <div class="p-1 grid text-xs ">
        <div class="col-12 p-0 m-0 flex justify-content-center">
          <Button text label="Diesen Tip nicht mehr anzeigen" class="text-gray-400 text-xs" icon="pi pi-eye-slash" @click="dontShowTip"/>
      </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, computed, } from 'vue';
import tips from './tips.js';
import router from '@/router';
import { userStore } from "@/store/userStore";

const store = userStore();
const tipEnabledList = store.tipEnabledList;

const tipEnabled = () => {
  console.log("tipEnabled",(!tipEnabledList?.tipsList?.hasOwnProperty(props.tipId) || tipEnabledList?.tipsList?.[props.tipId]));//DEBUG
  return (
    !tipEnabledList?.tipsList?.hasOwnProperty(props.tipId) || tipEnabledList?.tipsList?.[props.tipId]
  )
};

const tipOverlay = ref();

const RdyToShow = ref(false);
const showOverlay = ref(false);
const props = defineProps({
  overlay: {
    type: Boolean,
    required: false,
    default: true,
  },
  tipId: {
    type: String,
    required: true
  },
  target: {
    required: false,
    default: 'false'
  },
  placement: {
    type: String,
    required: false,
    default: 'top'
  },
  route: {
    type: String,
    required: false,
    default: '',
  },
  routeLabel: {
    type: String,
    required: false,
    default: 'OK',
  },
  sideTitel: {
    type: String,
    required: false,
    default: '',
  },
  timeout: {
    required: false,
    default: 0,
  },
  showCheckbox: {
    required: false,
    default: true,
  },
});

const tip = computed(() => {
  return tips.find((tip) => tip.tipId === props.tipId);
});

const tipOptions = ref({
  labels: {
    buttonSkip: 'Überspringen',
    buttonPrevious: 'Zurück',
    buttonNext: 'Weiter',
    buttonStop: props.routeLabel,
  },
  highlight: true,
  checkbox: props.showCheckbox,
  checkboxLabel: "Nicht erneut anzeigen",
});

function dontShowTip(event) {
  console.log("dontshow",event);//DEBUG

  const tipId = props.tipId;
  if (!tipEnabledList.tipsList) {
    tipEnabledList.tipsList = {};
  }
  tipEnabledList.tipsList[tipId] = false;
  store.setCompanyTips(tipEnabledList);
  tipOverlay.value.hide();
}

const steps = [
  {
    target: props.target,
    header: {
      title: `<span style="font-size: 1.1rem; color:#fff" class="p-component">${tip.value.title}</span>`,
    },
    content: `<div class="flex p-component"><div class="p-2"><i class="pi pi-info-circle text-justify " style="font-size: 2rem;color:#fff"></i></div><div>${tip.value.contentText}</div></div>${tip.value.image}${tip.value.contentFooter}<br>`,
    params: {
      highlight: true,
      placement: props.placement,
    }
  },
];

const Callbacks = {
  onStart: () => {
    showOverlay.value = true;
  },
  onStop: () => {
    if (props.route != 'OK') {
      router.push(props.route);
    }
    showOverlay.value = false;
    console.log("onFinish");
  },
  onFinish: () => {
    console.log("onFinish");
    showOverlay.value = false;
    store.headerTitel = props.sideTitel;
  },
};

const tipShow = () => {
  if (tipEnabled()==false) return;
  const target = document.querySelector(props.target);
  if (!target) return;
  const event = { currentTarget: target };

  if (tipOverlay.value) setTimeout(() => {
    tipOverlay.value.show(event);
    if (target) {
      target.classList.add('target--highlighted');
    }
  }, 10);
}

const tipHide = () => {
  const target = document.querySelector(props.target);
  if (tipOverlay.value) {
    tipOverlay.value.hide();
    if (target) {
      target.classList.remove('target--highlighted');
    }
  }
}



onMounted(async () => {


  try {
  } catch (e) {
    console.error(e);
  }
  if (props.route != '' && props.route != 'OK') {
    const newZIndexValue = 1000;
    const targetElement = document.querySelector(props.target);
    if (targetElement) {
      targetElement.style.zIndex = newZIndexValue;
    }
  }

  RdyToShow.value = true;
  if (RdyToShow.value) setTimeout(() => {
    console.log(tipOverlay.value)
    tipShow();
  }, 20);

});


</script>
<style>
.tipTitel {
  font-weight: 500;
  font-size: 1.1rem;
  color: #fff;
  background: #20858e;
}

.p-overlaypanelTip {
  /* background: #20858e; */
  box-shadow: 15px 5px 15px 45px rgba(255, 255, 255, 0.5);
}

.p-overlaypanelTip.p-overlaypanel-flipped::before {
  border-top-color: #20858e;
}

.p-overlaypanelTip.p-overlaypanel-flipped::after {
  border-top-color: #20858e;
}

.p-overlaypanelTip.p-overlaypanel::before {
  border-bottom-color: #20858e;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.p-overlaypanelTip.p-overlaypanel::after {
  border-bottom-color: #20858e;
}
</style>
<style scoped>
:deep(.overlay) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1 !important;
}
</style>
<style>
/*tips*/
@keyframes glowing {
  0% {
    box-shadow: 0 0 0 2px #00b11db8;
  }

  50% {
    box-shadow: 0 0 0 10px #00ff2a;
  }

  100% {
    box-shadow: 0 0 0 2px #00b11db8;
  }
}

.target--highlighted {
  animation: glowing 1300ms infinite;
  /* box-shadow: 0 0 0 99999px rgba(0,0,0,.4); */
  /*  box-shadow: 0 0 0 4px #06ff30b8!important; */
}

/*tips*/
</style>