import { ref,reactive } from "vue";
import { userStore } from "@/store/userStore";

export const itemType = ref([
  { optionLabel: "Waren", optionValue: "goods" },
  { optionLabel: "Dienstleistung", optionValue: "service"},
  { optionLabel: "Sonstiges", optionValue: "different" },
]);

export const calcVK = (item) => {
  console.log("🚀 ~ file: calculationUtils.js:9 ~ item", item)
  if (item.aufschlag > 0) {
    const aufschlagFactor = 1 + item.aufschlag / 100;
    const vk = parseFloat(
      (item.itemInPrice * aufschlagFactor).toFixed(2)
    );
    item.itemOutPrice = vk;
  }
};


export const calcFromVK = (item,vkField) => {
if (!vkField) {
  item.aufschlag = 0;
return;
}
  if (
    item.itemInPrice > 0 &&
    vkField >
    item.itemInPrice
  ) {
    const difference =
    vkField - item.itemInPrice;
    const markup = (difference / item.itemInPrice) * 100;
    item.aufschlag = parseFloat(markup.toFixed(2));
  }
};

export const formatDate = (date) => {
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 101).toString().substring(1);
  var day = (date.getDate() + 100).toString().substring(1);
  return year + "-" + month + "-" + day;
};
export const bruttoRechner = (item) => {
  return (
    item.itemOutPrice *
    (1 + (item.itemTaxValue/100))
  ).toFixed(2);
};


export const marge = (item,vkField) => {
  item.marge = (vkField - item.itemInPrice).toFixed(2);
    return item.marge;
  };

  export const brutto = (item,vkField) => {
    console.log("🚀 ~ file: calculationUtils.js:60 ~ item.itemTaxValue:", item.itemTaxValue)

    item.bruttoPreis = (
    vkField *
    (1+(item.itemTaxValue/100))
  ).toFixed(2);
      return item.marge;
    };



  export const rolle = ref([
    { optionLabel: "Management", optionValue: "MANAGEMENT" },
    { optionLabel: "Fachkraft", optionValue: "WORKER" },
    { optionLabel: "Arbeiter", optionValue: "STUFF" },
  ])

  export const rolleRead = ref([
    { optionLabel: "Inhaber", optionValue: "OWNER" },
    { optionLabel: "Management/Admin", optionValue: "ADMIN" },
    { optionLabel: "Management", optionValue: "MANAGEMENT" },
    { optionLabel: "Fachkraft", optionValue: "WORKER" },
    { optionLabel: "Arbeiter", optionValue: "STUFF" },
  ])

  export const vkStufen = ref([
    { optionLabel: "VK-1", optionValue: "VK-1" },
    { optionLabel: "VK-2", optionValue: "VK-2" },
    { optionLabel: "VK-3", optionValue: "VK-3" },
    { optionLabel: "VK-4", optionValue: "VK-4" },
    { optionLabel: "VK-5", optionValue: "VK-5" },
    { optionLabel: "VK-6", optionValue: "VK-6" },
    { optionLabel: "VK-7", optionValue: "VK-7" },
    { optionLabel: "VK-8", optionValue: "VK-8" },
  ])

  export const contactTypes = ref([
    { optionLabel: "Kunde", optionValue: "CUSTOMER" },
    { optionLabel: "Lieferant", optionValue: "SUPPLIER" },
    { optionLabel: "Kontakt", optionValue: "CONTACT" },
  ]);

  export const statuses = reactive([
    {label:"REGISTRIERT", value:"REGISTERED"},
    {label:"AKTIV", value:"ACTIVE",},
    {label:"PAUSIERT", value:"PAUSED"},
    {label:"GESPERRT", value:"CANCELED"},
    {label:"ABGELEHNT", value:"REJECTED"},
    {label:"GEKÜNDIGT", value:"TERMINATED"},
    {label:"GELÖSCHT", value:"DELETED"},
  ]);
  
  export const getAccountStatus = (status) => {

    console.log("🚀 ~ file: calculationUtils.js:99 ~ status:", status)
    
    return statuses.find((s) => s.value == status).label;
  };  

  
   export const skontoZiele = ref([
    { optionLabel: "kein Skonto", optionValue: 0 },
    { optionLabel: "3 Tage", optionValue: 3 },
    { optionLabel: "7 Tage", optionValue: 7 },
    { optionLabel: "14 Tage", optionValue: 14 },
    { optionLabel: "28 Tage", optionValue: 28 },
  ]);

  export const zahlungsZiele = ref([
    { optionLabel: "Kein Zahlungsziel", optionValue: -1 },
    { optionLabel: "Sofort", optionValue: 0},
    { optionLabel: "3", optionValue: 3 },
    { optionLabel: "7", optionValue: 7 },
    { optionLabel: "14", optionValue: 14 },
    { optionLabel: "28", optionValue: 28 },
  ]);

export const parcelServices = ref([
  { name: 'DHL', code: 'dhl' },
  { name: 'Hermes', code: 'hermes' },
  { name: 'UPS', code: 'ups' },
  { name: 'GLS', code: 'gls' },
  { name: 'Deutsche Post', code: 'deutschePost' },
  { name: 'FedEx', code: 'fedex' },
  { name: 'DPD', code: 'dpd' },
  { name: 'Amazon Logistics', code: 'amazonLogistics' },
  { name: 'TNT', code: 'tnt' },
  { name: 'GO!', code: 'go' },
  { name: 'iloxx', code: 'iloox' },
  { name: 'Trans-o-flex', code: 'transoFlex' },
  { name: 'UPS Express Saver', code: 'upsExpressSaver' },
  { name: 'Der Kurier', code: 'derKurier' },
  { name: 'Night Star Express', code: 'nightStarExpress' },
  { name: 'Cargo International', code: 'cargoIntl' },
  { name: 'Eurodis', code: 'eurodis' },
  { name: 'General Logistics Systems (GLS)', code: 'generalLS' },
  { name: 'Parcel One', code: 'parcelOne' },
  { name: 'Gebrüder Weiss', code: 'gebruederWeiss' },
  { name: "PinAG", code: 'pinag' }
]);

export const getTaxOptionById=(taxId) =>{
  const store = userStore();
  const taxOptions = store.companySettings.taxOptions.taxOptionsData;
  const tax = taxOptions.find(option => option.id === taxId);
  return tax ? tax : null;
};