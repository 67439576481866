import { reactive } from "vue";
import iAxios from "@/store/axiosInterface";

export const CustomerService = {
  async getData() {
    try {
      const pat =  await iAxios.get(process.env.VUE_APP_BASE_API_URL+"/company/customers");
      const data = await reactive(pat.data.customers);
      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async getCustomerXLarge() {
    return Promise.resolve(this.getData());
  },

  async getCustomerDetails(cusomterUid) {
    try {
      const customerDetail =  await iAxios.get(process.env.VUE_APP_BASE_API_URL+"/company/customer-details/"+cusomterUid);
      const data = await reactive(customerDetail.data.customer);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  
  async getCustomerExtraAddressList(cusomterUid){
    try {
      const customerDetail =  await this.getCustomerDetails(cusomterUid);
      const extraAddress = await customerDetail.extraAddress;  
      if (extraAddress) {
        const listExtraAddress = await reactive(extraAddress.list);  
        if (listExtraAddress) {
          const mainAdress={
            bezeichnung: 'Hauptadresse',
            name1: customerDetail.isPrivateCustomer?customerDetail.salutation + ' ' + customerDetail.firstName + ' ' + customerDetail.surName:customerDetail.firmenName,
            name2: customerDetail.name2,
            street: customerDetail.street,
            houseNumber: customerDetail.houseNumber,
            address1: customerDetail.street + ' ' + customerDetail.houseNumber,
            address2: customerDetail.address2,
            postCode: customerDetail.postCode,
            city: customerDetail.city,
          };
          listExtraAddress.push(mainAdress);
          return listExtraAddress;
        }
      return null;
      }
    } catch (e) {
      console.log(e);
    }
  },

};