<template>
  <template v-if="!store.rules.activatedModules.catalog">
  <UpgradeLicence />
    </template>
  <template v-else>
      <Menubar :model="menuItems" v-if="showAdvancedMenu" :pt="{ submenu: { style: 'top: 40px !important; '}}">
    <template #item="{ item, props, hasSubmenu, root }">
      <a v-ripple class="flex align-items-center" v-bind="props.action">
        <span
          :class="
            item.key === 'toggleAuswahl'
              ? showItemSelectable
                ? 'pi pi-check'
                : ''
              : item.icon
          " />
        <span class="ml-2 w-auto">
          {{
            item.key === "toggleAuswahl"
              ? showItemSelectable
                ? "Auswählen deaktivieren"
                : "Auswählen aktivieren"
              : item.key === "toggleCatalogue"
              ? showItemSelectable
                ? "Katalog erstellen (Selektierte Artikel)"
                : "Katalog erstellen (Alle Artikel)"
              : item.label
          }}
        </span>
        <Badge
          v-if="item.badge"
          :class="{ 'ml-auto': !root, 'ml-2': root }"
          :value="item.badge" />
        <span
          v-if="item.shortcut"
          class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
          >{{ item.shortcut }}</span
        >
        <i
          v-if="hasSubmenu"
          :class="[
            'pi pi-angle-down',
            { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
          ]"></i>
      </a>
    </template>
  </Menubar>
  <div class="card p-fluid">
    <Loading v-show="loading" />
    <Tip
      v-if="lazyLoading == false && items.length > 0"
      :target="'#editCustomer'"
      :tipId="'editItemTip'"
      :placement="'right'"
      :overlay="false" />
    <div class="w-full">
      <MultiSelect
        v-show="showAdvanced"
        :modelValue="selectedColumns"
        :options="kundenNummer ? columnsCustomer : columns"
        optionLabel="header"
        @update:modelValue="onToggle"
        :class="dtClass"
        display="chip"
        placeholder="Spalten Ansicht wählen"
        class="w-auto" />
    </div>
    <DataTable
      ref="dataTableRef"
      v-model:filters="filters"
      v-model:selection="selectedItems"
      :value="items"
      editMode="row"
      :class="dtClass"
      scrollable
      scrollHeight="85vh"
      :filterDisplay="showAdvanced ? 'row' : ''"
      tableStyle="min-width: 10%"
      resizableColumns
      columnResizeMode="fit"
      stripedRows
      selectionMode="row"
      dataKey="item_id_company"
      sortField="created_us"
      @sort="onSort"
      :sortOrder="-1"
      class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick"
      :virtualScrollerOptions="{
        lazy: false,
        itemSize: 46,
        delay: 100,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100,
      }">
      <Column
        v-if="showAdvancedMenu"
        :headerStyle="
          showAdvancedMenu ? 'width: 2rem; text-align: center' : 'display: none'
        "
        :bodyStyle="
          showAdvancedMenu
            ? 'text-align: center; overflow: visible'
            : 'display: none'
        "
        style="width: 1%">
        <template #header>
          <Button
            type="button"
            v-show="showAdvanced"
            outlined
            text
            size="small"
            icon="pi pi-plus"
            :class="dtClass"
            rounded
            @click="handleNewItem" />
        </template>
        <template #body="{ data }">
          <Button
            v-show="showAdvanced"
            type="button"
            id="editCustomer"
            outlined
            text
            size="small"
            icon="pi pi-file-edit"
            :class="dtClass"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            rounded
            @click="popUptoggle($event, data)" />
          <Menu
            ref="menu"
            id="overlay_menu"
            :class="dtClass"
            :model="popUpitems"
            :popup="true" />
        </template>
      </Column>

      <Column
        selectionMode="multiple"
        headerStyle="width: 0.1%"
        :class="!showItemSelectable?'hidden':''"
        field="event">
        <template #body="{ data }">
          <Checkbox
          :class="!showItemSelectable?'hidden':''"
            v-model="selectedItems"
            :value="data" />
        </template>
      </Column>

      <Column
        class=""
        v-for="col of selectedColumns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="showAdvanced ? col.header : ''"
        :showFilterMenu="false"
        :style="'width:' + col.colWidth"
        sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <div
            v-if="
              col.field === 'created_de' ||
              (col.field === 'updated_de' && col.field !== 'itemActive')
            ">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <div v-if="col.field === 'itemActive' || col.field === 'isDeposit' ">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="[
                  { label: 'Ja', value: true },
                  { label: 'Nein', value: false },
                ]"
                optionLabel="label"
                optionValue="value"
                placeholder="Auswählen"
                class="p-column-filter"
                style="min-width: 10%"
                :showClear="true" />
            </div>
            <div v-else>
              <InputText
                v-model="filterModel.value"
                v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
                type="text"
                @keydown.enter="filterCallback()"
                @input="filterModel.value || filterCallback()"
                class="p-column-filter" />
            </div>
          </div>
        </template>
        <template #body="{ data, field }">
          <span v-if="field == 'itemOutPrice'">
            {{ data[field].toFixed(2) }} €
          </span>
          <span v-else>
            <span v-if="field.includes('.')">
              {{ getNestedValue(data, field) }}
            </span>
            <span v-else>
              <span v-if="field === 'itemActive'">
                {{ data[field] ? "Ja" : "Nein" }}
              </span>
              <span v-else>
                <template v-if="field === 'isDeposit'">
                  {{ data[field] ? "Ja" : "Nein" }}
                </template>
                <template v-else>
                  {{ data[field] }}
                </template>
              </span>
            </span>
          </span>
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>

      <Column
        headerStyle="width: 1rem; text-align: center"
        footer=""
        bodyStyle="text-align: center; overflow: visible"
        style="width: 1%">
        <template #body="{ data }" v-if="showAdvancedMenu">
          <Button
            type="button"
            v-show="showAdvancedMenu"
            id="deleteBox"
            outlined
            text
            size="small"
            icon="pi pi-trash"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            rounded
            @click="deleteRow(data)"
            severity="danger" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  computed,
  reactive,
  nextTick,
} from "vue";
import Tip from "@/components/Tips/Tip.vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { ItemsService } from "@/service/ItemsService";
import Loading from "@/components/Loading";
import { useDialog } from "primevue/usedialog";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import Skeleton from "primevue/skeleton";
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import download from "downloadjs";
import InputText from "primevue/inputtext";

const StockInputComponent = defineAsyncComponent(() =>
  import("@/views/StockInputComponent.vue")
);
const StockInputFooter = defineAsyncComponent(() =>
  import("@/views/StockInputFooter.vue")
);
const StockInputHeader = defineAsyncComponent(() =>
  import("@/views/StockInputHeader.vue")
);

const StockInputView = defineAsyncComponent(() =>
  import("@/views/StockInputView.vue")
);

const CategoryDialog = defineAsyncComponent(() =>
  import("@/views/CategoryDialog.vue")
);
const CategoryDialogFooter = defineAsyncComponent(() =>
  import("@/views/CategoryDialogFooter.vue")
);

const ItemInsertComponent = defineAsyncComponent(() =>
  import("@/views/ItemInsertComponent.vue")
);
const ItemsEditHeader = defineAsyncComponent(() =>
  import("@/views/ItemsEditHeader.vue")
);

const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/ItemsEditFooter.vue")
);

const CatalogueDialog = defineAsyncComponent(() =>
  import("@/views/CatalogueDialog.vue")
);
const CatalogueDialogFooter = defineAsyncComponent(() =>
  import("@/views/CatalogueDialogFooter.vue")
);
const CatalogueDialogHeader = defineAsyncComponent(() =>
  import("@/views/CatalogueDialogHeader.vue")
);

const ReassignLinkedItemComponent = defineAsyncComponent(() =>
  import("@/components/ReassignLinkedItemComponent.vue")
);

FilterService.register("dateFilter", dateFilter);

const { dtClass, kundenNummer, showAdvanced, showAdvancedMenu } = defineProps({
  dtClass: {
    type: String,
    required: false,
    default: "",
  },
  kundenNummer: {
    type: String,
    required: false,
    default: "",
  },
  showAdvanced: {
    type: Boolean,
    required: false,
    default: true,
  },
  showAdvancedMenu: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const router = useRouter();
const toast = useToast();
const dataTableRef = ref(null);
const dialog = useDialog();
const store = userStore();
const isMobile = ref(false);
const loading = ref(true);
const lazyLoading = ref(false);
const loadLazyTimeout = ref();
const items = ref([]);
const virtualItems = ref([]);
const confirm = useConfirm();
const selectedItems = ref([]);
const message = ref({});

const columns = ref([
  /*   { field: "customerId", header: "Kunden ID" }, */
  { field: "artikelNr", header: "Artikelnr", colWidth: "10%" },
  { field: "EAN", header: "EAN", colWidth: "10%" },
  { field: "itemName", header: "Artikelname", colWidth: "20%" },
  { field: "itemOutPrice", header: "Verkaufspreis", colWidth: "10%" },
  { field: "category.path", header: "Kategorie", colWidth: "10%" },
  { field: "itemInPrice", header: "Einkaufspreis", colWidth: "10%" },
  { field: "created_de", header: "Erstelldatum", colWidth: "10%" },
  { field: "updated_de", header: "Zuletzt Geändert", colWidth: "10%" },
  { field: "createdUser", header: "Erstellt Benutzer", colWidth: "10%" },
  { field: "updatedUser", header: "Geändert Benutzer", colWidth: "10%" },
  { field: "itemActive", header: "Öffentlich", colWidth: "10%" },
  { field: "isDeposit", header: "Pfand", colWidth: "10%" },
]);

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}

const filters = ref({
  artikelNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
  EAN: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemAlias: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemOutPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemInPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  "category.path": { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  "history.quantity": { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemActive: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  isDeposit: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const startColumns = ref([
  { field: "artikelNr", header: "Artikelnr", colWidth: "10%" },
  { field: "EAN", header: "EAN", colWidth: "10%" },
  { field: "itemName", header: "Artikelname", colWidth: "20%" },
  { field: "itemOutPrice", header: "Verkaufspreis", colWidth: "10%" },
  { field: "category.path", header: "Kategorie", colWidth: "10%" },
  { field: "isDeposit", header: "Pfand", colWidth: "10%" },
]);

const columnsCustomer = ref([
  { field: "artikelNr", header: "Artikelnr", colWidth: "10%" },
  { field: "EAN", header: "EAN", colWidth: "10%" },
  { field: "itemName", header: "Artikelname", colWidth: "20%" },
  { field: "itemAlias", header: "Artikel Alias", colWidth: "20%" },
  { field: "itemOutPrice", header: "Verkaufspreis", colWidth: "10%" },
  { field: "category.path", header: "Kategorie", colWidth: "10%" },
  { field: "created_de", header: "Erstelldatum", colWidth: "10%" },
  { field: "updated_de", header: "Zuletzt Geändert", colWidth: "10%" },
  { field: "createdUser", header: "Erstellt Benutzer", colWidth: "10%" },
  { field: "updatedUser", header: "Geändert Benutzer", colWidth: "10%" },
  { field: "history.quantity", header: "gekaufte Anz", colWidth: "10%" },
  { field: "isDeposit", header: "Pfand", colWidth: "10%" },
]);

const startColumnsCustomer = ref([
  { field: "artikelNr", header: "Artikelnr", colWidth: "10%" },
  { field: "EAN", header: "EAN", colWidth: "10%" },
  { field: "itemName", header: "Artikelname", colWidth: "20%" },
  { field: "history.quantity", header: "gekaufte Anz", colWidth: "10%" },
]);

const selectedColumns = kundenNummer
  ? ref(startColumnsCustomer.value)
  : ref(startColumns.value);
const onToggle = (val) => {
  const cols = kundenNummer ? columnsCustomer : columns;
  const valFields = val.map((v) => v.field);
  cols.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};

const popUpLabel = reactive({ label: "" });
let rowData = null;

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.itemName;
  menu.value?.toggle(event);
};
const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Ansehen/Bearbeiten",
        icon: "pi pi-file-edit",
        command: () => {
          handleRowDoubleClick({ data: rowData });
        },
      },
      {
        label: "Artike Duplizieren / Kopieren",
        icon: "pi pi-copy",
        command: () => {
          handleNewItem({}, JSON.parse(JSON.stringify(rowData)), true);
        },
      },
    ],
  },
]);

function getNestedValue(obj, path) {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

const onSort = (event) => {
  console.log("🚀 ~ file: ItemsView.vue:433 ~ event:", event);

  const { sortField, sortOrder } = event;

  items.value = [...items.value].sort((a, b) => {
    let valueA = a[sortField] || "";
    let valueB = b[sortField] || "";

        // Null oder undefined Werte am Ende sortieren
    if (valueA == null) return 1;
    if (valueB == null) return -1;

    if (typeof valueA === "string" && typeof valueB === "string") {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * sortOrder;
    }

    if (valueA < valueB) return -1 * sortOrder;
    if (valueA > valueB) return 1 * sortOrder;
    return 0;
  });
  console.log("items.value:", items.value);
};

const deleteRow = (data) => {
  let linkedItemMap = [];
  let linkedVPEItemMap = [];
  let itemIdToItem = [];
  items.value.forEach((o) => {
    itemIdToItem[o.item_id_company] = o;

    if (o.itemParameters?.linkedItem?.item_id_company) {
      if (o.itemParameters.linkedItem.item_id_company == data.item_id_company) {
        let linkedItemMapEntry =
          linkedItemMap[o.itemParameters.linkedItem.item_id_company];
        if (linkedItemMapEntry) {
          linkedItemMapEntry.push(o.item_id_company);
        } else {
          linkedItemMap[o.itemParameters.linkedItem.item_id_company] = [
            o.item_id_company,
          ];
        }
      }
    }
    if (o.VPE) {
      o.VPE.list.forEach((vpeObj) => {
        if (vpeObj.linkedItem) {
          if (vpeObj.linkedItem.item_id_company !== data.item_id_company) {
            return;
          }
          let vpeLinkedItemEntry =
            linkedVPEItemMap[vpeObj.linkedItem.item_id_company];
          if (vpeLinkedItemEntry) {
            vpeLinkedItemEntry.push(o.item_id_company);
          } else {
            linkedVPEItemMap[vpeObj.linkedItem.item_id_company] = [
              o.item_id_company,
            ];
          }
        }
      });
    }
  });

  if (linkedItemMap.length || linkedVPEItemMap.length) {
    const dialogRef = dialog.open(ReassignLinkedItemComponent, {
      props: {
        header: "Verlinkungen Bearbeiten",
        modal: true,
        closable: false,
        style: {
          width: "55vw",
        },
        breakpoints: {
          "1200px": "75vw",
          "640px": "100vw",
        },
      },
      data: {
        item: data,
        linkedVPEItemMap: linkedVPEItemMap,
        linkedItemMap: linkedItemMap,
        itemIdToItem: itemIdToItem,
      },
      onClose: async (options) => {
        if (options.data.type === "success") {
          let msg =
            "Möchten sie den Artikel wirklich löschen? Dieser vorgang kann nicht wieder rückgängig gemacht werden.";
          confirm.require({
            message: msg,
            header: "Artikel Löschen?",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-danger",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            defaultFocus: "reject",
            accept: () => {
              forceDelete(data);
            },
            reject: () => {
              return false;
            },
          });
        }
      },
    });
  } else {
    let msg =
      "Möchten sie den Artikel wirklich löschen? Dieser vorgang kann nicht wieder rückgängig gemacht werden.";
    confirm.require({
      message: msg,
      header: "Artikel Löschen?",
      icon: "pi pi-info-circle",
      acceptClass: "p-button-danger",
      acceptLabel: "Ja",
      rejectLabel: "Nein",
      defaultFocus: "reject",
      accept: () => {
        forceDelete(data);
      },
      reject: () => {
        return false;
      },
    });
  }
};

const forceDelete = async (data) => {
  const itemID = data.item_id_company;

  let url = process.env.VUE_APP_BASE_API_URL + "/products/item/" + itemID;
  await iAxios.delete(url);

  let index = items.value.indexOf(data);
  let virtualIndex = virtualItems.value.indexOf(data);

  items.value.splice(index, 1);
  virtualItems.value.splice(virtualIndex, 1);
  console.log(data);
};

const handleRowDoubleClick = async (event) => {
  console.log("🚀 ~ file: ItemsView.vue:336 ~ event:", event);

  if (event.data.item_id_company !== null)
    ItemsService.getItem(event.data.item_id_company).then((data) => {
      //aktuallisiere die Daten des Artikels in items
      const index = items.value.findIndex(
        (item) => item.item_id_company == data.item_id_company
      );
      data.artikelNr = data.itemCustomId
        ? data.itemCustomId
        : data.item_id_company;
      items.value[index] = data;
      //aktuallisiere die Daten des Artikels in virtualItems
      // const virtualIndex = virtualItems.value.findIndex((item) => item.item_id_company == data.item_id_company);
      // virtualItems.value[virtualIndex] = data;
    });

  const data = ref(event.data ? event.data : event);

  const originalData = data.value;
  const dialogRef = dialog.open(ItemInsertComponent, {
    props: {
      header: "Artikel Bearbeiten",
      modal: true,
      closable: false,
      style: {
        width: "60vw",
      },
      breakpoints: {
        "1200px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(ItemsEditHeader),
    },
    data: {
      item: data,
      erweitert: store.itemEditSettings.erweitert,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        data.value.itemImageUrl = originalData.itemImageUrl;
        if (buttonType == "Save") {
          save(data.value, originalData);
        } else if (buttonType == "Cancel") {
          Object.assign(data.value, originalData);
        }
      }
    },
  });
};

const handleNewItem = async (event, predefinedItem, isClone) => {
  const newItem = ref({
    itemName: "",
    itemActive: true,
    itemDescription: "",
    // itemImageUrl: "",
    itemInPrice: 0,
    VPE: null,
    itemOutPrice: 0,
    itemTaxId: store.companySettings.defaultTaxId,
    itemTaxValue: store.companySettings.defaultTaxValue,
    itemUnit: "Stk.",
    itemType: "goods",
    itemParameters: {
      weight: 0,
      linkedItem: null,
      forbidSingleSale: false,
      discount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    history: {
      quantity: 0,
      lastPurchase: null,
      lastSale: null,
    },
    category: reactive({ path: "", selectedKey: "", structure: null }),
    stock: 0,
    rabatt: 0,
  });
  if (predefinedItem) {
    Object.assign(newItem.value, predefinedItem);
  }
  if (isClone) {
    delete newItem.value.itemImageUrl;
    newItem.value.item_id_company = null;
  }

  const dialogRef = dialog.open(ItemInsertComponent, {
    props: {
      header: predefinedItem ? "Artikel Duplizieren" : "Artikel Anlegen",
      modal: true,
      closable: false,
      style: {
        width: "60vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    data: {
      item: newItem,
      erweitert: store.itemEditSettings.erweitert,
      isNew: !predefinedItem,
    },
    templates: {
      header: markRaw(ItemsEditHeader),
      footer: markRaw(DefaultFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const isReOpen = options.data.reOpen;

        console.log(
          "🚀 ~ file: ItemsView.vue:445 ~  options.data:",
          options.data
        );

        if (buttonType == "Save") {
          try {
            dialogRef.data.item.value.hasGroup =
              dialogRef.data.item.value.itemGroupList &&
              dialogRef.data.item.value.itemGroupList.length > 0;

            const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
            console.log(JSON.stringify(dialogRef.data.item.value));
            let response = await iAxios
              .post(url, dialogRef.data.item.value)
              .then((response) => {
                const item = reactive(response.data);

                // Erstellen einer Kopie des Arrays, dies ist notwendig, da das Array sonst nicht reaktiv ist wie es scheint. wenn keine Elemente im array sind
                const newItems = [...items.value];
                // Ersetzen des ursprünglichen Array durch die Kopie
                items.value = newItems;
                item.artikelNr = item.itemCustomId
                  ? item.itemCustomId
                  : item.item_id_company;
                items.value.push(item);
                // virtualItems.value.push(item);

                if (store.debug) {
                  console.log(
                    "🚀 ~ file: ItemsView.vue:275 ~ response ~ items:",
                    items
                  );
                  console.log(
                    "🚀 ~ file: ItemsView.vue:273 ~ onClose: ~ response:",
                    response
                  );
                  console.log(
                    "🚀 ~ file: ItemsView.vue:277 ~ response ~ virtualItems:",
                    virtualItems
                  );
                  console.log(
                    "🚀 ~ file: ItemsView.vue:279 ~ response ~  dataTableRef.value:",
                    dataTableRef
                  );
                }
                console.log(
                  "🚀 ~ file: ItemsView.vue:485 ~ isReOpen:",
                  isReOpen
                );

                if (isReOpen) {
                  handleNewItem();
                }
              });
          } catch (error) {
            console.error(error);
            toast.add({
              severity: "error",
              summary: "Fehler",
              detail: "Fehler beim Speichern der Daten",
            });
          }
        }
      }
    },
  });
};

const save = async (item, originalData) => {
  console.log("🚀 ~ file: ItemsView.vue:637 ~ item:", item);

  try {
    const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
    if (store.debug) console.log("item.value: ", item.value);
    await iAxios.patch(url, item);
    await werteLaden();
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Fehler beim Speichern der Daten",
    });
    Object.assign(item, originalData);
  }
};

function isSortable(field) {
  return field === "birthDate" || field === "registered";
}

const refreshReRenderTable = async () => {
  await werteLaden();
  //loadItemsLazy({ first: firstVirtualItemIndex.value, last: (lastVirtualItemIndex.value) + 1 });
};

const lastVirtualItemIndex = ref(0);
const firstVirtualItemIndex = ref(0);

const loadItemsLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuelleItems = [...items.value];
    let { first, last } = event;
    firstVirtualItemIndex.value = first;
    lastVirtualItemIndex.value = last;

    //load data of required page
    const loadedItems = items.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuelleItems, [
      ...[first, last - first],
      ...loadedItems,
    ]);

    virtualItems.value = virtuelleItems;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

async function werteLaden() {
  loading.value = true;
  const blancCategory = reactive({
    path: "",
    selectedKey: "",
    structure: null,
  });
  try {
    await ItemsService.getItemsXLarge().then((data) => {
      items.value = data.map((item) => ({
        ...item,
        category: item.category ? item.category : blancCategory,
        artikelNr: item.itemCustomId ? item.itemCustomId : item.item_id_company,
      }));
    });

    if (kundenNummer) {
      const itemHistory = await iAxios.get(
        "/products/item-history-customer/" + kundenNummer
      );
      items.value = items.value
        .map((itemList) => {
          // Finden von Element in itemHistory
          const historyItem = itemHistory?.data?.find(
            (history) => history.item_id_company === itemList.item_id_company
          );

          return {
            ...itemList,
            // Fügen der quantity history Eigenschaft hinzu, wenn das Element in itemHistory gefunden wurde
            history: historyItem
              ? { quantity: historyItem.data.quantity }
              : null,
          };
        })
        .filter((item) => item.history !== null); // Filtern der Artikel ohne history
    }

    loading.value = false;
  } catch (exception) {
    console.log(exception);
  } finally {
    store.itemsLoaded = false;
    console.log("🚀 ~ file: ItemsView.vue:405 ~ items:", items);

    loading.value = false;
  }
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(async () => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  loading.value = true;
  await werteLaden();
  loading.value = false;
});

const pdfFile = ref();

const openNewInventory = () => {
  const dialogRef = dialog.open(StockInputComponent, {
    props: {
      header: "Wareneingang",
      style: {
        width: "35vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: {
      inventory: {
        readOnly: false,
        storno: false,
        isNew: true,
        isEditable: true,
      },
      rechnungsDaten: event.data,
    },
    templates: {
      footer: markRaw(StockInputFooter),
      header: markRaw(StockInputHeader),
    },
    onClose: async (options) => {
      werteLaden();
    },
  });
};

const openInventoriesList = () => {
  const dialogRef = dialog.open(StockInputView, {
    props: {
      header: "Wareneingang",
      style: {
        width: "35vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: true,
    },
  });
};

const showPublicItems = ref(false);
const showOnlyPublicItems = ref(false);
const showItemSelectable = ref(false);
const menuItems = ref([
  {
    label: "Wareneingang",
    icon: "pi pi-sign-in",
    items: [
      {
        label: "Neuen Wareneingang erstellen",
        icon: "pi pi-bolt",
        command: openNewInventory,
      },
      {
        label: "Wareneingänge Anzeigen",
        icon: "pi pi-bolt",
        command: openInventoriesList,
      },
    ],
  },
  {
    label: "Inventur",
    icon: "pi pi-barcode",
    command: () => {
      router.push("/inventurScanner");
      //  openGenericInventory();
    },
  },
  {
    label: "Kategorien",
    icon: "pi pi-th-large",
    command: () => {
      openCategoryDialog();
    },
  },
  {
    label: "Artikel PDF-Katalog",
    icon: "pi pi-file-pdf",
    items: [
      {
        key: "toggleAuswahl",
        label: "Auswählen aktivieren",
        icon: "",
        command: () => {
/*           selectedItems.value = showItemSelectable.value
            ? (selectedItems.value = [])
            : items.value; */
          showItemSelectable.value = !showItemSelectable.value;
        },
      },
      {
        key: "toggleCatalogue",
        label: "Katalog erstellen",
        icon: "pi pi-bolt",
        command: () => {
          openPDFCatalog();
        },
      },
    ],
  },
]);

const openPDFCatalog = () => {
  const dialogRef = dialog.open(CatalogueDialog, {
    props: {
      header: showItemSelectable.value
        ? "(Selektierte Artikel)"
        : "(Alle Artikel)",
      style: {
        width: "30vw",
      },
      breakpoints: {
        "831px": "100vw",
      },
      modal: true,
      closable: true,
    },
    data: {
      catalogueSettings: {
        publicItems: false,
        showVK: true,
        showImage: true,
        showCover: true,
        showBackground: true,
        showLogo: true,
        showAddress: true,
        Titel: "Katalog",
        subTitel: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      },
    },
    templates: {
      footer: markRaw(CatalogueDialogFooter),
      header: markRaw(CatalogueDialogHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Generate") {
          generateCatalogue(options.data.settings);
        }
      }
    },
  });
};

const generateCatalogueItemList = (settings) => {
  const itemsForList = showItemSelectable.value ? selectedItems : items;
  const catalogueItems = [];
  for (let i = 0; i < itemsForList.value.length; i++) {
    const item = itemsForList.value[i];
    let vk = item.itemOutPrice;
    if (item[settings.selectedVKStufe]) {
      vk = item[settings.selectedVKStufe];
    }
    if (!settings.publicItems && !item.itemActive) {
    } else
      catalogueItems.push({
        fileHash: item.itemImageUrl?.[0]?.fileHash,
        EAN: item.EAN,
        itemCustomId: item.itemCustomId??item.item_id_company,
        item_id_company: item.item_id_company,
        itemName: item.itemName,
        VK: vk,
        UVP: item.UVP,
      });
  }
  if (store.debug) {
    console.log(
      "🚀 ~ file: ItemsView.vue:920 ~ catalogueItems:",
      catalogueItems
    );
  }
  return catalogueItems;
};

const generateCatalogue = (settings) => {
  //seleteditems muss noch deklariert und dann noch angepasst werden
  const body = {};
  delete settings.selectedVKStufe;
  delete settings.publicItems;
  body.settings = settings;
  body.itemList = generateCatalogueItemList(settings);

  console.log("🚀 ~ file: ItemsView.vue:933 ~ body:", body);

  iAxios
    .post("/company/shipping-catalogue", body, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((response) => {
      download(response.data, "Katalog.pdf", "application/pdf");
      console.log(
        "🚀 ~ file: ItemsView.vue:405 ~ response.value:",
        response.data
      );
    });
};

const openCategoryDialog = () => {
  dialog.open(CategoryDialog, {
    props: {
      header: "Kategorien bearbeiten",
      modal: true,
      closable: false,
      style: {
        width: "75vw",
      },
      breakpoints: {
        "3600px": "45vw",
        "1470px": "70vw",
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    data: {
      categories: [],
      fromItem: false,
    },
    templates: {
      footer: markRaw(CategoryDialogFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          console.log(
            "🚀 ~ file: ItemsView.vue:183 ~ onClose: ~ options.data:",
            options.data
          );
        }
      }
    },
  });
};
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
