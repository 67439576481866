<template>
  <div class="card p-fluid">
    <Loading v-show="loading" />

    <MultiSelect
      :modelValue="isMobile ? selectedColumns : columns"
      :options="columns"
      optionLabel="header"
      @update:modelValue="onToggle"
      display="chip"
      placeholder="Spalten Ansicht wählen"
      class="w-full" />
    <DataTable
      v-model:filters="filters"
      v-model:expandedRowGroups="expandedRowGroups"
      expandableRowGroups
      rowGroupMode="subheader"
      groupRowsBy="inventoryProcessId"
      @rowgroup-expand="onRowGroupExpand"
      @rowgroup-collapse="onRowGroupCollapse"
      sortMode="single"
      :value="inventories"
      editMode="row"
      scrollable
      scrollHeight="85vh"
      filterDisplay="row"
      tableStyle="min-width: 10%"
      resizableColumns
      columnResizeMode="fit"
      stripedRows
      selectionMode="single"
      dataKey="inventoryProcessId"
      sortField="invProStart"
      :sortOrder="-1"
      class="p-datatable-sm"
      @row-dblclick="openReadOnlyInvoice"
      :virtualScrollerOptions="{
        lazy: false,
        onLazyLoad: loadCustomersLazy,
        itemSize: 46,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100,
      }"
      ref="dataTableRef">
      <template #groupheader="slotProps">
        <span class="vertical-align-middle ml-2 font-bold line-height-3">
          {{ formatTime(slotProps.data.invProStart) }}
          <Tag
            :value="getActionLabel(slotProps.data.invProStatus)"
            :severity="getInventorySeverity(slotProps.data.invProStatus)"
            rounded />
          <Button
            v-if="groupExpandedStatus[slotProps.data.inventoryProcessId]"
            size="small"
            class="text-xs w-14rem"
            text
            label="Restliche Artikel Anzeigen"
            icon="pi pi-eye"
            @click="openInvDocDetails(slotProps.data,true)" />
        </span>
      </template>

      <Column
        headerStyle="width: 2rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button
            type="button"
            :pt="{ icon: { class: 'text-xl' } }"
            text
            icon="pi pi-eye"
            rounded
            @click="openInvDocDetails( data )" />
        </template>
      </Column>

      <Column
        v-for="col of isMobile ? selectedColumns : columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="col.header"
        :showFilterMenu="false"
        sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <span v-if="col.field == 'invProStatus'">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="statuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Auswählen"
              class="p-column-filter"
              style="min-width: 10%"
              :showClear="true">
              <template #option="slotProps">
                <Tag
                  :value="slotProps.option.label"
                  :class="slotProps.option.label"
                  :severity="getInventorySeverity(slotProps.option.value)" />
              </template>
            </Dropdown>
          </span>
          <span v-else>
            <div v-if="col.field === 'created_de' || col.field === 'updated_de'">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              class="p-column-filter" />
          </div>
          </span>
        </template>
        <template #body="{ data, field }">
          <span v-if="field == 'invProStatus'">
            <Tag
              :value="getActionLabel(data[field])"
              :severity="getInventorySeverity(data[field])"
              rounded />
          </span>
          <span v-else>
            <span v-if="field == 'invProStart'">
              {{ formatTime(data[field]) }}
            </span>
            <span v-else>
              {{ data[field] }}
            </span>
          </span>
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  reactive,
  computed,
} from "vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import Loading from "@/components/Loading";
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import { saveAndGetPDF, setDocumentStatus } from "@/utils/invoiceUtils";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";

const InventurDocDetailView = defineAsyncComponent(() =>
  import("@/views/InventurDocDetailView.vue")
);
const StockInputFooter = defineAsyncComponent(() =>
  import("@/views/StockInputFooter.vue")
);
const InventurDocDetailViewHeader = defineAsyncComponent(() =>
  import("@/views/InventurDocDetailViewHeader.vue")
);
FilterService.register("dateFilter", dateFilter);

const router = useRouter();
const toast = useToast();

const expandedRowGroups = ref();
const groupExpandedStatus = reactive({});

defineExpose({
  werteLaden
})

const onRowGroupExpand = (event) => {
  groupExpandedStatus[event.data] = true;
};

const onRowGroupCollapse = (event) => {
  groupExpandedStatus[event.data] = false;
};

const columns = ref([
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "inventoryAnnotation", header: "Bezeichnung" },
  { field: "docContent.notes", header: "Bemerkung" },
]);

const startColumns = ref([
  { field: "createdUser", header: "Benutzer" },
  { field: "created_de", header: "Erstelldatum" },
]);
const selectedColumns = ref(startColumns.value);
const onToggle = (val) => {
  const valFields = val.map(v => v.field);
  columns.value.forEach((col) => {
    if (valFields.includes(col.field) && !selectedColumns.value.some(selectedCol => selectedCol.field === col.field)) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter((selectedCol) => selectedCol.field !== col.field);
    }
  });
};

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}


const filters = ref({
  created_de: { value: null, matchMode: "dateFilter", filterFunction: dateFilter, dateRange: [null, null],},
  updated_de: { value: null, matchMode: "dateFilter", filterFunction: dateFilter, dateRange: [null, null],},
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  inventoryAnnotation: { value: null, matchMode: FilterMatchMode.CONTAINS },
  "docContent.notes": { value: null, matchMode: FilterMatchMode.CONTAINS },
});

function isSortable(field) {
  return field === "updated" || field === "created";
}
const isMobile = ref(false);
const loading = ref(true);

const lazyLoading = ref(false);
const loadLazyTimeout = ref();

const inventories = ref([]);
const dialog = useDialog();
let rowData = null;
const dataTableRef = ref();
const popUpLabel = reactive({ label: "" });

const statuses = reactive([
  { label: "Aktiv", value: "ACTIVE" },
  { label: "Abgeschlossen", value: "DONE" },
  { label: "Abgebrochen", value: "CANCELED" },
]);

const pdfFile = ref();
const openInvDocDetails = async (event,isRestItems) => {

  console.log("🚀 ~ file: InventurView.vue:233 ~ event:", event)

  
  const dialogRef = dialog.open(InventurDocDetailView, {
    props: {
      header: "Ansicht Inventur Details",
      style: {
        width: "35vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: isRestItems?await restItemsData(event.inventoryProcessId):event,isRestItems,
    templates: {
      header: markRaw(InventurDocDetailViewHeader),
    },
  });
};

const restItemsData = async (inventoryProcessId) => {
  const inventoryDocList = await iAxios
    .get("/company/inventory-process-details/"+inventoryProcessId)
    .then((response) => {

      console.log("🚀 ~ file: InventurView.vue:270 ~ response:", response)

      return Object.values(response.data.inventoryProcess.untouchedItemsOfCompany) || [];
    });
    console.log("🚀 ~ file: InventurView.vue:278 ~ {itemList:inventoryDocList}:", {itemList:inventoryDocList})

  return  {itemList:inventoryDocList};
};



const getInventorySeverity = (status) => {
  switch (status) {
    case "ACTIVE":
      return "info";

    case "DONE":
      return "success";

    case "CANCELED":
      return "danger";
  }
  return "info";
};

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const formatTime = (value) => {
  const date = new Date(value);
  return (
    date.toLocaleDateString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    }) + " Uhr"
  );
};
async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
}

const holeInventoryCombiList = async () => {
  const inventoryHistory = await iAxios
    .get("/company/inventory-process-history")
    .then((response) => {

      console.log("🚀 ~ file: InventurView.vue:330 ~ response:", response)
      if (!response.data.inventoryProcessesHistory) {
        return [];
      }

      return Object.values(response.data.inventoryProcessesHistory);
    });

  const invPro = await iAxios
    .get("/company/inventory-doc-list")
    .then((response) => {

      console.log("🚀 ~ file: InventurView.vue:336 ~ response:", response)

      return response.data.inventories || [];
    });

  console.log("🚀 ~ file: InventurView.vue:616 ~ invPro:", invPro);

  /*  
steht nur als kommentar hier wenn 
const inventorydocList = await iAxios.get("/company/inventory-doc-list"
  ).then((response) => {
    return Object.values(response.data.inventories) || [];;
  });

  for (let key in inventoryHistory) {
    inventoryHistory[key].inventoryDocs = inventorydocList[key];
  } */
  inventories.value = invPro;

  console.log(
    "🚀 ~ file: InventurView.vue:714 ~ inventoryHistory:",
    inventoryHistory
  );
};

async function werteLaden() {
  loading.value = true;

  await holeInventoryCombiList();
  loading.value = false;
}
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});

function closePreview() {
  pdfFile.value = null;
}
</script>
