
import { ref } from "vue";
export const timeSelect = (zeitAuswahl,timeFrom,timeTo,label) => {
    label.value = zeitAuswahl;
    switch (zeitAuswahl){
      case "Gesamt":
        timeFrom.value = null;
        timeTo.value = null;
        break;
      case "7 Tage":
        timeFrom.value = new Date(new Date().setDate(new Date().getDate() - 7));
        timeTo.value = new Date();
        break;
      case "14 Tage":
        timeFrom.value = new Date(new Date().setDate(new Date().getDate() - 14));
        timeTo.value = new Date();
        break;
      case "30 Tage":
        timeFrom.value = new Date(new Date().setDate(new Date().getDate() - 30));
        timeTo.value = new Date();
        break;
      case "1 Monat":
        timeFrom.value = new Date(new Date().setMonth(new Date().getMonth() - 1));
        timeTo.value = new Date();
        break;
      case "3 Monate":
        timeFrom.value = new Date(new Date().setMonth(new Date().getMonth() - 3));
        timeTo.value = new Date();
        break;
      case "6 Monate":
        timeFrom.value = new Date(new Date().setMonth(new Date().getMonth() - 6));
        timeTo.value = new Date();
        break;
      case "9 Monate":
        timeFrom.value = new Date(new Date().setMonth(new Date().getMonth() - 9));
        timeTo.value = new Date();
        break;
      case "1 Jahr":
        timeFrom.value = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        timeTo.value = new Date();
        break;
      case "3 Jahre":
        timeFrom.value = new Date(new Date().setFullYear(new Date().getFullYear() - 3));
        timeTo.value = new Date();
        break;
      case "5 Jahre":
        timeFrom.value = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
        timeTo.value = new Date();
        break;
    }
  };

