import iAxios from "@/store/axiosInterface";

export const setDocumentStatus = async (orderId, status) => {
  let url = process.env.VUE_APP_BASE_API_URL + "/company/order/" + orderId;
  let response = await iAxios.post(url, { documentStatus: status });
  return response;
}

export const OrdersService = {
  async getData() {
    try {
      let url = process.env.VUE_APP_BASE_API_URL + "/company/order-list";
      let response = await iAxios.get(url, {});
      return response.data;
    } catch (exception) {
      console.log(exception);
    }
    return null;
  },

  async getOrders() {
    return Promise.resolve(this.getData());
  },

};
