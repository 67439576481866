<template>
  <div class="card">
    <Loading v-show="loading" />
    <PickList
      v-model="pickListProducts"
      listStyle="height:100%; max-height:75vh"
      dataKey="item_id_company"
      breakpoint="1300px"
      stripedRows
      @move-to-source="onMoveToSource"
      @move-to-target="onMoveToTarget"
      @move-all-to-source="onMoveAllToSource"
      @move-all-to-target="onMoveAllToTarget"
      :pt="{
        sourceHeader: {
          class: 'py-0 h-3rem',
          style: { top: '4px!important', position: 'relative' },
        },
        targetHeader: {
          class: 'py-0 h-3rem',
          style: { top: '12px!important', position: 'relative' },
        },
      }">
      <template #sourceheader>
        <div>
          Katalog
          <InputText
            v-model="filter"
            v-tooltip="'Suche nach Artikelname oder EAN'"
            class="text-xs w-7 ml-3"
            placeholder="Suche..." />
            <Button :disabled="!dialogRef?.data?.customer" severity="info" icon="pi pi-info" label="Kein Kunde" class="text-xs" v-if="!dialogRef?.data?.customer" />
            <Button text :label="showPurchased?'Alle':'Nur erworbene'" class="text-xs" :class="dialogRef?.data?.customer?'':'hidden'" :icon="showPurchased?'pi pi-eye-slash':'pi pi-eye'" v-tooltip="'Nur Artikel anzeigen, die bereits vom Kunden gekauft wurden'" @click="toggleShowPurchased" />
        </div>
      </template>
      <template #targetheader> Ausgewählt </template>
      <template #item="slotProps">
        <div
          v-if="isTarget(slotProps.item) && slotProps.item?.linkedVPEItem || slotProps.item?.linkedItem"
          :class="[
      'p-0 m-0 text-xs',
      { 'p-disabled': slotProps.item?.linkedVPEItem || slotProps.item?.linkedItem }
    ]"
          class="p-0 m-0 text-xs">
          <span class="flex justify-content-between"
            ><span
              >{{ slotProps.item.quantity }} x
              {{ slotProps.item.itemName }}</span
            ><span>{{ slotProps.item.itemOutPrice.toFixed(2) }}€</span></span
          >
        </div>
        <div
          v-else
          class="flex flex-wrap py-0 my-0 px-1 align-items-center gap-3 text-xs"
          :class="{ 'selected-item': slotProps.item.selected }"
          @click="slotProps.item.selected = !slotProps.item.selected">
          <img
            class="w-4rem max-h-4rem flex-shrink-0 border-round"
            :src="
              slotProps?.item.itemImageUrl?.[0]?.fileUrl.replace('/auth', '') ??
              require('@/assets/no-image.jpg')
            "
            :alt="slotProps?.item.itemName"
            style="object-fit: contain" />
          <InputNumber
            v-model="slotProps.item.quantity"
            inputClass="w-4rem text-xs"
            showButtons
            @click="$event.stopPropagation()"
            @mousedown="$event.stopPropagation()"
            @dblclick="$event.stopPropagation()" />

          <div
            class="col-2 p-0 m-0 relative"
            style="top: -6px"
            v-if="slotProps.item?.VPE?.list?.length > 0">
            <label for="Verkaufpreis" class="font-light text-xs">VPE</label>
            <InputGroup class="text-xs p-0 m-0">
              <InputGroupAddon class="text-xs text-center p-0 m-0 min-w-min">
                <Checkbox
                  v-model="slotProps.item.isVPE"
                  :disabled="
                    slotProps.item?.itemParameters?.forbidSingleSale
                  "
                  class="p-0 m-0 text-xs"
                  inputId="isVPE"
                  binary
                  @update:modelValue="
                    slotProps.item.isVPE
                      ? (slotProps.item.VPE_QTY =
                          slotProps.item.VPE.list[0].VPE_QTY)
                      : null
                  "
                  @click="$event.stopPropagation()"
                  @mousedown="$event.stopPropagation()"
                  @dblclick="$event.stopPropagation()" />
              </InputGroupAddon>
              <Dropdown
                v-if="slotProps.item.isVPE && slotProps.item?.VPE?.list"
                v-model="slotProps.item.VPE_QTY"
                inputClass="text-xs text-center"
                optionLabel="VPE_QTY"
                optionValue="VPE_QTY"
                :options="slotProps.item.VPE.list"
                class="w-full"
                :class="{ invalid: validated && !slotProps.item.VPE }"
                required
                @change="chooseVPE($event, slotProps.item)"
                :pt="{ trigger: { class: 'max-w-0 pr-2' } }"
                @click="$event.stopPropagation()"
                @mousedown="$event.stopPropagation()"
                @dblclick="$event.stopPropagation()">
                <template #dropdownicon>
                  <span class="pi pi-chevron-down text-xs"></span>
                </template>
              </Dropdown>
              <InputNumber
                v-if="
                  slotProps.item.isVPE &&
                  !(slotProps.item?.VPE?.list?.length > 0)
                "
                v-model="slotProps.item.VPE_QTY"
                inputId="pos-VPE_QTY"
                :inputProps="{ inputmode: 'numeric' }"
                inputClass="text-xs text-right w-full px-1 md:px-2"
                :class="{ invalid: validated && !slotProps.item.VPE_QTY }"
                required
                @update:modelValue="checkStockQuantityField(slotProps.item)"
                @click="$event.stopPropagation()"
                @mousedown="$event.stopPropagation()"
                @dblclick="$event.stopPropagation()" />
            </InputGroup>
          </div>
          <div v-else class="col-2 p-0 m-0 relative" style="top: -6px"></div>
          <!--die drei sind notwendig um zu verhindern das beim bedienen des mengenfeldes ein event an das ober geordnete führt-->
          <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold text-xs">{{
              slotProps?.item.itemName
            }}</span>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-tag text-sm"></i>
              <span class="bg-purple-100">EAN: {{ slotProps.item.EAN }}</span
              ><span class="bg-yellow-100"
                >Bestand: {{ slotProps?.item.stock }}</span
              ><span class="dancing-script-font">{{
                slotProps?.item.itemUnit
              }}</span>
            <span v-if="slotProps?.item?.history" v-tooltip="'bereits vom Kunden gekaufte Anzahl'" class="bg-green-300">gekauft: {{ slotProps.item.history.quantity }}</span>  
            </div>
          </div>
          <span class="font-bold"
            >{{ slotProps.item.itemOutPrice.toFixed(2) }}€</span
          >
        </div>
      </template>
    </PickList>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, reactive, computed, inject } from "vue";
import { userStore } from "@/store/userStore";
import { ItemsService } from "@/service/ItemsService";
import Loading from "@/components/Loading";
import iAxios from "@/store/axiosInterface";
const store = userStore();
const menge = ref();
const products = ref(null);
let items = [];
const loading = ref(false);
const filter = ref("");
const dialogRef = inject("dialogRef");
const selectionDone = dialogRef.value.data.selectionDone;
const selectionVPEDone = dialogRef.value.data.selectionVPEDone;
const checkStockQuantityField = dialogRef.value.data.checkStockQuantityField;
const deleteLinkedItems = dialogRef.value.data.deleteLinkedItems;
const sourceProducts = ref([]);

let showPurchased = ref(false);

const filteredSourceProducts = computed(() => {
  let filteredProducts = sourceProducts.value;

  if (filter.value && filteredProducts) {
    filteredProducts = filteredProducts.filter(
      (product) =>
        product.itemName?.toLowerCase().includes(filter.value.toLowerCase()) ||
        product.EAN?.toLowerCase().includes(filter.value.toLowerCase())
    );
  }

  if (showPurchased.value && filteredProducts) {
    filteredProducts = filteredProducts.filter(
      (product) => {
        return product.history?.quantity > 0
      }
    );
  }

  return filteredProducts;
});

// Funktion zum Umschalten des showPurchased Status
function toggleShowPurchased() {
  showPurchased.value = !showPurchased.value;
}


const pickListProducts = computed(() => [
  filteredSourceProducts.value,
  dialogRef.value.data.docData.itemList,
]);

const itemListShema = {
  posNr: 1,
  quantity: 1,
  itemTaxValueFloat: store.companySettings.defaultTaxValue / 100,
  VkPreisBrutto: null,
  summe: 0,
  selectedItem: null,
};

let itemsLoaded = false;
async function werteLaden() {
  loading.value = true;
  const blancCategory = reactive({
    path: "",
    selectedKey: "",
    structure: null,
  });
  try {
    await ItemsService.getItemsXLarge().then((data) => {
      items = data.map((item) => ({
        ...item,
        category: item.category ? item.category : blancCategory,
        isVPE: item?.itemParameters?.forbidSingleSale,
        VPE_QTY: item?.itemParameters?.forbidSingleSale
          ? item.VPE.list[0].VPE_QTY
          : null,
        ...itemListShema,
      }));
    });


    const customer=dialogRef.value.data.customer;

    if (customer) {
    const itemHistory = await iAxios.get("/products/item-history-customer/" + customer.kundenNummer);
    items = items.map((itemList) => {
      // Finden von Element in itemHistory
      const historyItem = itemHistory?.data?.find(history => history.item_id_company === itemList.item_id_company);

      return {
        ...itemList,
        // Fügen der quantity history Eigenschaft hinzu, wenn das Element in itemHistory gefunden wurde
        history: historyItem ? { quantity: historyItem.data.quantity } : null,
      };
    });
  }


    loading.value = false;
  } catch (exception) {
    console.log(exception);
  } finally {
    itemsLoaded = true;
    loading.value = false;
  }
}

const isSource = (item) => {
  // Überprüfe, ob das Element im Source-Bereich ist
  return pickListProducts.value[0].includes(item);
};

const chooseVPE = (event, item) => {
  console.log("🚀 ~ file: ItemAuswahlListe.vue:217 ~ event:", event);

  console.log("VPE-Change");
  console.log(pickListProducts.value);

  if (isTarget(item)) {
    selectionVPEDone(event, item);
  }
};

const isTarget = (item) => {
  // Überprüfe, ob das Element im Target-Bereich ist
  return pickListProducts?.value?.[1].includes(item);
};

const onMoveToSource = (event) => {
  loading.value = true;
  event.items.forEach((item) => {
    const index = dialogRef.value.data.docData.itemList.findIndex(
      (product) => product.item_id_company === item.item_id_company
    );
    if (index !== -1) {
      if (item.linkedItem) return;

      sourceProducts.value.push(item);
      deleteLinkedItems(dialogRef.value.data.docData,item);
      dialogRef.value.data.docData.itemList.splice(index, 1);
    }
  });
  loading.value = false;
};

const onMoveAllToSource = () => {
  loading.value = true;
  // zuerst soll von ...dialogRef.value.data.docData.itemList alle items entfernt werden die linkeditem oder linkedvpeitem sind
  dialogRef.value.data.docData.itemList = dialogRef.value.data.docData.itemList.filter(
    (item) => !item.linkedItem && !item.linkedVPEItem
  );
  sourceProducts.value = [
    ...sourceProducts.value,
    ...dialogRef.value.data.docData.itemList,
  ];
  dialogRef.value.data.docData.itemList = [];
  loading.value = false;
};

const onMoveToTarget = (event) => {
  loading.value = true;
  event.items.forEach((item, index) => {
    item.posNr = index + 1;
    item.selectedItem = JSON.parse(JSON.stringify(item));
    // Entferne das Element aus sourceProducts, wenn es zur Ziel-Liste hinzugefügt wird
    const sourceIndex = sourceProducts.value.findIndex(
      (product) => product.item_id_company === item.item_id_company
    );
    if (sourceIndex !== -1) {
      sourceProducts.value.splice(sourceIndex, 1);
    }
    dialogRef.value.data.docData.itemList.push(item);
    event.value = item.VPE_QTY;
    selectionVPEDone(event, item);
  });
  loading.value = false;
};

const onMoveAllToTarget = () => {
  loading.value = true;
  filteredSourceProducts.value.forEach((item, index) => {
    item.posNr = index + 1;
    item.selectedItem = JSON.parse(JSON.stringify(item));
  });
  dialogRef.value.data.docData.itemList = [
    ...dialogRef.value.data.docData.itemList,
    ...filteredSourceProducts.value,
  ];
  // Leeren von sourceProducts, wenn alle Elemente zur Ziel-Liste hinzugefügt werden ansonsten nur die gefilterten Elemente entfernen
  if (filter.value) {
    sourceProducts.value = sourceProducts.value.filter(
      (product) =>
        !filteredSourceProducts.value.some(
          (filteredProduct) =>
            filteredProduct.item_id_company === product.item_id_company
        )
    );
  } else {
    sourceProducts.value = [];
  }
  loading.value = false;
};
const entferneTargetItemsAusSourceProducts = () => {
  loading.value = true;
  dialogRef.value.data.docData.itemList.forEach((item) => {
    const index = sourceProducts.value.findIndex(
      (product) => product.item_id_company === item.item_id_company
    );
    if (index !== -1) {
      sourceProducts.value.splice(index, 1);
    }
  });
  loading.value = false;
};

onMounted(async () => {
  while (!itemsLoaded) {
    await new Promise((resolve) => setTimeout(resolve, 10)); // Warten  0.01 Sekunde
    sourceProducts.value = items;
  }
  entferneTargetItemsAusSourceProducts();
});

onBeforeMount(async () => {
  await werteLaden();
});
</script>
<style scoped>
.selected-item {
  border: 2px dashed green;
}
</style>
