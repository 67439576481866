import iAxios from "@/store/axiosInterface";
export const getTickets = (data) => {
  return data;
};

export const TicketService = {
  
  async getData() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/support/ticket");
    const data = await response.data;
    return Promise.resolve(data);
  },

  getTicketsSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getTicketsMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async getTicketsXLarge() {
    return Promise.resolve(this.getData());
  },

};
