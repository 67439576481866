<template>
  <div>
    <div class="my-1 text-center offset-2 col-12">
      <!--         <img src="@/assets/register.jpg" class="img-fluid" /> -->
    </div>
    <div class="alert alert-danger col-md-8 offset-2" v-if="error">
      {{ errorDisplayText }}
    </div>
    <Form @submit="submitData" :validation-schema="schema" v-slot="{ errors }">
      <!-- <div>{{ errors }}</div> -->
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
          v-model="values.email"
          type="email"
          class="form-control"
          id="email"
          name="email"
          placeholder="E-Mail Adresse"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
            v-model="values.password"
            as="input"
            name="password"
            type="password"
            class="form-control"
            id="new-password"
            placeholder="Passwort"

          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" :class="columnSizesForm">
          <InputText
            v-model="values.confirmPassword"
            as="input"
            name="confirmPassword"
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="Passwort wiederholen"
          />
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group" :class="columnSizesForm">
          <div class="d-grid">
            <Button :loading="isLoading" label="Registrieren" @click="submitData" class="btn bg-vue text-vue">
            </Button>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script setup>
import { ref,reactive } from "vue";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";

const store=userStore();
const toast = useToast();

const values= reactive({
  email:"",
  password:"",
  confirmPassword:"",
})

const schema = yup.object().shape({
  email: yup
    .string()
    .required("E-Mail Adresse wird benötigt.")
    .trim()
    .email("Keine gültige E-Mail Adresse."),
  password: yup
    .string()
    .required("Password wird benötigt.")
    .min(6, "Password mindestens 6 Zeichen"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwörter stimmen nicht überein."),
});

const error = ref("");
const isLoading = ref(false);


const columnSizesForm =
  "col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2";

function errorDisplayText() {
  if (error.value) {
    if (error.value.includes("EMAIL_EXISTS")) {
      return "E-Mail Adresse existiert bereits.";
    }
    return "Ein unbekannter Fehler, versuche es nochmal.";
  }
  return "";
}

function submitData() {
  isLoading.value = true;
  error.value = "";
  store
    .signup({
      email: values.email,
      password: values.password,
    })
    .then(() => {
      isLoading.value = false;
      toast.add({
      severity: "success",
      summary: "Registrierung abgeschickt",
      detail: "Bitte die Registrierung per Email abschließen, TIP: Schauen Sie auch im Spam Ordner nach, falls keine Email da ist.",
    });
    store.registersStayOpen=false;
    console.log("🚀 ~ file: RegisterComponent.vue:129 ~ .then ~ store.registersStayOpen:", store.registersStayOpen)
    })
    .catch((error) => {
      error.value = error.message;
      isLoading.value = false;
    });
}
</script>

<style scoped></style>
