<template>
  <div v-if="store.debug">
    <div v-for="(section, sectionIndex) in sections" :key="section.id">
      <Inplace :closable="true">
        <template #display>
          <h2>
            {{ section.title || 'klicken zum Titel editieren' }}</h2>
        </template>
        <template #content>
          <InputText class="w-full" type="text" v-model="section.title" autofocus />
        </template>
      </Inplace>
      <div>
        <Inplace :closable="true">
          <template #display>
            {{ section.content || 'klicken zum Content editieren'  }}
          </template>
          <template #content>
            <Textarea class="w-full" v-model="section.content" rows="5" autofocus></Textarea>
          </template>
        </Inplace>
      </div>
      <div v-for="(subSection, subSectionIndex) in section.subSections" :key="subSection.id">
        <span class="px-4 py-0 m-0">
          <Inplace :closable="true">
            <template #display>
              <h3 class="px-4 py-0 m-0">{{ subSection.title || 'klicken zum Titel editieren' }}</h3>
            </template>
            <template #content>
              <InputText class="w-full" type="text" v-model="subSection.title" autofocus="" />
            </template>
          </Inplace>
        </span>
        <div class="px-4 py-0 m-0">
          <Inplace :closable="true">
            <template #display>
              {{ subSection.content || 'klicken zum Content editieren'  }}
            </template>
            <template #content>
              <Textarea class="w-full" v-model="subSection.content" rows="5" autofocus></Textarea>
            </template>
          </Inplace>
        </div>
        <Button text size="small" severity="danger" @click="removeSubSection(sectionIndex, subSectionIndex)">Unterabschnitt
          entfernen</Button>
        <Button text size="small" @click="addSubSection(sectionIndex, subSectionIndex)">Unterabschnitt hinzufügen</Button>

        <div v-for="(subSubSection, subSubSectionIndex) in subSection.subSections" :key="subSubSection.id">
          <span >
            <Inplace :closable="true">
              <template #display>
                <h4 class="px-4 py-0 m-0">{{ subSubSection.title || 'klicken zum Titel editieren' }}</h4>
              </template>
              <template #content>
                <InputText class="w-full" type="text" v-model="subSubSection.title" autofocus="" />
              </template>
            </Inplace>
          </span>
          <div class="px-4 py-0 m-0">
            <Inplace :closable="true">
              <template #display>
                {{ subSubSection.content || 'klicken zum Content editieren'  }}
              </template>
              <template #content>
                <Textarea class="w-full" v-model="subSubSection.content" rows="5" autofocus></Textarea>
              </template>
            </Inplace>
          </div>
          <Button text size="small" severity="danger" @click="removeSubSubSection(sectionIndex, subSectionIndex, subSubSectionIndex)">Unterabschnitt
            entfernen</Button>
            
          <Button text size="small" @click="addSubSubSection(sectionIndex, subSectionIndex, subSubSectionIndex)">Unterabschnitt hinzufügen</Button>


          <div v-for="(subSubSubSection, subSubSubSectionIndex) in subSubSection.subSections" :key="subSubSubSection.id">
            <div class="px-4 py-0 m-0">
              <Inplace :closable="true">
                <template #display>
                  <h5 class="px-4 py-0 m-0">{{ subSubSubSection.title || 'klicken zum Titel editieren' }}</h5>
                </template>
                <template #content>
                  <InputText class="w-full" type="text" v-model="subSubSubSection.title" autofocus />
                </template>
              </Inplace>
            </div>
            <div class="px-4 py-0 m-0">
              <Inplace :closable="true">
                <template #display>
                  {{ subSubSubSection.content || 'klicken zum Content editieren' }}
                </template>
                <template #content>
                  <Textarea class="w-full" v-model="subSubSubSection.content" rows="5" autofocus></Textarea>
                </template>
              </Inplace>
            </div>
            <Button text size="small" severity="danger" @click="removeSubSubSubSection(sectionIndex, subSectionIndex, subSubSectionIndex, subSubSubSectionIndex)">Unterabschnitt
              entfernen</Button>
              <Button text size="small" @click="addSubSubSubSection(sectionIndex, subSectionIndex, subSubSectionIndex, subSubSubSectionIndex)">Unterabschnitt hinzufügen</Button>

          </div>

        </div>

      </div>
      <Button text size="small" @click="addSubSection(sectionIndex, subIndex)">Unterabschnitt hinzufügen</Button>
      <Button severity="danger" text size="small" @click="removeSection(sectionIndex)">Abschnitt entfernen</Button>
    </div>
    <Button text size="small" @click="addSection">Abschnitt hinzufügen</Button>
    <Button @click="downloadData">Daten herunterladen</Button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { data } from './HandbuchData.js';
import { userStore } from "@/store/userStore";

const store = userStore();
const sections = ref([]);

onMounted(async () => {
  if (!store.debug) return;
  sections.value = data;
  console.log(sections.value);
});

const addSection = () => {
  sections.value.push({
    id: `section${sections.value.length + 1}`,
    title: '',
    content: '',
    subSections: []
  });
};

const removeSection = (index) => {
  sections.value.splice(index, 1);
};

const addSubSection = (sectionIndex, subSectionIndex) => {
  const newSubSection = {
    id: `section${sectionIndex + 1}-${sections.value[sectionIndex].subSections.length + 1}`,
    title: '',
    content: ''
  };
  sections.value[sectionIndex].subSections.splice(subSectionIndex + 1, 0, newSubSection);
};

const addSubSubSection = (sectionIndex, subSectionIndex, subSubSectionIndex) => {
  if (!sections.value[sectionIndex] || !sections.value[sectionIndex].subSections[subSectionIndex]) {
    console.error('Parent section or subsection does not exist');
    return;
  }  const newSubSubSection = {
    id: `section${sectionIndex + 1}-${subSectionIndex + 1}-${subSubSectionIndex + 1}`,
    title: '',
    content: ''
  };
  console.log(newSubSubSection);
  sections.value[sectionIndex].subSections[subSectionIndex].subSections.splice(subSubSectionIndex + 1, 0, newSubSubSection);
};

const addSubSubSubSection = (sectionIndex, subSectionIndex, subSubSectionIndex, subSubSubSectionIndex) => {
  const newSubSubSubSection = {
    id: `section${sectionIndex + 1}-${subSectionIndex + 1}-${subSubSectionIndex + 1}-${subSubSubSectionIndex + 1}`,
    title: '',
    content: ''
  };
  sections.value[sectionIndex].subSections[subSectionIndex].subSections[subSubSectionIndex].subSections.splice(subSubSubSectionIndex + 1, 0, newSubSubSubSection);
};

const removeSubSection = (index, subIndex) => {
  sections.value[index].subSections.splice(subIndex, 1);
};

const removeSubSubSection = (sectionIndex, subSectionIndex, subSubSectionIndex) => {
  sections.value[sectionIndex].subSections[subSectionIndex].subSections.splice(subSubSectionIndex, 1);
};

const removeSubSubSubSection = (sectionIndex, subSectionIndex, subSubSectionIndex, subSubSubSectionIndex) => {
  sections.value[sectionIndex].subSections[subSectionIndex].subSections[subSubSectionIndex].subSections.splice(subSubSubSectionIndex, 1);
};

const downloadData = () => {
  const dataStr = 'export const data = ' + JSON.stringify(sections.value, null, 2);
  const blob = new Blob([dataStr], { type: 'text/javascript' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'HandbuchData.js';
  link.click();
  URL.revokeObjectURL(url);
};
</script>