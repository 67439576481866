<template>
    <template v-if="!store.rules.activatedModules.dashboard">
        <UpgradeLicence />
    </template>
    <template v-else>
        {{ console.log("customerWarnings", customerWarnings) }}
        <Message v-if="customerWarnings?.length < 6" v-for="cusWarn in customerWarnings" severity="warn"
            :sticky="sticky" :life="2000">
            {{ (cusWarn.firmenName || !cusWarn?.isPrivat) ? cusWarn.firmenName : cusWarn.firstName + ' ' +
                cusWarn.surName }} ->
            {{ Math.floor((new Date() - new Date(cusWarn.lastActivity_en)) / (1000 * 60 * 60 * 24)) }} Tage seit der
            letzten
            Aktivität des Kunden vergangen.
        </Message>
        <Message v-else severity="warn" :sticky="sticky" :life="2000">
            {{ customerWarnings?.length }} Kunden seit mind {{ store.companySettings.lastCustomerActivityWarningDays }}
            Tagen zu letzt Aktiv. Bitte überprüfen Sie die Kundenaktivität unter Kunden in der Spalte Letze Aktivität,
            in
            dem Sie es danach sortieren.
            Oder stellen Sie die Warnung in den Einstellungen höher.
        </Message>

        <Menubar :model="menuItems" :pt="{ submenu: { style: 'top: 40px !important; ' } }">

            <template #item="{ item, props, hasSubmenu, root }">
                <a v-ripple class="flex align-items-center" v-bind="props.action">
                    <span class="ml-2 w-auto text-xs">
                        {{ item.label }}
                    </span>
                    <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" />
                    <span v-if="item.shortcut"
                        class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut
                        }}</span>
                    <i v-if="hasSubmenu" :class="[
                        'pi pi-angle-down',
                        { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
                    ]"></i>
                </a>
            </template>
            <template #end>
                <span>von:</span>
                <Calendar v-model="timeFrom" :showButtonBar="true" type="date" inputClass="text-xs w-6rem h-2rem" />
                <span class="pl-2">bis:</span>
                <Calendar v-model="timeTo" :showButtonBar="true" type="date" inputClass="text-xs w-6rem h-2rem" />

                <Button label="Netto" @click="isBrutto = false; showData()" :outlined="isBrutto" class="ml-2 text-xs border-noround-right"/>
                <Button label="Brutto" @click="isBrutto = true; showData()" :outlined="!isBrutto" class="text-xs border-noround-left" />
            </template>    
        </Menubar>

        <div class="dashboard">
            <div class="p-component surface-ground px-4 py-5 md:px-6 lg:px-8">
                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="surface-800 shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-400 font-medium mb-3">Umsatz</span>
                                    <div class="text-900 text-white font-medium text-xl">{{ gesamtUmsatz.toFixed(2) }} €
                                    </div>
                                </div>
                                <div class="flex align-items-center justify-content-center border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-chart-line text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-400">Zeitraum: </small><small class="text-400 text-white">{{
                                zeitAuswahlLabel
                                }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Offene Rechnungen</span>
                                    <div class="text-900 font-medium text-xl">{{ sumOfOpenInvoices.toFixed(2) }} €</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-euro text-blue-800 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">{{ parseFloat(openInvoices).toFixed(0) }} Rechnungen</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Bezahlte Rechnungen</span>
                                    <div class="text-900 font-medium text-xl">{{ sumOfPaidInvoices.toFixed(2) }} €</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-euro text-green-800 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">{{ parseFloat(paidInvoices).toFixed(0) }} Rechnungen</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="surface-card shadow-2 p-3 border-round"
                            style="background-color: #2d323b !important;">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-400 font-medium mb-3">Kunden</span>
                                    <div class="text-white font-medium text-xl">{{ allRegisteredCustomer
                                        }}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-inbox text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">Hinzugekommene Kunden</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4" v-if="allPackagesToSend">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Noch nicht Verschickt</span>
                                    <div class="text-900 font-medium text-xl">{{ parseFloat(allPackagesToSend > 0 ?
                                        allPackagesToSend : 0).toFixed(2) }}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-truck text-blue-800 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">Sendungen noch zu verschicken.</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4" v-if="allSentPackages">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Verschickte Sendungen</span>
                                    <div class="text-900 font-medium text-xl">{{ parseFloat(allSentPackages > 0 ?
                                        allSentPackages : 0).toFixed(0) }}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-truck text-green-800 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">Sendungen.</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4" v-if="openOffers">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Offene Angebote</span>
                                    <div class="text-900 font-medium text-xl">{{ parseFloat(openOffers > 0 ?
                                        openOffers : 0).toFixed(0) }}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-truck text-blue-800 text-xl"></i>
                                </div>
                            </div>
                            <small class="text-500">Angebote die noch nicht akzeptiert sind.</small>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-4" v-if="acceptedOffers">
                        <div class="surface-card shadow-2 p-3 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Akzeptierte Angebote</span>
                                    <div class="text-900 font-medium text-xl">{{ parseFloat(acceptedOffers > 0 ?
                                        acceptedOffers : 0).toFixed(0) }}</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-truck text-green-800 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Card class="col-12 md:col-12 lg:col-12" v-if="chartOptionsDays">
                        <template #title>
                            <div class="flex" style="margin-bottom:50px!important">
                                <div class=""><span>Umsatz (Tagesfein)</span></div>
                            </div>
                        </template>
                        <template #content>
                            <Chart type="line" :options="chartOptionsDays" :data="chartDataDays"></Chart>
                        </template>
                    </Card>

                    <div class="col-12 md:col-12 lg:col-12" v-if="chartDataMonthValues">
                        <Card>
                            <template #title>
                                <div class="flex" style="margin-bottom:50px!important">
                                    <div class=""><span>Umsatz Jahresübersicht</span></div>
                                    <!--div class="flex-grow-1"></div>
                                    <div class="flex-none" style="font-size:10px;font-weight: 400;">
                                        <div v-for="(item) in chartDataMonthValues.datasets"
                                            style="line-height: 0.9rem">
                                            <span class="flex-none mx-2 my-2 px-2 py-0"
                                                :style="{ 'height': '10px', 'width': '10px', 'border': '1px solid ' + item.borderColor, 'background-color': item.backgroundColor }">
                                            </span>
                                            <span style="font-size: 10px !important;">
                                                <span style="margin-left: 2px">{{ item.label }}</span>
                                            </span>
                                        </div>
                                    </div-->
                                </div>
                            </template>
                            <template #content>
                                <Chart type="bar" class="h-30rem flex align-self-auto  justify-content-center"
                                    :options="setChartOptions" :data="chartDataMonthValues"></Chart>
                            </template>
                        </Card>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6" v-if="topTenItemsList">
                        <Card>
                            <template #title style="margin-bottom:0!important">
                                <div class="flex">
                                    <div class=""><span>Top 10 Artikel</span></div>
                                    <div class="flex-grow-1"></div>
                                    <div class="flex-none" style="font-size:10px;font-weight: 400;">
                                        <div v-for="(item) in legendTopTenItemsItems" style="line-height: 0.9rem">
                                            <span class="flex-none px-2 py-0 "
                                                :style="{ 'height': '10px', 'width': '10px', 'border': '1px solid ' + item.borderColor, 'background-color': item.backgroundColor }">
                                            </span>
                                            <span style="font-size: 10px !important;">
                                                <span style="margin-left: 2px">{{ item.label }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #content>
                                <Chart @select="artikelSelected" type="bar"
                                    class="h-30rem flex align-self-auto  justify-content-center" :options="chartOptions"
                                    :data="topSellingItemsData"></Chart>
                            </template>
                        </Card>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6" v-if="topTenCustomers">
                        <Card>
                            <template #title style="margin-bottom:0!important">
                                <div class="flex">
                                    <div class=""><span>Top 10 Kunden</span></div>
                                    <div class="flex-grow-1"></div>
                                    <div class="flex-none" style="font-size:10px;font-weight: 400;">
                                        <div v-for="(item) in legendItemsTopTenCustomers" style="line-height: 0.9rem">
                                            <span class="flex-none px-2 py-0 "
                                                :style="{ 'border': '1px solid ' + item.borderColor, 'background-color': item.backgroundColor }">
                                            </span>
                                            <span style="font-size: 10px !important;">
                                                <span style="margin-left: 2px">{{ item.label }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #content>
                                <Chart type="bar" @select="customerSelected"
                                    class="h-30rem flex align-self-auto  justify-content-center" :options="chartOptions"
                                    :data="topTenCustomersData"></Chart>
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script setup>
import { ref, onMounted, watch, defineAsyncComponent } from 'vue';

import Chart from 'primevue/chart';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';

import { userStore } from "@/store/userStore";
import iAxios from "@/store/axiosInterface";
import * as utils from "../utils/calculationUtils";
import { timeSelect } from "@/utils/timeSelectUtils";
import { ItemsService } from '@/service/ItemsService';
import { useDialog } from 'primevue/usedialog';
import { markRaw } from 'vue';
import { CustomerService } from '@/service/CustomerService';

const ItemInsertComponent = defineAsyncComponent(() =>
    import("@/views/ItemInsertComponent.vue")
);
const ItemsEditHeader = defineAsyncComponent(() =>
    import("@/views/ItemsEditHeader.vue")
);
const DefaultFooter = defineAsyncComponent(() =>
    import("@/views/ItemsEditFooter.vue")
);

const KundenEditDialog = defineAsyncComponent(() =>
    import("@/views/KundenEditDialog.vue")
);
const KundenEditDialogHeader = defineAsyncComponent(() =>
    import("@/views/KundenEditDialogHeader.vue")
);

const monthNames = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
];


const store = userStore();
const dialog = useDialog();

const statisticData = ref({});


const chartOptions = ref({});
const topSellingItemsData = ref({});

const topTenCustomersData = ref({});

const allRegisteredCustomer = ref({});
const gesamtUmsatz = ref(0);

const openInvoices = ref(0);
const paidInvoices = ref(0);
const sumOfPaidInvoices = ref(0);
const sumOfOpenInvoices = ref(0);
const topTenItemsList = ref();

const topTenCustomers = ref();
const numberOfOrderedItems = ref({});

const allPackagesToSend = ref();
const allSentPackages = ref();

const acceptedOffers = ref();
const openOffers = ref();


const legendTopTenItemsItems = ref();
const legendItemsTopTenCustomers = ref();

const chartDataMonthValues = ref();

const chartDataDays = ref();
const chartOptionsDays = ref();

const zeitAuswahlLabel = ref("Gesamt");
const timeFrom = ref();
const timeTo = ref();

const customerSelected = async (event) => {
    let selectedElement = topTenCustomers.value[event.element.index];

    let c = await CustomerService.getCustomerXLarge();

    let customerData = c.find((o, i) => { return o.customerId == selectedElement.customerId; });

    dialog.open(KundenEditDialog, {
        props: {
            header: " Bearbeiten",
            modal: true,
            closable: false,
            style: {
                width: "50vw",
            },
            breakpoints: {
                "960px": "75vw",
                "640px": "100vw",
            },
        },
        templates: {
            header: markRaw(KundenEditDialogHeader),
        },
        data: {
            customerData,
            isNewCustomer: false,
        },
        onClose: async (options) => {
            if (options.data) {
                const buttonType = options.data.buttonType;
                const customerUid = options.data.customerUid;
                if (buttonType == "Delete") {
                    await iAxios.delete(process.env.VUE_APP_BASE_API_URL + "/company/customer/" + customerUid);
                }
            }
        },
    });

}

const artikelSelected = async (event) => {
    let selectedElement = legendTopTenItemsItems.value[event.element.index];

    let item = await ItemsService.getItem(selectedElement.element.item_id_company);
    if (!item)
        return;

    const dialogRef = dialog.open(ItemInsertComponent, {
        props: {
            header: "Artikel Bearbeiten",
            modal: true,
            closable: false,
            style: {
                width: "60vw",
            },
            breakpoints: {
                "1200px": "75vw",
                "640px": "100vw",
            },
        },
        templates: {
            footer: markRaw(DefaultFooter),
            header: markRaw(ItemsEditHeader),
        },
        data: {
            item: item,
            erweitert: store.itemEditSettings.erweitert,
        },
        onClose: async (options) => {
            if (options.data) {
                const buttonType = options.data.buttonType;
                if (buttonType == "Save") {
                    const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
                    if (store.debug) console.log("item.value: ", item.value);
                    await iAxios.patch(url, item);

                    topSellingItemsData.value.labels[event.element.index] = item.itemName;
                }
            }
        },
    });
}

const zeitAuswahl = (zeitAuswahl) => {
    timeSelect(zeitAuswahl, timeFrom, timeTo, zeitAuswahlLabel);
};

const isBrutto = ref(false);

const menuItems = ref([
    {
        label: "Gesamt",
        command: () => {
            zeitAuswahl("Gesamt");
        },
    },
    {
        label: "Tage",
        items: [
            {
                label: "7 Tage",
                command: () => {
                    zeitAuswahl("7 Tage");
                },
            },
            {
                label: "14 Tage",
                command: () => {
                    zeitAuswahl("14 Tage");
                },
            },
            {
                label: "30 Tage",
                command: () => {
                    zeitAuswahl("30 Tage");
                },
            },
        ],
    },
    {
        label: "Monate",
        items: [
            {
                label: "1 Monat",
                command: () => {
                    zeitAuswahl("1 Monat");
                },
            },
            {
                label: "3 Monate",
                command: () => {
                    zeitAuswahl("3 Monate");
                },
            },
            {
                label: "6 Monate",
                command: () => {
                    zeitAuswahl("6 Monate");
                },
            },
            {
                label: "9 Monate",
                command: () => {
                    zeitAuswahl("9 Monate");
                },
            },
        ],
    },
    {
        label: "Jahre",
        items: [
            {
                label: "1 Jahr",
                command: () => {
                    zeitAuswahl("1 Jahr");
                },
            },
            {
                label: "3 Jahre",
                command: () => {
                    zeitAuswahl("3 Jahre");
                },
            },
            {
                label: "5 Jahre",
                command: () => {
                    zeitAuswahl("5 Jahre");
                },
            },
        ],
    },
]);

const createChartDataObject = () => {
    return {
        labels: [],
        datasets: [
            {
                label: 'Sales',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }
        ]
    }
}

const getBackgroundColors = () => {
    return ['rgba(255, 221, 0, 0.2)', 'rgba(255, 0, 0, 0.2)', 'rgba(17, 0, 166, 0.2)', 'rgba(88, 252, 0, 0.2)', 'rgba(98, 99, 97, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 59, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)']
}

const getBorderColors = () => {
    return ['rgba(255, 221, 0)', 'rgba(255, 0, 0)', 'rgba(17, 0, 166)', 'rgba(88, 252, 0)', 'rgba(98, 99, 97)', 'rgb(255, 159, 64)', 'rgb(255, 59, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'];
}

const customerWarnings = ref();

onMounted(async () => {

    await iAxios.get("/company/customers-activity-warning").then((response) => {
        customerWarnings.value = response.data.customersWithLessActivity;

        console.log("🚀 ~ file: Dashboard.vue:269 ~ customerWarnings.value :", customerWarnings.value)

    });

    store.headerTitel = "DASHBOARD"

    try {
        let response = await iAxios.post("/company/dash-stats", {});

        statisticData.value = response.data;

        showData();
    } catch (err) { };
});

const showData = () => {
    let data = statisticData.value;

    allRegisteredCustomer.value = data.numberOfCustomers;
    topTenCustomers.value = data.topTenCustomerOrderList;
    numberOfOrderedItems.value = data.numberOfOrderedItems;

    openInvoices.value = data.openInvoices;
    paidInvoices.value = data.paidInvoices;

    acceptedOffers.value = data.acceptedOffers;
    openOffers.value = data.openOffers;

    if (isBrutto.value) {
        gesamtUmsatz.value = data.sumOfAllInvoicesBrutto;

        sumOfPaidInvoices.value = data.sumOfAllInvoicesBruttoPaid;
        sumOfOpenInvoices.value = data.sumOfAllInvoicesBruttoOpen;
    } else {
        gesamtUmsatz.value = data.sumOfAllInvoicesNetto;

        sumOfPaidInvoices.value = data.sumOfAllInvoicesNettoPaid;
        sumOfOpenInvoices.value = data.sumOfAllInvoicesNettoOpen;
    }
    topTenItemsList.value = data.topTenItemsList;

    handleTopTenCustomers();
    handleTopTenItems();
    handleMonatsUmsatz(statisticData.value);
};

const handleMonatsUmsatz = (data) => {
    chartDataMonthValues.value = [];
    if (!data.monthlyPaidMap) {
        chartDataMonthValues.value = null;

        return;
    }
    let chartData = {
        labels: monthNames,
        datasets: []
    };

    var bezahltDataSet = {
        label: 'Zahlungseingänge',
        backgroundColor: getBackgroundColors()[0],
        borderColor: getBorderColors()[0],
        type: 'bar',
        data: [],
    };
    chartData.datasets.push(bezahltDataSet);
    for (var i = 0; i < 12; i++) {
        var iMonthData = data.monthlyPaidMap[zeroPad(i + 1)];
        if (!iMonthData) {
            bezahltDataSet.data.push(0);
        } else {
            if (isBrutto.value) {
                bezahltDataSet.data.push(iMonthData.documentValueBrutto);
            } else {
                bezahltDataSet.data.push(iMonthData.documentValueNetto);
            }
        }
    }

    var unbezahltDataSet = {
        label: 'Ausstehende Zahlung',
        backgroundColor: getBackgroundColors()[1],
        borderColor: getBorderColors()[1],
        type: 'bar',
        data: [],
    };
    chartData.datasets.push(unbezahltDataSet);
    for (var i = 0; i < 12; i++) {
        var iMonthData = data.monthlyNotpaidMap[zeroPad(i + 1)];
        if (!iMonthData) {
            unbezahltDataSet.data.push(0);
        } else {
            if (isBrutto.value) {
                unbezahltDataSet.data.push(iMonthData.documentValueBrutto);
            } else {
                unbezahltDataSet.data.push(iMonthData.documentValueNetto);
            }
        }
    }
    chartDataMonthValues.value = chartData;
};

const zeroPad = (num) => String(num).padStart(2, '0')

watch([timeFrom, timeTo], async () => {
    let body = {};
    if (timeFrom.value != null && timeTo.value != null) {
        body.from = utils.formatDate(timeFrom.value),
            body.to = utils.formatDate(timeTo.value)
    }

    try {
        let response = await iAxios.post("/company/dash-stats", body);
        statisticData.value = response.data;
        showData();
    } catch (exception) {
        console.log(exception);
    }
});

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');
    const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
}

chartOptions.value = {
    scales: {
        x: {
            display: true, // x-achse labels ausblenden
        },
    },
    plugins: {
        legend: {
            display: false, // legende ausblenden
        },
    },
};

const handleTopTenItems = () => {
    let backgroundColors = getBackgroundColors();
    let borderColors = getBorderColors();

    let chartDataTopTenItems = createChartDataObject();

    legendTopTenItemsItems.value = [];
    if (topTenItemsList.value != undefined) {
        for (const [k, v] of Object.entries(topTenItemsList.value)) {
            chartDataTopTenItems.datasets[0].data.push(v.quantity);
            chartDataTopTenItems.labels.push(v.itemName);
            let backgroundColor = backgroundColors.pop();
            chartDataTopTenItems.datasets[0].backgroundColor.push(backgroundColor);
            let borderColor = borderColors.pop();
            chartDataTopTenItems.datasets[0].borderColor.push(borderColor);
            legendTopTenItemsItems.value.push({ label: v.itemName, backgroundColor: backgroundColor, borderColor: borderColor, element: v });
        }
    }
    topSellingItemsData.value = chartDataTopTenItems;
}

const handleTopTenCustomers = () => {
    let backgroundColors = getBackgroundColors();
    let borderColors = getBorderColors();

    let chartDataTopTenCustomers = createChartDataObject();

    legendItemsTopTenCustomers.value = [];
    if (topTenCustomers.value != undefined) {
        for (const [key, value] of Object.entries(topTenCustomers.value)) {
            chartDataTopTenCustomers.datasets[0].data.push(value.orders);
            chartDataTopTenCustomers.labels.push(value.firmenName);

            let backgroundColor = backgroundColors.pop();
            chartDataTopTenCustomers.datasets[0].backgroundColor.push(backgroundColor);

            let borderColor = borderColors.pop();
            chartDataTopTenCustomers.datasets[0].borderColor.push(borderColor);
            legendItemsTopTenCustomers.value.push({ label: value.firmenName, backgroundColor: backgroundColor, borderColor: borderColor });
        }
    }
    topTenCustomersData.value = chartDataTopTenCustomers;
}

function generateRandomData() {
    const data = {
        paidMap: {},
        notpaidMap: {}
    };

    const startDate = new Date('2023-01-01');
    const endDate = new Date('2023-12-31');

    const numberOfEntries = Math.floor(Math.random() * (600 - 300 + 1) + 9000);

    for (let i = 0; i < numberOfEntries; i++) {
        const randomDate = new Date(
            startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
        ).toISOString().split('T')[0];

        const randomInvoiceValueBrutto = parseFloat((Math.random() * 30 + 5).toFixed(2));
        const randomInvoiceValueNetto = parseFloat((randomInvoiceValueBrutto * 0.84).toFixed(2));

        const isPaid = Math.random() < 0.5;

        if (isPaid) {
            data.paidMap[randomDate] = {
                invoiceValueBrutto: randomInvoiceValueBrutto,
                invoiceValueNetto: randomInvoiceValueNetto
            };
        } else {
            data.notpaidMap[randomDate] = {
                invoiceValueBrutto: randomInvoiceValueBrutto,
                invoiceValueNetto: randomInvoiceValueNetto
            };
        }
    }

    return { data };
}



</script>

<style scoped>
.p-submenu-list {
    top: 40px;
}

dd {
    display: list-item;
    list-style-type: "\2B1C";
    padding-inline-start: 1ch;
    border: 2px;
    margin-right: 5px;
    line-height: 10px;
    border-radius: 2px;
    background-color: aqua;
}

.chart {
    width: 40em;
    margin: 10px;
    padding: 10px;
}

.container {
    display: flex;
    flex-direction: row;
}

.calendar-container {
    flex: 1;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
}

.buttonChangeDateRange {
    margin-left: 5px;
}

.charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chart {
    flex-basis: 48%;
    /* Breite der Karten anpassen */
    margin-bottom: 20px;
}

.card-item {
    height: 100%;
    padding: 0rem !important;
}

:deep(.p-card .p-card-content) {
    padding: 0 !important;
}

.marker-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.marker {
    width: 20px;
    height: 20px;
    border: 2px;
    margin-right: 5px;
    line-height: 10px;
    border-radius: 2px;
    /* Rundungen anpassen */
}
</style>